@import "~assets/styles/_variables";

.icon {
    display: inline-block;
    padding: 0 0.25rem;
    line-height: 0;
}

.icon img {
    width: 16px;
    max-width: 100%;
    display: inline-block;
}

.iconCircle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    background-color: $purple;
    border-radius: 100%;
}

.iconCircle svg {
    fill: #ffffff;
}

.ArrowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.875rem;
    height: 0.875rem;
    background-color: $green;
    border-radius: 100%;
    margin-right: 0.5rem;
}

.noMargin {
    margin-right: 0;
}

.ArrowIconRed {
    background-color: $red;
}

.ArrowIconDown {
    img {
        transform: rotate(180deg);
    }
}

.ArrowIconBlank {
    background-color: $grey-light;
    color: #fff;
}

.ArrowIconBlank span {
    font-size: 18px;
    font-weight: 800;
    display: inline-block;
    padding-left: 1px;
    padding-bottom: 2px;
}

.ArrowIcon svg {
    fill: #ffffff;
}

.objectSymbol {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
