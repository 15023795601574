@import "~assets/styles/_variables";

$white: #fff;
$darkpurple: #3d47f5;
$lightgrey: $grey-bg;
$darkgrey: $grey-lines;

.daySelectContainer {
    position: relative;

    .DayPicker-Day {
        font-size: 0.75rem;
        width: 2.125rem;
        height: 2.125rem;
        font-weight: 500;
        cursor: pointer;

        &:active,
        &:focus {
            outline: none;
        }
    }

    .DayPicker-Weekday {
        font-size: 0.75rem;
        font-weight: 600;
        color: $grey-medium;
        padding: 0 0.5rem;
    }

    .DayPicker-Month {
        border-collapse: initial;
        border-spacing: 0 0.25rem;

        &:active,
        &:focus {
            outline: none;
        }
    }

    .DayPicker-wrapper {
        outline: none;
    }

    .DayPicker-Day {
        color: $grey-graphite;
        position: relative;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--today):not(.DayPicker-Day--outside):not(.DayPicker-Day--highlighted):hover {
        color: #ffffff;
        font-weight: 800;
        background-color: $purple !important;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside):hover {
        color: #ffffff !important;
        background-color: $purple !important;
        font-weight: 800;
        border-radius: 5rem;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside) {
        border-radius: 5rem;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: $purple;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .DayPicker-Day--outside {
        background-color: transparent !important;
    }

    .DayPicker-Day--highlighted {
        color: #ffffff !important;
        background-color: $purple;
        font-weight: 800;
        cursor: default;
    }

    .DayPicker-Day--disabled {
        color: $grey-lines;
        cursor: default;
    }

    .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--outside):after {
        content: "";
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 10px;
        background: $purple;
    }

    .DayPicker-Day--daysWithAnomalies:hover:after {
        background: #ffffff;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from {
        width: 2.125rem;
        height: 2.125rem;
        border-radius: 100%;
        background: $purple;
        color: #ffffff !important;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
        display: none;
    }

    .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:after,
    .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:after,
    .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:after {
        background: #ffffff;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: transparent;
        border-radius: initial;
        color: $white;
    }

    .daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $purple;
        color: #ffffff !important;
        border-radius: 100%;
    }

    .DayPicker-NavButton--prev {
        background-image: url("~assets/images/icons/Icon-Arrow-Right.svg");
        transform: scaleX(-1);
    }

    .DayPicker-NavButton--next {
        background-image: url("~assets/images/icons/Icon-Arrow-Right.svg");
    }

    .DayPicker-NavButton {
        width: 0.875rem;
        outline: none;
        margin-top: -0.0625rem;
    }

    .DayPicker-NavButton--prev {
        left: 1rem;
    }

    .DayPicker-NavButton--next {
        right: 1rem;
    }

    .DayPicker-Caption {
        text-align: center;
        margin-bottom: 0.625rem;
    }

    .DayPicker-Caption > div {
        font-size: 0.875rem;
        font-weight: 800;
        color: $grey-graphite;
    }

    svg {
        fill: #ffffff;
    }

    .DayPicker-Day {
        min-width: 2.125rem;
    }

    .daySelect {
        box-shadow: 0 4px 8px 0 rgba(51, 57, 68, 0.05);
        border-radius: 8px;
        z-index: 100;
        position: absolute;
        background-color: #ffffff;
        right: 0;
        top: 2.75rem;
        padding: 0.5rem 0.5rem 0 0.5rem;
        border: 1px $grey-lines solid;
        transform: scale(0);
        opacity: 0;
        transition: opacity 0.3s ease, transform 0s ease 0.3s;
    }

    .daySelectOpen {
        transform: scale(1);
        opacity: 1;
        transition: opacity 0.3s ease 0.1s, transform 0s ease;
    }

    .DayPicker-Months {
        flex-wrap: initial;
    }
}
