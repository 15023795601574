@import "~assets/styles/_variables.scss";

.MuiSlider-root {
    .MuiSlider-rail {
        background-color: $grey-lines;
        height: 0.25rem;
        border-radius: 0.25rem;
    }

    .MuiSlider-track {
        height: 0.25rem;
        border-radius: 0.25rem;
        background-color: $purple;
    }

    .MuiSlider-thumb {
        width: 1rem;
        height: 1rem;
        background-color: $white;
        border: 0.3125rem solid $purple;
        box-shadow: 0 0.25rem 0.375rem 0 rgba(68, 76, 89, 0.2);
        transition: border-color 0.3s ease-in-out;

        &:hover {
            box-shadow: 0 0.25rem 0.375rem 0 rgba(68, 76, 89, 0.2);
            border-color: $purple;
        }

        &::after {
            display: none;
        }

        [class*="PrivateValueLabel-offset"].MuiSlider-valueLabel,
        .MuiSlider-valueLabel[class*="jss"] {
            left: calc(50% - 1px);
            transform: translate(-50%, -0.5rem);

            [class*="PrivateValueLabel-circle"],
            > [class*="jss"] {
                border-radius: 0.375rem;
                transform: rotate(0deg);
                height: 1.625rem;
                width: 3.25rem;
                background-color: $grey-rhino;

                &::after {
                    position: absolute;
                    content: "";
                    width: 0.625rem;
                    height: 0.625rem;
                    transform: rotate(45deg) translateX(-50%);
                    background-color: $grey-rhino;
                    border-radius: 0.125rem;
                    bottom: -0.5rem;
                    left: 50%;
                }

                [class*="PrivateValueLabel-label"],
                > [class*="jss"] {
                    transform: rotate(0);
                    font-family: $primary-font;
                    font-weight: 800;
                    font-size: 0.6875rem;
                }
            }
        }
    }
}
