@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;600;700;800&display=swap");
@import "~assets/styles/_variables";

body {
    font-family: $primary-font !important;
    font-weight: 400;
    font-size: 16px;
    color: $grey-graphite;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
label {
    margin-top: 0;
}

h1,
h2 {
    font-weight: 100;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 2rem;
}

h3 {
    font-weight: 100;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 1rem;
}

p,
li,
a,
label {
    font-size: 0.75rem;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}
