@import "~assets/styles/_variables.scss";

.Select.is-focused:not(.is-open) > .Select-control {
    border-color: $purple;
}

.Select-control {
    border: none;
    border-bottom: 1px solid $grey-mist;
    border-radius: 0;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
        border-color: $purple;

        .Select-arrow {
            background-image: url("../images/Top_Panel/Icon-Arrow-Down-Purple.svg");
        }
    }
}

.Select--multi .Select-input {
    margin-left: 0;
}

.Select-placeholder {
    padding-left: 0;
    font-size: 0.75rem;
    color: #c2c7de;
}

.Select--single > .Select-control .Select-value {
    padding-left: 0;
    line-height: 40px;
}

.Select-input {
    padding-left: 0;

    > input {
        max-height: 100%;
        padding: 0;
    }
}

.Select-value-label {
    color: $grey-graphite !important;
}

.rt-expander {
    margin: 0px 11px !important;
}

.Select-arrow {
    height: 0.5rem;
    width: 0.625rem;
    border: 0;
    background-image: url("../images/Top_Panel/Icon-Arrow-Down-Dark.svg");
    display: inline-block;
    background-repeat: no-repeat;
}

.Select.is-open .Select-arrow {
    background-image: url("../images/Top_Panel/Icon-Arrow-Down-Purple.svg");
}

.Select-arrow-zone {
    padding-right: 1rem;
}

.Select.is-open > .Select-control .Select-arrow {
    transform: rotate(180deg);
}

.Select-clear-zone {
    color: $grey-graphite;
    margin-right: -0.25rem;

    &:hover {
        color: $red;
    }
}

.Select-clear {
    font-size: 22px;
    font-weight: 600;
}

.Select-menu-outer {
    border-radius: 0.375rem;
    box-shadow: 0 0.325rem 0.625rem 0 rgba(51, 57, 68, 0.1);
    padding: 0.5rem;

    > div {
        max-height: 7.5rem;

        > div {
            border-radius: 0.5rem;
            background-color: transparent !important;
            color: $grey-graphite;
            padding: 0.875rem 0.75rem 0.75rem;
            font-size: 0.875rem;
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover:not(.is-disabled) {
                color: $purple;
                background-color: $purple-light !important;
            }

            &.is-selected {
                font-weight: 800;
            }

            &:last-of-type {
                border-radius: 0.5rem;
            }
        }
    }
}
