.tabContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.open {
    display: flex;
    flex: 1;
    flex-grow: 1;
    max-width: 100%;
    flex-direction: column;
}

.closed {
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    display: none;
    flex-direction: column;
}

.closedLeft {
    transform: translateX(100%);
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
}

.closedRight {
    transform: translateX(-100%);
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
}

.closed > div,
.closedLeft > div,
.closedRight > div {
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
}

.split {
    display: flex;
    flex: 1;
    max-width: 100%;
}
