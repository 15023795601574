@import "~assets/styles/_variables";

.container {
    position: relative;
    z-index: 100;
}

@media only all and (max-width: $tablet-breakpoint) {
    .calendarContainer {
        .monthPicker {
            left: 0 !important;
        }
        .dayPickerWrapper {
            border: none !important;
            & > div:first-child {
                display: none;
            }
        }
    }
}

.mobileWidth {
    width: 11.25rem;
}

.desktopWidth {
    width: 17.125rem;
}

.zIndex30 {
    z-index: 30;
}

.calendarContainer {
    position: relative;

    hr {
        border: 0;
        background-color: $grey-lines;
        width: 0.0625rem;
        margin: 0;
    }

    .dayPickerWrapper {
        display: flex;
        transform: scale(0);
        opacity: 0;
        transition: opacity 0.3s ease, transform 0s ease 0.3s;
        position: absolute;
        z-index: 100;
        right: 0;
        top: 2.625rem;
        border: 1px $grey-lines solid;
        box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.05);
        border-radius: 0.625rem;
    }

    .presets {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 2.75rem;
        border-radius: 0 0.5rem 0.5rem 0;
        border: 1px solid $grey-lines;
        border-left-color: transparent;
        transition: border 0.3s ease, background-color 0.3s ease;
        margin-left: -0.125rem;
        position: relative;
        z-index: 1;

        &:hover,
        &.open {
            border-color: $purple;

            svg {
                fill: $purple;
            }
        }

        &.open {
            background-color: #f3f4ff;
        }
    }

    .presetSelector {
        position: absolute;
        right: 0;
        bottom: -0.125rem;
        width: 16.75rem;
        z-index: 1;

        .customDateRange {
            display: block;
            border-top: 0.0625rem solid $grey-lines;
            margin-top: 0.5rem;

            .customLabel {
                display: flex;
                align-items: center;
                position: relative;

                svg {
                    width: 0.875rem;
                    height: 0.875rem;
                    margin-right: 0.75rem;
                }
            }
        }
    }

    &.calendarOpen {
        &::before {
            background-color: transparent;
        }
        .dayPickerWrapper {
            transform: scale(1);
            opacity: 1;
            transition: opacity 0.3s ease 0.1s, transform 0s ease;
        }
        .calendarButton,
        .calendarButton:hover {
            border: 1px solid $purple;
            color: $purple;
            background-color: #f3f4ff !important;

            .calendarIcon,
            svg {
                fill: $purple;
            }
            svg:not(.calendarIcon) {
                transform: scaleY(-1);
                fill: $purple;
            }
        }
    }

    &.compareCalendar {
        margin-left: 0.5rem;

        .calendarButton {
            width: auto;
            min-width: 13rem;
        }

        .presetSelector {
            min-width: 13rem;
            width: 100%;
        }
    }
}

.rangePickerAlignLeft {
    left: 0;
    right: auto !important;
}

.additionalOptions {
    border-bottom: 1px solid $grey-lines;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
    li:first-child {
        margin-top: 0;
        margin-bottom: 0;
    }
    li {
        svg {
            width: 14px;
            height: auto;
            margin-right: 0.5rem;
            margin-bottom: -2px;
        }
    }
}
