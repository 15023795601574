@import "~assets/styles/_variables";

$white: #fff;
$darkpurple: #3d47f5;
$lightgrey: $grey-bg;
$darkgrey: $grey-lines;

[class*="selectInput"] {
    background-color: transparent;
    border-radius: 0.5rem;
    padding: 0;
    min-width: 180px;

    &.headerHeight .Select-control {
        min-height: 2.75rem;
    }

    &.is-disabled {
        pointer-events: none;

        .Select-control {
            background-color: $white;

            .Select-value .Select-value-label {
                color: $grey-mild !important;
            }

            .Select-arrow-zone .Select-arrow {
                background-color: $grey-light;
            }
        }
    }

    .Select-control {
        width: 100%;
        background-color: $white;
        min-height: 2.5rem;
        height: auto;
        border: 1px solid $grey-lines !important;
        cursor: pointer;
        border-radius: 0.5rem;
        transition: border 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

        .Select-multi-value-wrapper {
            width: 100%;
        }

        .Select-placeholder {
            color: $grey-mist !important;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            font-size: 0.875rem;
            margin: 0.125rem 0 0;
        }
        .Select-value {
            background-color: transparent;
            color: $grey-graphite !important;

            &::before {
                display: flex;
                align-items: center;
                width: 1rem;
                height: 1rem;
                mask-size: 1rem;
                mask-repeat: no-repeat;
                mask-position: center;
                -webkit-mask-size: 1rem;
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-position: center;
                background-color: $grey-medium;
                margin: 0.125rem 0.75rem 0 0;
                transition: background-color 0.3s ease-in-out;
            }

            .Select-value-label {
                color: $grey-graphite;

                svg {
                    display: none;
                }
            }
        }
        .Select-input {
            background-color: transparent;
            padding: 0 1rem;
            margin-top: 0.125rem;
            color: $grey-graphite !important;
            cursor: pointer;

            input {
                width: 100% !important;
                height: 32px;
                display: block;
                border: 0;
                position: relative;
                top: 0;
                left: 0;
                padding: 0;
                background-color: transparent;
                cursor: pointer;

                &:focus {
                    border: 0;
                    background-color: transparent;
                    cursor: text;
                }
            }
        }
        .Select-arrow-zone {
            text-align: right;
            padding: 0;
            margin-left: 0.625rem;
            display: block;

            .Select-arrow {
                border: none;
                height: 0.5rem;
                width: 0.625rem;
                margin: -2px 18px 0 0;
                background-color: $grey-rhino;
                background-image: none;
                -webkit-mask-image: url("../images/icons/arrow-down-icon-white.svg");
                mask-image: url("../images/icons/arrow-down-icon-white.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: background-color 0.3s ease-in-out;
            }
        }
    }

    .Select-clear {
        margin-top: 0.125rem;
    }

    .Select-control:hover,
    &.is-open .Select-control {
        border-color: $purple !important;
        border-radius: 0.5rem;
        box-shadow: none;

        .Select-value {
            &::before {
                background-color: $purple;
            }
            .Select-value-label {
                color: $purple !important;
            }
        }

        .Select-arrow-zone .Select-arrow {
            background-color: $purple;
        }
    }

    &.is-open .Select-control {
        background-color: $purple-light;
    }

    .Select-menu-outer {
        max-height: 16.5rem;
        background-color: $white;
        border-radius: 0.5rem;
        border-color: $darkgrey;
        margin-top: 0.125rem;
        box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.08);
        padding: 0;
        top: 100%;
        bottom: auto;

        .Select-menu {
            max-height: 15.25rem;

            .Select-option {
                position: relative;
                padding: 0.375rem 0.5rem 0.25rem;
                font-weight: 400;
                margin: 0 0.5rem;
                border-radius: 0.5rem;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1rem;
                min-height: 2.25rem;
                color: $grey-graphite;
                display: flex;
                align-items: center;

                svg {
                    fill: $grey-light;
                    transition: fill 0.3s ease-in-out;
                }

                &:first-child {
                    margin-top: 0.5rem;
                }

                &:last-child {
                    margin-bottom: 0.5rem;
                }

                &.is-selected {
                    font-weight: 800;
                }

                &.is-disabled {
                    color: $grey-mild;
                }

                &:hover:not(.is-disabled) {
                    color: $purple;

                    svg {
                        fill: $purple;
                    }
                }
            }
        }
    }

    &.darkBoarder:not(.is-open) .Select-control:not(:hover) {
        border-color: $grey-light !important;
    }

    &.Select--single {
        .Select-control {
            .Select-value {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0 1rem;
                cursor: pointer;

                .Select-value-label {
                    display: flex;
                    align-items: center;
                    line-height: 1rem;
                    font-weight: 400;
                    font-size: 0.875rem;
                    margin: 0.125rem 0 0;
                    transition: color 0.3s ease-in-out;
                }
            }
            .Select-input {
                width: 100%;
            }
        }
    }

    &.Select--multi {
        &.has-value .Select-control {
            .Select-value {
                border-color: $grey-lines;
                border-radius: 0.375rem;

                .Select-value-icon {
                    &:hover {
                        background-color: $purple-light;
                        color: $purple;
                    }
                }

                .Select-value-label {
                    font-weight: 800;
                    font-size: 0.75rem;
                }
            }
            .Select-input {
                margin-left: 0;
                padding: 0 0.5rem;
            }
        }
    }
}

[class*="integrationSelector"] {
    .Select-control {
        .Select-multi-value-wrapper {
            position: relative !important;

            .Select-value-label {
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                white-space: nowrap !important;
                display: block !important;
            }
        }
    }
}
