@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;600;700;800&display=swap);
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
/*Grid Styles*/
.generalStyles_headerWithTwoItems__ANMzz {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.generalStyles_twoThirdsOneThirdGrid__3t0i1 {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 100%;
}

@media only screen and (min-width: 68.75rem) {
  .generalStyles_twoThirdsOneThirdGrid__3t0i1 {
    grid-template-columns: calc(68.5% - 0.75rem) calc(31.5% - 0.75rem);
  }
}
.generalStyles_oneHalfOneHalfGrid__K7zEG {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 100%;
}

@media only screen and (min-width: 48rem) {
  .generalStyles_oneHalfOneHalfGrid__K7zEG {
    grid-template-columns: calc(50% - 0.75rem) calc(50% - 0.75rem);
  }
}
.generalStyles_oneHalfOneHalfLargeGapGrid__2CK58 {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 100%;
}

@media only screen and (min-width: 48rem) {
  .generalStyles_oneHalfOneHalfLargeGapGrid__2CK58 {
    grid-template-columns: calc(50% - 0.75rem) calc(50% - 0.75rem);
  }
}
@media only screen and (min-width: 68.75rem) {
  .generalStyles_oneHalfOneHalfLargeGapGrid__2CK58 {
    grid-gap: 3rem;
    grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);
  }
}
.generalStyles_oneWholeGrid__2-gjf {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 100%;
}

.generalStyles_gridSpacingTop__qOCIJ {
  margin-top: 2.25rem;
}

.generalStyles_gridSpacingBottom__28M4T {
  margin-top: 4rem;
}

/* Card Additional Styles */
.generalStyles_materialCardPadding__2Yw0n {
  padding: 2rem;
}

.generalStyles_materialCardPaddingGraph__1lIT1 {
  padding: 2rem 2rem 2.25rem 2rem;
}

.generalStyles_centeringContainer__2zWr6 {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0 !important;
}

.generalStyles_horizontalRule__1sL2c {
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
  display: block;
}

.generalStyles_avatarGreen__18uF_ {
  background-color: #00b178;
  float: none;
  margin: auto;
}
.generalStyles_avatarGreen__18uF_ svg,
.generalStyles_avatarGreen__18uF_ path {
  fill: #fff;
}

/* Background Colour Styles */
.generalStyles_lightGreenBackground__3xXm0 {
  background-color: #00b178;
}

.generalStyles_lightOrangeBackground__1ooZo {
  background-color: #ff9d00;
}

.generalStyles_yellowBackground__1BPvs {
  background-color: #ffbc00;
}

.generalStyles_lightPurpleBackground__1M7wM {
  background-color: #333333;
}

.generalStyles_lightGreyBackgound__1vW_u {
  background-color: #6a80a7;
}

.generalStyles_lighterGreyBackground__1ihja {
  background-color: #f8f9fb;
}

/* growth number */
.generalStyles_zeroGrowth__3Nw3e,
.generalStyles_positiveGrowth__1UlUQ,
.generalStyles_negativeGrowth__3sdQY,
.generalStyles_mildGrowth__6MDkI {
  font-weight: 800;
  letter-spacing: 0;
  text-align: center;
  border-radius: 0.3125rem;
  padding: 0.0625rem 0.3125rem 0;
  line-height: 1.0625rem;
  font-size: 0.6875rem;
  width: -webkit-max-content;
}

.generalStyles_zeroGrowth__3Nw3e {
  color: #6a80a7;
  background-color: #f0f4f7;
}

.generalStyles_positiveGrowth__1UlUQ {
  color: #00b178;
  background-color: #e6f9f5;
  width: -webkit-max-content;
}

.generalStyles_negativeGrowth__3sdQY {
  color: #fb1427;
  background-color: #ffeef0;
  width: -webkit-max-content;
}

.generalStyles_mildGrowth__6MDkI {
  color: #ff9d00;
  background-color: #fef6e9;
  width: -webkit-max-content;
}

.generalStyles_dashboardSubtitle__qB5nh {
  font-size: 0.8125rem;
}
.generalStyles_dashboardSubtitle__qB5nh .generalStyles_dot__27Fn5 {
  height: 0.375rem;
  width: 0.375rem;
  background-color: #bccfdc;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.625rem 0.125rem;
}

.generalStyles_platformChannelLabel__2XjhR {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.generalStyles_platformChannelLabel__2XjhR .generalStyles_channelName__3FR5o::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #a0b5c4;
  display: inline-block;
  margin: 0 0.375rem 0.0625rem 0.125rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_tooltip__1z_vK svg {
  transform: fill 0.3s ease-in-out;
}
.style_tooltip__1z_vK:hover svg {
  fill: #333333;
}

.style_tooltipCustom__3sOHS div {
  opacity: 0 !important;
  visibility: visible !important;
  transition: opacity 0.5s ease-in-out;
}
.style_tooltipCustom__3sOHS div::after {
  display: none;
}
.style_tooltipCustom__3sOHS div::before {
  display: none;
}
.style_tooltipCustom__3sOHS.style_show__3qxoI div {
  opacity: 1 !important;
}

.style_tooltipBox__27cxC > div {
  visibility: visible !important;
  line-height: 16px;
  font-weight: 400;
  font-size: 12px !important;
  padding: 0.625rem 1.125rem;
  background-color: #fff !important;
  max-width: 280px;
  border-radius: 0.5rem;
  opacity: 0 !important;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 2px 4px 0 rgba(51, 57, 68, 0.05);
}
.style_tooltipBox__27cxC:not(.style_hasArrow__VX74H) > div::after {
  display: none;
}
.style_tooltipBox__27cxC:not(.style_hasArrow__VX74H) > div::before {
  display: none;
}
.style_tooltipBox__27cxC.style_show__3qxoI > div {
  opacity: 1 !important;
}

.style_tooltipDark__2pUPw > div {
  background-color: #333333 !important;
  opacity: 0 !important;
  visibility: visible !important;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 0.5rem;
  padding: 0.5rem 0.875rem !important;
  font-weight: 600;
  font-size: 0.75rem !important;
  line-height: 1rem;
  transition: opacity 0.3s ease-in-out;
  color: #fff !important;
  border: none;
}
.style_tooltipDark__2pUPw > div::after {
  width: 0.625rem;
  height: 0.625rem;
  border: none !important;
  transform: rotate(45deg) translateX(-50%);
  background-color: #333333;
  border-radius: 0.125rem;
  margin-left: -0.0625rem !important;
}
.style_tooltipDark__2pUPw > div span {
  color: #fff !important;
  line-height: 1rem !important;
  font-weight: 600 !important;
  text-transform: none;
}
.style_tooltipDark__2pUPw > div strong {
  font-weight: 800;
}
.style_tooltipDark__2pUPw.style_top__26XSk div::after {
  bottom: -0.3rem;
  left: calc(50% - 3px);
}
.style_tooltipDark__2pUPw.style_top__26XSk.style_show__3qxoI > div {
  margin-top: -0.625rem;
}
.style_tooltipDark__2pUPw.style_bottom__3yqZF div::after {
  top: -0.25rem;
  left: calc(50% - 3px);
}
.style_tooltipDark__2pUPw.style_bottom__3yqZF.style_show__3qxoI > div {
  margin-top: 0.625rem;
}
.style_tooltipDark__2pUPw.style_top__26XSk.style_arrowRight__1I0sw div::after, .style_tooltipDark__2pUPw.style_bottom__3yqZF.style_arrowRight__1I0sw div::after {
  right: 0.5rem;
  left: auto;
}
.style_tooltipDark__2pUPw.style_left__1QGYC div::after {
  right: -0.25rem;
  margin-top: -0.4rem;
}
.style_tooltipDark__2pUPw.style_left__1QGYC.style_show__3qxoI > div {
  margin-left: -0.6rem;
}
.style_tooltipDark__2pUPw.style_right__3F15S div::after {
  left: -0.1rem;
  margin-top: -0.3rem;
}
.style_tooltipDark__2pUPw.style_right__3F15S.style_show__3qxoI > div {
  margin-left: 0.6rem;
}
.style_tooltipDark__2pUPw.style_right__3F15S.style_arrowTop__2yZqB div::after, .style_tooltipDark__2pUPw.style_left__1QGYC.style_arrowTop__2yZqB div::after {
  top: 1rem;
}
.style_tooltipDark__2pUPw.style_show__3qxoI div {
  opacity: 1 !important;
}
.style_tooltipDark__2pUPw.style_alignLeft__LzFtA div {
  text-align: left;
}
.style_tooltipDark__2pUPw.style_fitContent__1lw7W > div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.style_tooltipDark__2pUPw.style_red__265Tc > div {
  background-color: #fb1427 !important;
}
.style_tooltipDark__2pUPw.style_red__265Tc > div::after {
  background-color: #fb1427;
}

.style_tooltipFlexible__1Tm26 {
  position: relative;
}
.style_tooltipFlexible__1Tm26 .style_message__21hGA {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.1s ease 0.3s;
  background-color: #333333;
  padding: 0.5rem 0.875rem;
  border-radius: 0.5rem;
  position: absolute;
  line-height: 1rem;
  z-index: 1;
  box-shadow: 0 0.375rem 0.5rem 0 rgba(51, 57, 68, 0.1);
  color: #fff;
  font-weight: 600;
  font-size: 0.75rem;
}
.style_tooltipFlexible__1Tm26 .style_message__21hGA.style_alignCenter__3ZmLU {
  text-align: center;
}
.style_tooltipFlexible__1Tm26 .style_message__21hGA.style_alignLeft__LzFtA {
  text-align: left;
}
.style_tooltipFlexible__1Tm26 .style_message__21hGA.style_alignRight__1d0DY {
  text-align: right;
}
.style_tooltipFlexible__1Tm26 .style_message__21hGA.style_noWrap__28FNU {
  white-space: nowrap;
}
.style_tooltipFlexible__1Tm26 .style_message__21hGA::after {
  position: absolute;
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  transform: rotate(45deg) translateX(-50%);
  background-color: #333333;
  border-radius: 0.125rem;
}
.style_tooltipFlexible__1Tm26 .style_message__21hGA strong {
  font-weight: 800;
}
.style_tooltipFlexible__1Tm26.style_top__26XSk .style_message__21hGA {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -0.625rem);
  transform-origin: bottom center;
}
.style_tooltipFlexible__1Tm26.style_top__26XSk .style_message__21hGA::after {
  bottom: -0.5rem;
  left: calc(50% - 1px);
}
.style_tooltipFlexible__1Tm26.style_top__26XSk:hover .style_message__21hGA {
  transform: translate(-50%, -0.625rem);
}
.style_tooltipFlexible__1Tm26.style_top__26XSk.style_arrowRight__1I0sw .style_message__21hGA {
  transform: translate(calc(-100% + 1.375rem), -0.625rem);
  transform-origin: bottom right;
}
.style_tooltipFlexible__1Tm26.style_top__26XSk.style_arrowRight__1I0sw .style_message__21hGA::after {
  left: auto;
  right: 0.875rem;
}
.style_tooltipFlexible__1Tm26.style_top__26XSk.style_arrowRight__1I0sw:hover .style_message__21hGA {
  transform: translate(calc(-100% + 1.375rem), -0.625rem);
}
.style_tooltipFlexible__1Tm26.style_top__26XSk.style_arrowLeft__1asAp .style_message__21hGA {
  left: -0.875rem;
  transform: translate(0, -0.625rem);
  transform-origin: bottom left;
}
.style_tooltipFlexible__1Tm26.style_top__26XSk.style_arrowLeft__1asAp .style_message__21hGA::after {
  left: 1.25rem;
}
.style_tooltipFlexible__1Tm26.style_top__26XSk.style_arrowLeft__1asAp:hover .style_message__21hGA {
  transform: translate(0, -0.625rem);
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF .style_message__21hGA {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0.625rem);
  transform-origin: top center;
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF .style_message__21hGA::after {
  top: 0;
  left: calc(50% - 1px);
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF:hover .style_message__21hGA {
  transform: translate(-50%, 0.625rem);
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF.style_arrowRight__1I0sw .style_message__21hGA {
  transform: translate(calc(-100% + 1.375rem), 0.625rem);
  transform-origin: bottom right;
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF.style_arrowRight__1I0sw .style_message__21hGA::after {
  left: auto;
  right: 0.875rem;
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF.style_arrowRight__1I0sw:hover .style_message__21hGA {
  transform: translate(calc(-100% + 1.375rem), 0.625rem);
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF.style_arrowLeft__1asAp .style_message__21hGA {
  left: -0.5rem;
  transform: translate(0, 0.625rem);
  transform-origin: bottom left;
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF.style_arrowLeft__1asAp .style_message__21hGA::after {
  left: 0.875rem;
}
.style_tooltipFlexible__1Tm26.style_bottom__3yqZF.style_arrowLeft__1asAp:hover .style_message__21hGA {
  transform: translate(0, 0.625rem);
}
.style_tooltipFlexible__1Tm26.style_left__1QGYC .style_message__21hGA {
  right: 100%;
  top: 50%;
  transform: translate(-0.625rem, -50%);
  transform-origin: right center;
}
.style_tooltipFlexible__1Tm26.style_left__1QGYC .style_message__21hGA::after {
  right: 0;
  top: calc(50% - 1px);
  transform: rotate(45deg) translateY(-50%);
}
.style_tooltipFlexible__1Tm26.style_left__1QGYC:hover .style_message__21hGA {
  transform: translate(-0.625rem, -50%);
}
.style_tooltipFlexible__1Tm26.style_right__3F15S .style_message__21hGA {
  left: 100%;
  top: 50%;
  transform: translate(0.625rem, -50%);
  transform-origin: left center;
}
.style_tooltipFlexible__1Tm26.style_right__3F15S .style_message__21hGA::after {
  left: -0.5rem;
  top: calc(50% - 1px);
  transform: rotate(45deg) translateY(-50%);
}
.style_tooltipFlexible__1Tm26.style_right__3F15S:hover .style_message__21hGA {
  transform: translate(0.625rem, -50%);
}
.style_tooltipFlexible__1Tm26.style_thick__3Bqrn .style_message__21hGA {
  padding: 1rem 1.25rem;
}
.style_tooltipFlexible__1Tm26:hover .style_message__21hGA {
  transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s, visibility 0.1s ease;
  visibility: visible;
  opacity: 1;
}
.style_tooltipFlexible__1Tm26:hover.style_delay__2PsKe .style_message__21hGA {
  transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, visibility 0.1s ease;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_codeblock__38jVo {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
  color: #1b264f;
  font-size: 0.875rem;
  display: flex;
  justify-self: flex-end;
  text-transform: initial;
}
.style_codeblock__38jVo span {
  color: #1679fb;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_rowClass__f-87b > td > div > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.style_campaignSettingsButton__15l2H {
  padding: 0 0.25rem;
  width: 2.5rem;
}
.style_campaignSettingsButton__15l2H svg > g {
  fill: #6a80a7 !important;
}
.style_campaignSettingsButton__15l2H:hover svg > g {
  fill: #333333 !important;
}

.style_dashboardSubtitle__jcWe5 {
  font-size: 0.8125rem;
}
.style_dashboardSubtitle__jcWe5 .style_dot__2nPZN {
  height: 0.375rem;
  width: 0.375rem;
  background-color: #bccfdc;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.625rem 0.125rem;
}

.style_sectionHeading__N3e5l {
  position: relative;
  z-index: 5;
}

.style_campaignTableWrapper__RkO91 {
  overflow: visible !important;
}
.style_campaignTableWrapper__RkO91 .style_activeCampaignAmount__1oCjA {
  font-size: 0.825rem;
  text-transform: capitalize;
  margin-right: 0.5rem;
  font-weight: 600;
}

.style_tableTopBar__1cxG4 {
  display: flex;
  align-items: start;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #e2e9f1;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.style_tableTopBar__1cxG4 .style_filterCampaigns__2ElmU .style_dot__2nPZN {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.375rem;
}
.style_tableTopBar__1cxG4 .style_filterCampaigns__2ElmU .style_promoToggle__186qo {
  position: relative;
}
.style_tableTopBar__1cxG4 .style_filterCampaigns__2ElmU .style_promoToggle__186qo:hover .style_upgradeTip__3zCHt {
  opacity: 1;
  visibility: visible;
}
.style_tableTopBar__1cxG4 .style_filterCampaigns__2ElmU .style_promoToggleButton__1rg0A {
  color: #333333;
}
.style_tableTopBar__1cxG4 .style_filterCampaigns__2ElmU .style_promoToggleButton__1rg0A svg {
  transform: translateY(0.1875rem);
  margin-right: 0.125rem;
}
.style_tableTopBar__1cxG4 .style_shareContainer__1zK96 {
  text-decoration: none;
  margin-left: auto;
  position: relative;
}
.style_tableTopBar__1cxG4 .style_shareContainer__1zK96 ul {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.style_campaignName__3V7Et {
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  color: #0671ee;
}
.style_campaignName__3V7Et .style_icon__19JQe {
  margin-right: 0.375rem;
  transform: translateY(0.125rem);
}
.style_campaignName__3V7Et .style_icon__19JQe svg,
.style_campaignName__3V7Et .style_icon__19JQe img {
  width: 0.875rem;
  height: auto;
}

.style_platformTooltip__1tp9U {
  padding: 0.25rem 0;
}
.style_platformTooltip__1tp9U > span strong {
  font-weight: 800;
  font-size: 0.8125rem;
  color: #1b264f;
}
.style_platformTooltip__1tp9U > div {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin-top: 0.375rem;
  color: #1b264f;
}
.style_platformTooltip__1tp9U > div:first-of-type {
  margin-top: 0.5rem;
}
.style_platformTooltip__1tp9U > div img {
  width: 0.875rem;
  height: auto;
  margin-right: 0.625rem;
}
.style_platformTooltip__1tp9U > div strong {
  margin-right: 0.25rem;
}

.style_zeroState__2mA92 {
  padding: 4rem 0;
}
.style_zeroState__2mA92 p {
  max-width: 30rem;
}

.style_dateTimePicker__19Dcy {
  z-index: 4;
  margin-left: 0 !important;
}

.style_dateContainer__uh2VZ {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: end;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_logoContainer__1TCEi {
  cursor: pointer;
}
.style_logoContainer__1TCEi img {
  aspect-ratio: 3/2;
  object-fit: contain;
}

.style_greyLogo__3BQCZ img {
  filter: grayscale(100%);
  opacity: 0.2;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_platformIcon__2ivHz {
  background-color: rgba(226, 233, 241, 0.3);
  width: 2rem;
  height: 2rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_platformIcon__2ivHz svg {
  height: 0.875rem;
  width: auto;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_forgotPassword__3Qlq4 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #333333;
  text-decoration: underline;
}
.style_forgotPassword__3Qlq4:hover {
  color: #0671ee;
}

.style_formCardFooter__3RnqR {
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-decoration: none;
  margin-top: 2rem;
  text-align: center;
  color: #333333;
}
.style_formCardFooter__3RnqR a {
  color: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_page__1b6HQ {
  position: relative;
  height: 100%;
  padding: 0;
  overflow-y: auto;
}
@media only screen and (max-width: 30rem) {
  .style_page__1b6HQ {
    padding: 0;
  }
}

.style_background__ycC4p {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #fff;
}

.style_containerWrapper__1kTd2 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
@media only screen and (max-width: 30rem) {
  .style_containerWrapper__1kTd2 {
    align-items: stretch;
  }
}
.style_containerWrapper__1kTd2 .style_heroLogin__2hxLo {
  flex: 1.2 1;
  padding-left: 3.5rem;
  background-color: #f8f9fb;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 4.5rem;
}
.style_containerWrapper__1kTd2 .style_heroLogin__2hxLo img {
  position: fixed;
  object-fit: cover;
  object-position: left;
}
@media only all and (max-width: 48rem) {
  .style_containerWrapper__1kTd2 .style_heroLogin__2hxLo {
    display: none;
  }
}

.style_container__2oBGT {
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 31.25rem;
  max-width: 100%;
  transform: translateY(1rem);
  margin-top: -2.5rem;
}
.style_container__2oBGT .style_logoWrapper__1ABIh {
  margin-bottom: 1.75rem;
}
.style_container__2oBGT .style_logoWrapper__1ABIh a {
  text-decoration: none;
}
.style_container__2oBGT .style_logoWrapper__1ABIh svg {
  width: 7.5rem;
  height: auto;
  display: block;
  margin: 0 auto;
}

.style_formCard__2CJz- {
  width: 100%;
  margin: 0 auto;
  max-width: 26.75em;
  padding: 0 1.5rem;
}

.style_formCardHeader__2v9j3 {
  text-align: center;
  margin-bottom: 1.75rem;
}

.style_formCardHeader__2v9j3 h1 {
  font-size: 2rem;
  line-height: 2.875rem;
  margin-bottom: 0;
  font-weight: 800;
  color: #333333;
}

.style_formCardHeader__2v9j3 p {
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #6a80a7;
  display: block;
  margin: 0 auto;
  max-width: 22.5rem;
}

.style_formCardHeader__2v9j3 p strong {
  color: #333333;
}

.style_formCardHeader__2v9j3 img {
  display: block;
  height: 3.625rem;
}

.style_formCardBody__3a1-u p,
.style_formCardBody__3a1-u li {
  font-size: 0.8125rem;
  line-height: 1.8;
  margin-bottom: 0;
}
.style_formCardBody__3a1-u p span,
.style_formCardBody__3a1-u li span {
  display: block;
}
.style_formCardBody__3a1-u ul {
  padding-left: 1.25rem;
  margin: 0.625rem 0 0;
}
.style_formCardBody__3a1-u > div {
  margin-bottom: 0.875rem;
}
.style_formCardBody__3a1-u label {
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
}

@media only screen and (max-width: 30rem) {
  .style_formCard__2CJz- form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .style_formCardBody__3a1-u {
    flex: 1 1 auto;
  }
}
.style_submitButton__ucFo9 {
  margin-top: 2rem;
}

.style_bottomSection__1nXY3 p {
  font-size: 0.9375rem;
  color: #6a80a7;
  margin-top: 1.5rem;
  text-align: center;
}

.style_bottomSection__1nXY3 a {
  font-size: 0.9375rem;
  color: #333333;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.2s ease;
}

.style_bottomSection__1nXY3 a:hover {
  color: #0671ee;
}

.style_formError__3HPwl {
  color: #ffffff;
  background-color: #ff3e00;
  padding: 0.625rem;
  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.8;
  text-align: center;
  text-transform: uppercase;
}
/*
    Typography
*/

$primary-font: "Nunito Sans", sans-serif;
$bold: 700;
$extra-bold: 800;
$black: 900;

/*
    Hawke Colours   
*/

$hawke-dark-grey: #242423;
$hawke-medium-grey: #333333;
$hawke-light-grey: rgba(#242423, 0.05);
$hawke-blue: #0671ee;
$hawke-red: #0671ee;
$hawke-dark-blue: #0671ee;

/*
    Colours
*/

$white: #fff;

$purple: #333333;
$purple-light: #f5f6f9;
$purple-vibrant: #6634ff;
$purple-vibrant-light: #9959ff;

$indigo-dark: #1c0f9f;
$indigo-darker: #242423;

$sky-blue: #0671ee;
$sky-blue-dark: #0671ee;

$blue: #1679fb;
$blue-dark: #1471e9;

$green: #00b178;
$green-light: #e6f9f5;
$green-dark: #015c3f;
$green-mid: #008258;

$orange: #ff9d00;
$orange-mid: #ff6c00;
$orange-dark: #fb1427;
$orange-light: #fef6e9;

$yellow: #ffbc00;
$medium-yellow: #0671ee;

$red: #fb1427;
$red-light: #ffeef0;
$red-dark: #db0000;

$magenta: #cf1382;
$violet: #d73bf6;

$grey-medium: #6a80a7;
$grey-temp: #7e93b8;
$grey-mild: #a0b5c4;
$grey-light: #bccfdc;
$grey-mist: #c5d7e2;
$grey-lines: #e2e9f1;
$grey-lines-light: #eaf0f7;
$grey-bg: #f8f9fb;
$grey-bg-darker: #f3f7ff;
$grey-bg-body: #fdfdfe;
$grey-rhino: #333333;
$grey-graphite: #1b264f;

/*
    Breakpoints
*/
$tablet-breakpoint: 48rem !default;
$laptop-breakpoint: 64rem !default;
$desktop-breakpoint: 86rem !default;

.style_button__2NB1i {
    outline: none;
    border: none;
    border-radius: 0.5rem;
    background: $hawke-medium-grey;
    color: #ffffff;
    font-weight: 800;
    font-size: 0.875rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-family: $primary-font, sans-serif;
    height: 3rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.style_button__2NB1i .style_buttonSuffixHasOnClick__3_JEI,
.style_button__2NB1i .style_buttonSuffixHasOnClick__3_JEI * {
    pointer-events: initial;
}

.style_button__2NB1i * {
    pointer-events: none;
}

.style_button__2NB1i:hover:not(:disabled) {
    background-color: $hawke-dark-grey;
}

.style_button__2NB1i:disabled {
    background-color: $grey-light;
    cursor: initial;
    color: #fff;
    border: none;
}

.style_button__2NB1i > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_button__2NB1i svg {
    fill: #fff;
    transition: fill 0.3s ease-in-out;
}

.style_buttonIcon__2ldXy {
    display: flex;
    align-content: center;
    max-height: 1rem;
    justify-content: center;
    color: #ffffff;

    & + span {
        margin-left: 0.625rem;
        line-height: 1.25rem;
    }

    &:not(.style_smallButton__uNrfy) + span {
        transform: translateY(0.0625rem);
    }
}

.style_secondaryButton__2sDxK {
    color: $purple;
    background-color: #ffffff;
    border: 1px solid $grey-lines;

    &.style_iconOnly__2mV4m {
        padding: 0 0.5rem;

        svg {
            margin-right: 0;
        }
    }

    svg {
        fill: $purple;
    }

    &:disabled {
        background-color: transparent;
        color: $grey-light;
        border: 1px solid $grey-lines;

        svg {
            fill: $grey-lines;
        }
    }
}

.style_slateButton__3TxPM {
    background-color: $grey-medium;

    &:hover:not(:disabled) {
        background-color: $hawke-blue;
    }
}

.style_redButton__2n-5i {
    background-color: $red;

    &:hover:not(:disabled) {
        background-color: $red-dark;
    }
}

.style_orangeButton__2gAIf {
    background-color: $orange;

    &:hover:not(:disabled) {
        background-color: $orange-dark;
    }
}

.style_redLightButton__u2ua7 {
    background-color: $red-light;
    color: $red;

    svg {
        fill: $red;
    }

    &:hover:not(:disabled) {
        background-color: $red;
        color: $white;

        svg {
            fill: $white;
        }
    }
}

.style_blueButton__2mTDf {
    background-color: $blue;

    &:hover:not(:disabled) {
        background-color: $blue-dark;
    }
}

.style_lightBlueButton__2MFYn {
    background-color: $sky-blue;

    &:hover:not(:disabled) {
        background-color: $sky-blue-dark;
    }
}

.style_vibrantBlueButton__3d7kO {
    background-color: $hawke-blue;

    &:hover:not(:disabled) {
        background-color: $hawke-blue;
    }
}

.style_violetButton__2lhBP {
    background-color: $hawke-blue;

    &:hover:not(:disabled) {
        background-color: $hawke-blue;
    }
}
.style_darkerPurpleButton__3S5j2 {
    background-color: $hawke-dark-blue;

    &:hover:not(:disabled) {
        background-color: $hawke-blue;
    }
}

.style_secondaryButton__2sDxK:hover:not(:disabled) {
    color: #fff !important;
    background-color: $hawke-dark-grey !important;
    border-color: $hawke-dark-grey;
    span {
        color: #fff !important;
    }
}

.style_secondaryButton__2sDxK:hover:not(:disabled) svg {
    fill: #fff;
}

.style_tertiaryButton__1WtAz {
    border: 1px solid $grey-lines;
    background-color: #ffffff;
    color: $purple;
}

.style_tertiaryButton__1WtAz:hover:not(:disabled) {
    background-color: $grey-medium !important;
    color: #fff !important;
    border: 1px solid $grey-medium;
}

.style_tertiaryButton__1WtAz:hover:not(:disabled) svg {
    fill: #fff;
}

.style_tertiaryButton__1WtAz svg {
    fill: $grey-medium;
}

.style_greenButton__X0lfd {
    border: 0;
    background-color: $green;
    color: #fff;
}

.style_greenButton__X0lfd:hover:not(:disabled) {
    background-color: $green-mid !important;
    color: #fff !important;
    border: 0;
}

.style_greenButton__X0lfd:hover:not(:disabled) svg {
    fill: #fff;
}

.style_greenButton__X0lfd svg {
    fill: #fff;
}

.style_margin__2F-yT {
    margin: 0px 4px;
}

.style_buttonInverse__2CxfD .style_buttonIcon__2ldXy,
.style_buttonInverse__2CxfD .style_buttonSuffix__c-dDG {
    color: $grey-light;
}

.style_buttonIconSuffix__1dcvw {
    display: flex;
    align-content: center;
    max-height: 1rem;
    justify-content: center;
    margin-left: 0.625rem;
    color: #ffffff;
}

.style_buttonInverse__2CxfD {
    color: #000000;
}

.style_smallButton__uNrfy {
    height: 2.25rem;
    font-size: 0.75rem;
    padding: 0 1rem;

    .style_buttonIcon__2ldXy {
        max-height: 0.875rem;

        svg {
            height: 0.875rem;
            width: 0.875rem;
        }

        & + span {
            transform: none;
        }
    }
}

.style_mediumButton__2BN80 {
    height: 2.5rem;
    font-size: 0.875rem;
    padding: 0 1rem;
}

.style_headerHeight__1-Qe_ {
    height: 2.75rem;
    padding: 0 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
}

.style_downloadButton__ldUh3 {
    background-color: $white;
    border: 1px solid $grey-lines;
    color: $grey-graphite;
    padding: 0 0.9385rem;
    height: 2.5rem;

    span {
        color: $grey-graphite;
    }

    .style_buttonIcon__2ldXy {
        height: 1.125rem;
        transform: translateY(-1px);

        svg {
            fill: $hawke-medium-grey;
            transition: fill 0.3s ease-in-out;
        }
    }

    &:hover:not(:disabled) {
        color: $hawke-dark-grey;
        background-color: $white !important;
        border-color: $hawke-dark-grey;

        .style_buttonIcon__2ldXy svg {
            fill: $hawke-dark-grey;
        }
    }

    &:disabled {
        color: $white;
        span {
            color: $white;
        }
        svg {
            fill: $white;
        }
    }
}

.style_hollowButton__37k2m,
.style_hollowGreenButton__1I6eS,
.style_hollowPurpleButton___q-vx {
    background-color: $white;
    padding: 0 1rem;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    span {
        display: flex;
        align-items: center;
    }

    svg {
        width: auto;
        height: 1rem;
    }

    &.style_iconOnly__2mV4m {
        padding: 0 0.5rem;

        svg {
            margin-right: 0;
        }
    }

    &:disabled {
        background-color: transparent;
        color: $grey-light;
        border: 1px solid $grey-lines;

        svg {
            fill: $grey-lines;
        }
    }
}

.style_hollowButton__37k2m {
    color: $grey-graphite;
    border: 1px solid $grey-lines;

    &:not(:disabled):not(.style_iconFullColor__msYBC) {
        svg,
        path {
            fill: $hawke-medium-grey;
            transition: fill 0.3s ease-in-out;
        }
    }

    &:hover:not(:disabled):not(.style_iconFullColor__msYBC) {
        background-color: $white !important;
        color: $purple;
        border-color: $hawke-dark-grey;

        svg,
        path {
            fill: $hawke-dark-grey;
        }
    }
}

.style_hollowGreenButton__1I6eS {
    color: $green;
    border: 1px solid $grey-lines;

    svg {
        fill: $green;
    }

    &:hover:not(:disabled) {
        background-color: $green-light !important;
        border-color: $green;
    }
}

.style_hollowPurpleButton___q-vx {
    color: $purple;
    border: 1px solid $purple;

    svg {
        fill: $purple;
    }

    &:hover:not(:disabled) {
        background-color: $purple-light !important;
    }
}

.style_hollowWhiteButton__2vozy {
    color: $white;
    background-color: transparent;
    border: 1px solid $white;

    &:hover:not(:disabled) {
        background-color: $white !important;
        color: $hawke-dark-grey;
    }
}

.style_emptyBgButton__1g-zG {
    background-color: transparent;
    border: none;
    padding: 0 1rem;
    color: $grey-graphite;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &.style_iconOnly__2mV4m {
        padding: 0 0.5rem;
    }

    &.style_mediumButton__2BN80 {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    span {
        display: flex;
        align-items: center;

        &:last-of-type {
            transform: translateY(0.0625rem);
        }
    }
    svg,
    svg > g {
        fill: $hawke-dark-grey;
        transition: fill 0.3s ease-in-out;
    }

    &:hover {
        background-color: $hawke-light-grey !important;
        color: $hawke-dark-grey;
        svg,
        svg > g {
            fill: $hawke-dark-grey;
        }
    }

    &:disabled,
    &:disabled:hover {
        background-color: transparent !important;
        color: $grey-light;
        svg,
        svg > g {
            fill: $grey-light;
        }
    }
}

@charset "UTF-8";
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_container__1nsZJ {
  position: relative;
  margin-bottom: 0.625rem;
  max-width: 100%;
}

.style_label__2Atjl {
  display: flex;
  color: #1b264f;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5rem;
  margin-bottom: 0.125rem;
  align-items: center;
}
.style_label__2Atjl > div {
  margin-left: 0.375rem;
}

.style_sideText__1n1Kg {
  position: absolute;
  top: 0;
  right: 0;
}

.style_labelHidden__1wXmJ {
  position: absolute;
  top: -625rem;
  left: -625rem;
  font-size: 0;
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
}

.style_input__1j_tF,
.style_container__1nsZJ .style_inputContainer__13OFa .style_input__1j_tF {
  -webkit-appearance: none !important;
          appearance: none !important;
  flex: 1 1 100%;
  display: block;
  width: 100%;
  height: 2.375rem;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0 0.875rem;
  color: #1b264f;
  background-color: #ffffff;
  border: 0;
  font-family: "Nunito Sans", sans-serif, sans-serif;
}

.style_textarea__3mQum,
.style_container__1nsZJ .style_inputContainer__13OFa .style_textarea__3mQum {
  max-width: 100%;
  max-height: 8rem;
  min-height: 2.375rem;
  padding: 0.6875rem 0.875rem 0.5625rem;
  height: auto;
  margin: 0;
  line-height: 1.125rem;
}

.style_input__1j_tF[type=checkbox] {
  opacity: 0;
}

form[disabled] .style_input__1j_tF {
  pointer-events: none;
}

.style_input__1j_tF:focus {
  outline: none;
}

.style_input__1j_tF::placeholder {
  color: #bccfdc;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  opacity: 1;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0 0 0 62.5rem #ffffff inset !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.style_inputContainer__13OFa {
  position: relative;
  display: flex;
  border: 1px solid #e2e9f1;
  border-radius: 0.5rem;
  transition: border-color 0.3s ease;
  overflow: hidden;
}
.style_inputContainer__13OFa:focus-within {
  border-color: #333333 !important;
}
.style_inputContainer__13OFa:focus-within .style_filledPrefix__2tpjW {
  background-color: #333333;
  color: #fff;
}

.style_darkBorder__1ZnIT .style_inputContainer__13OFa,
.style_darkBorder__1ZnIT .style_prefix__2n_Z8 {
  border-color: #bccfdc;
}

.style_smallInputContainer__2uFlC .style_input__1j_tF,
.style_inputContainer__13OFa .style_input__1j_tF {
  height: 40px;
  font-size: 0.875rem;
  padding: 0.6875rem 0.75rem 0.8125rem 0.75rem;
}

.style_smallInputContainer__2uFlC .style_label__2Atjl {
  margin-bottom: 0;
}

.style_icon__2W3Yu {
  flex: 0 0 2.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}

.style_icon__2W3Yu svg path {
  fill: #888888;
}

.style_inputError__23f57 {
}

.style_inputError__23f57 .style_inputContainer__13OFa {
  border-color: #fb1427 !important;
}

.style_errorMessage__2k9gD {
  color: #fb1427;
  font-size: 0.75rem;
  margin-top: 5px;
}

@media only screen and (min-width: 51.875rem) {
  .style_errorMessage__2k9gD {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 0.5rem);
    border-radius: 0.375rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.05);
    margin-top: 0;
  }
  .style_errorMessage__2k9gD::after {
    content: "";
    width: 0.375rem;
    height: 0.375rem;
    position: absolute;
    top: 50%;
    right: calc(100% - 3px);
    transform: translateY(-50%) rotate(45deg);
    background-color: #1b264f;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.05);
  }
  .style_errorMessage__2k9gD span {
    position: relative;
    display: block;
    font-weight: 700;
    color: #ffffff;
    background-color: #1b264f;
    font-size: 0.75rem;
    line-height: 1;
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    z-index: 1;
    white-space: nowrap;
  }
}
.style_microForm__8oqG3 {
  display: flex;
  margin-bottom: 0.625rem;
}

.style_button__3pye7 {
}

.style_microForm__8oqG3 .style_container__1nsZJ {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.style_microForm__8oqG3 .style_button__3pye7 {
  margin-left: 1rem;
}

.style_microForm__8oqG3 .style_button__3pye7 span {
  margin-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 0;
}

.style_containerCheckbox__2_HCJ {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.style_containerCheckbox__2_HCJ label {
  color: #6a80a7;
  font-family: “Nunito Sans”, sans-serif !important;
  margin-bottom: 0;
}

.style_containerCheckbox__2_HCJ label a {
  text-decoration: none;
  color: #333333;
  font-size: inherit;
}

.style_containerCheckbox__2_HCJ .style_inputContainer__13OFa {
  position: relative;
  border: none;
}

.style_containerCheckbox__2_HCJ .style_inputContainer__13OFa input {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background-color: transparent;
  z-index: 3;
}

.style_containerCheckbox__2_HCJ .style_inputContainer__13OFa input + .style_checkbox__3-l14 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 1rem;
  height: 1rem;
  background-size: 0.75rem 0.75rem;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #c5d7e2;
  border-radius: 0.25rem;
  cursor: pointer;
  pointer-events: none;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.style_containerCheckbox__2_HCJ .style_inputContainer__13OFa input:checked + .style_checkbox__3-l14 {
  background-image: url(/static/media/checkmark-icon.77cd799f.svg);
  background-color: #333333;
  border-color: #333333;
}

.style_inputErrorCheckbox__2FSiO {
}

.style_inputErrorCheckbox__2FSiO .style_inputContainer__13OFa input + .style_checkbox__3-l14 {
  border-color: #fb1427;
}

.style_inlineErrorMessage__3_Vtj {
  color: #fb1427;
  font-size: 0.75rem;
  margin-top: 0.2rem;
}

.style_large__3ColH .style_inputContainer__13OFa .style_input__1j_tF {
  height: 2.625rem;
  font-size: 1rem;
}

.style_extraLarge__9Zu8T .style_inputContainer__13OFa .style_input__1j_tF {
  height: 3.5rem;
  font-size: 1rem;
}

.style_prefix__2n_Z8 {
  color: #6a80a7;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  font-size: 0.875rem;
  padding: 0.0625rem 0.75rem 0;
  font-weight: 800;
  border-right: 1px solid #e2e9f1;
}
.style_prefix__2n_Z8.style_filledPrefix__2tpjW {
  background-color: #e2e9f1;
  color: #6a80a7;
  min-width: 3rem;
  padding: 0.0625rem 0.875rem 0 0.5rem;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.style_prefix__2n_Z8.style_filledPrefix__2tpjW + .style_input__1j_tF {
  border-radius: 0.5rem;
  margin-left: -0.5rem;
  z-index: 1;
}

.style_endPrefix__1cVe5 {
  flex-direction: row-reverse;
}
.style_endPrefix__1cVe5 .style_input__1j_tF {
  margin-right: -0.5rem;
  margin-left: 0 !important;
}
.style_endPrefix__1cVe5 .style_filledPrefix__2tpjW {
  padding: 0.0625rem 1rem 0 1.5rem;
}
.style_endPrefix__1cVe5 .style_prefix__2n_Z8 {
  border-left: 1px solid #e2e9f1;
  border-right: none;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_submitButton__2AHtT {
  -webkit-appearance: none !important;
          appearance: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.125rem;
  font-size: 1rem;
  font-weight: 800;
  line-height: normal;
  padding: 0.875rem;
  color: #ffffff;
  background-color: #333333;
  border: 0;
  cursor: pointer;
  border-radius: 0.625rem;
  transition: background-color 0.2s ease;
  font-family: "Nunito Sans", sans-serif, sans-serif;
}

.style_submitButton__2AHtT:focus {
  outline: none;
}

.style_submitButton__2AHtT:hover {
  background-color: #242423;
}

.style_submitButton__2AHtT:disabled {
  background-color: #e2e9f1;
  cursor: not-allowed;
  color: #fff;
  border: none;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_googleButtonWrapper__1-sts {
  margin-top: 2rem;
}

.style_googleButton__3NYKo {
  width: 100%;
  background-color: #4285f4;
  height: 3.125rem;
  border-radius: 0.625rem;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  position: relative;
}

.style_googleIcon__r_53U {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  position: absolute;
  left: 1rem;
}

.style_googleIcon__r_53U svg {
  width: 100%;
}

.style_divider__3bxw2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.25rem 0 1rem;
}

.style_divider__3bxw2 p {
  margin: 0 1rem;
  color: #6a80a7;
  font-size: 1rem;
}

.style_dividingLine__1P8T6 {
  width: 40%;
  height: 1px;
  background-color: #e2e9f1;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_ErrorWrapper__1Zy2J {
  background-color: #ffeef0;
  min-height: 40px;
  border-radius: 0.625rem;
  padding: 0.5rem 0.875rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.style_ErrorWrapper__1Zy2J p {
  color: #1b264f !important;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 700 !important;
  line-height: 23px;
  margin-top: 1px;
  font-style: normal !important;
  border: none;
}

.style_ErrorWrapper__1Zy2J .style_alertAvatar__1VTve {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.875rem;
  background-color: #fb1427;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}
.style_ErrorWrapper__1Zy2J .style_alertAvatar__1VTve svg {
  height: 0.75rem;
}
.style_ErrorWrapper__1Zy2J .style_alertAvatar__1VTve.style_largeIcon__34lTT {
  height: 2rem;
  width: 2rem;
  border-radius: 0.625rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_SuccessWrapper__3Xiy5 {
  background-color: #e6f9f5;
  height: 40px;
  border-radius: 0.625rem;
  padding: 0.5rem 1rem;
  margin-bottom: 8px;
}

.style_SuccessWrapper__3Xiy5 p {
  color: #1b264f !important;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 700 !important;
  line-height: 23px;
  margin-top: 1px;
  font-style: normal !important;
  border: none;
}

.style_SuccessWrapper__3Xiy5 .style_alertAvatar__2ZZDi {
  padding: 7px;
  float: left;
  margin-right: 12px;
  background-color: #00b178 !important;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_loadingWrapper__3-rN6 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 10rem 0 !important;
  border-radius: 5px;
  text-align: center;
  animation: style_fadein__3O76o 1s linear 1 forwards;
}
@keyframes style_fadein__3O76o {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.style_loadingWrapper__3-rN6 p {
  margin-bottom: 0;
}

.style_loadingWrapper__3-rN6 span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 12px;
  color: #e91e63;
  text-align: center;
}

body {
  background-color: #fdfdfe;
}

.style_loadingTitle__38uMv {
  color: #1b264f;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: center;
  min-width: 240px;
  font-family: "Nunito Sans", sans-serif;
}

.style_loadingMessage__2dERQ {
  color: #1b264f;
  font-size: 0.825rem;
  line-height: 1.25rem;
  font-weight: 600;
  text-align: center;
  min-width: 240px;
  font-family: "Nunito Sans", sans-serif;
}

.style_preloader__3b90g {
  width: 100%;
  height: 100%;
  margin-bottom: 1.5rem;
}

.style_loader__28nCr {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;
  margin: -50px 0 0 -39px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #cdd5e3;
  animation: style_spin__1Hqqg 1.5s linear infinite;
}

.style_loader__28nCr:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #7c8ca9;
  animation: style_spin__1Hqqg 2.5s linear infinite;
}

.style_loader__28nCr:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #a7b4cb;
  animation: style_spin__1Hqqg 1.25s linear infinite;
}
@keyframes style_spin__1Hqqg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_navigationBar__3ACWp {
  display: none;
  flex-shrink: 0; /* Prevent shrinking */
}

@media all and (min-width: 42rem) {
  .style_navigationBar__3ACWp {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: 220px;
    flex-shrink: 0;
    min-width: 1px;
    max-width: 4rem;
    background-color: #fff;
    height: 100%;
    box-sizing: border-box;
    padding: 0 0.625rem 0.5rem 0.625rem;
    z-index: 6;
    transition: max-width 200ms ease-in, min-width 200ms ease-in;
    transition-delay: 0.2s;
    border-right: 1px solid #e2e9f1;
  }
  .style_navigationBar__3ACWp.style_open__18zrU {
    min-width: 15.375rem;
    max-width: 15.375rem;
    transition: max-width 200ms ease-in, min-width 200ms ease-in;
  }
  .style_navigationBar__3ACWp.style_open__18zrU .style_label__2K46r {
    visibility: visible;
  }
  .style_navigationBar__3ACWp.style_open__18zrU .style_customIconButton__kNQ5e {
    display: flex;
    padding-right: 0.75rem;
    align-items: center;
  }
}
.style_navigationList__NFQWx {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.style_navigationList__NFQWx .style_divider__3Qpyj {
  height: 1px;
  background-color: #e2e9f1;
  width: calc(100% + 1.25rem);
  margin: 0 0 0.75rem -0.625rem;
}
.style_navigationList__NFQWx .style_divider__3Qpyj + li {
  margin-top: 0;
  margin-bottom: 0;
}
.style_navigationList__NFQWx .style_accountLogo__2lRm4 {
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #bccfdc;
  border-radius: 0.375rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin: auto auto 1rem auto;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.style_navigationList__NFQWx ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.style_navigationList__NFQWx ul > li {
  position: relative;
  margin-top: 0.375rem;
}
.style_navigationList__NFQWx ul > li .style_customIconButton__kNQ5e:not(.style_active__2ezsW):hover {
  background: rgba(36, 36, 35, 0.05);
}
.style_navigationList__NFQWx ul > li .style_customIconButton__kNQ5e:not(.style_active__2ezsW):hover .style_redDot__2y8Ny {
  border: rgba(36, 36, 35, 0.05) 3px solid;
}
.style_navigationList__NFQWx ul > li .style_customIconButton__kNQ5e:not(.style_active__2ezsW):hover .style_navArrow__KKYgb {
  opacity: 0;
}

.style_lowerNav__14SGI {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;
}

.style_lowerNav__14SGI p {
  margin: 0;
}

.style_navigationBar__3ACWp.style_open__18zrU .style_label__2K46r {
  transition: opacity 0.4s 0.2s ease-out, visibility 0.6s 0.2s ease-out;
  visibility: visible;
  opacity: 1;
}
.style_navigationBar__3ACWp.style_open__18zrU .style_customIconButton__kNQ5e:hover .style_fremiumIcon__Sq444 {
  border: rgba(36, 36, 35, 0.05) 3px solid;
}

.style_label__2K46r {
  font-size: 0.875rem;
  font-weight: 700;
  color: #1b264f;
  opacity: 0;
  visibility: hidden;
  margin-left: 0.125rem;
  transition: display 4s 0.2s ease-in, opacity 0.2s ease-in, visibility 0.2s ease-in;
}

.style_customIconButton__kNQ5e {
  position: relative;
  cursor: pointer;
  height: 2.75rem;
  border-radius: 10px;
  align-items: center;
  display: flex;
  transition: background-color 0.3s ease-in-out;
}
.style_customIconButton__kNQ5e .style_icon__2kpFP {
  min-width: 44px;
  height: 44px;
  display: flex;
  line-height: 0;
  align-items: center;
  justify-content: center;
  position: relative;
}
.style_customIconButton__kNQ5e .style_icon__2kpFP svg,
.style_customIconButton__kNQ5e .style_icon__2kpFP g,
.style_customIconButton__kNQ5e .style_icon__2kpFP path {
  fill: #242423;
}
.style_customIconButton__kNQ5e > svg {
  min-width: 22px;
  max-width: 22px;
  fill: #242423;
  display: flex;
  transition: fill 0.3s ease-in-out;
}
.style_customIconButton__kNQ5e > svg > g,
.style_customIconButton__kNQ5e > svg > path {
  fill: #242423;
  transition: fill 0.3s ease-in-out;
}
.style_customIconButton__kNQ5e.style_active__2ezsW {
  background-color: #242423;
}
.style_customIconButton__kNQ5e.style_active__2ezsW svg,
.style_customIconButton__kNQ5e.style_active__2ezsW svg > g,
.style_customIconButton__kNQ5e.style_active__2ezsW svg > path {
  fill: #fff;
}
.style_customIconButton__kNQ5e.style_active__2ezsW .style_label__2K46r {
  color: #fff;
}
.style_customIconButton__kNQ5e .style_navArrow__KKYgb {
  margin-left: auto;
  height: 0.5rem;
  width: auto;
  transition: opacity 0.3s ease-in-out;
}

.style_iconButton__xS8GF i {
  display: block;
}

.style_iconButton__xS8GF {
  cursor: pointer;
  color: #c1cedb;
}

.style_lockIcon__NgbXf {
  position: absolute;
  right: 2px;
  bottom: 12px;
  background: #242423;
  border-radius: 50%;
  padding: 2px;
  width: 14px;
}

.style_subNavigation__1cnay {
  position: absolute;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  top: -0.4rem;
  left: 100%;
  transform: translateX(0.1825rem);
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(51, 57, 68, 0.05);
  border-radius: 0.625rem;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  min-width: 14.5rem;
  border: 1px solid #eaf0f7;
}
.style_subNavigation__1cnay::after {
  position: absolute;
  content: "";
  left: -5px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  top: 1.4rem;
  background: #ffffff;
  border-left: 1px solid #eaf0f7;
  border-radius: 0 0 0 3px;
}
.style_subNavigation__1cnay::before {
  position: absolute;
  content: "";
  height: 2.75rem;
  width: 0.375rem;
  left: -0.25rem;
  top: 0;
}
.style_subNavigation__1cnay.style_bottomAligned__3jPDQ {
  top: auto;
  bottom: -0.4rem;
}
.style_subNavigation__1cnay.style_bottomAligned__3jPDQ::after {
  bottom: 1.4rem;
  top: auto;
}
.style_subNavigation__1cnay.style_bottomAligned__3jPDQ::before {
  bottom: 0;
  top: auto;
}
.style_subNavigation__1cnay .style_freemiumEnhancedBudgets__3VDWF {
  align-self: flex-end;
  transform: translate(-3.625rem, -0.25rem);
}

.style_dropdownLink__3FPla {
  padding: 0.5rem 0.75rem;
  min-height: 2.375rem;
  border-radius: 0.5rem;
  color: #1b264f;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  position: relative;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.style_dropdownLink__3FPla .style_icon__2kpFP {
  line-height: 0;
}
.style_dropdownLink__3FPla path {
  transition: fill 0.3s ease-in-out;
}
.style_dropdownLink__3FPla:not(.style_fullColor__1_DKE) path {
  fill: #242423;
}
.style_dropdownLink__3FPla:not(.style_fullColor__1_DKE):hover path {
  fill: #242423;
}
.style_dropdownLink__3FPla:not(.style_active__2ezsW):hover {
  cursor: pointer;
  background-color: rgba(36, 36, 35, 0.05);
  color: #242423;
}
.style_dropdownLink__3FPla.style_active__2ezsW {
  color: #fff;
  background-color: #242423;
}
.style_dropdownLink__3FPla.style_active__2ezsW:not(.style_fullColor__1_DKE) svg, .style_dropdownLink__3FPla.style_active__2ezsW:not(.style_fullColor__1_DKE) g, .style_dropdownLink__3FPla.style_active__2ezsW:not(.style_fullColor__1_DKE) path {
  fill: #fff;
}
.style_dropdownLink__3FPla svg,
.style_dropdownLink__3FPla img {
  margin-right: 0.75rem;
  width: 1rem;
  height: 1rem;
}

.style_headerNav__1LK_G {
  color: #383c46;
  margin: 0.3rem;
  padding: 0.5rem 0.75rem;
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.style_competitorLink__1qRB_ svg {
  margin-right: 0.75rem;
  max-width: 18px;
  max-height: 18px;
}

.style_competitorLink__1qRB_:not(.style_fullColor__1_DKE) path {
  fill: #bccfdc;
}

.style_competitorLink__1qRB_:not(.style_fullColor__1_DKE):hover path {
  fill: #242423;
}

.style_competitorLink__1qRB_:hover {
  cursor: pointer;
  background-color: #f8f9fb;
}

.style_dropdownContainer__2nFea {
  position: relative;
}
.style_dropdownContainer__2nFea::before {
  content: "";
  position: absolute;
  top: 0;
  right: -0.5rem;
  height: 100%;
  width: 0.625rem;
}
.style_dropdownContainer__2nFea:hover .style_subNavigation__1cnay {
  visibility: visible;
  opacity: 1;
}

.style_competitorCount__KwEws {
  font-size: 10px;
  color: #ffffff;
  font-weight: 900;
  width: 1.875rem;
  height: 1.25rem;
  background-color: #fb1427;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.style_open__18zrU .style_toggle__ZnSxv {
  width: calc(100% - 1.25rem);
}

.style_open__18zrU .style_toggle__ZnSxv svg {
  transform: scaleX(-1);
  transition-delay: 0s;
}

.style_open__18zrU .style_toggle__ZnSxv .style_label__2K46r {
  pointer-events: initial;
  width: 100%;
}

.style_open__18zrU .style_accountLogo__2lRm4 {
  width: 4.5rem;
  height: 4.5rem;
}

.style_toggle__ZnSxv {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 69%;
  height: 44px;
  border-radius: 10px;
  transition: width 200ms ease-in-out 0.4s, background-color 200ms ease-in-out;
}
.style_toggle__ZnSxv:not(.style_active__2ezsW):hover {
  background-color: rgba(36, 36, 35, 0.05);
}
.style_toggle__ZnSxv svg {
  margin: 0 1.125rem 0 0.875rem;
  transition: transform 200ms ease-in-out 0.4s;
}
.style_toggle__ZnSxv svg,
.style_toggle__ZnSxv g,
.style_toggle__ZnSxv path {
  fill: #242423;
}
.style_toggle__ZnSxv .style_label__2K46r {
  pointer-events: none;
  line-height: 6px;
  margin-left: 0;
}

.style_countDotWrapper__1_Fp_ {
  top: 0.1875rem;
  right: 0.1875rem;
  position: absolute;
  width: 1.875rem;
  height: 1.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: right 0.3s ease-in-out 0.2s;
}
.style_countDotWrapper__1_Fp_ .style_competitorCount__KwEws {
  right: 0.125rem;
}
.style_countDotWrapper__1_Fp_ .style_redDot__2y8Ny {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.5rem;
  background: #fb1427;
  border: 3px solid #242423;
  transition: border-color 200ms ease-in-out;
}

.style_active__2ezsW .style_redDot__2y8Ny {
  border: 3px solid #242423;
}

.style_fremiumIcon__Sq444 {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  top: -0.125rem;
  right: -0.125rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_fremiumIcon__Sq444 svg {
  width: 0.5rem;
  height: 0.375rem;
}

.style_freemiumListItemIcon__153JB {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  position: relative;
}
.style_freemiumListItemIcon__153JB svg {
  margin: 0px auto;
}
.style_freemiumListItemIcon__153JB svg path {
  fill: #fff !important;
}

.style_contentEnter__3HwCn {
  opacity: 0;
}

.style_contentEnterActive__3RlmO {
  opacity: 1;
}

.style_contentExit__1nms- {
  opacity: 1;
  top: 0;
  bottom: 0;
  margin: auto;
}

.style_contentExitActive__2d6RT {
  opacity: 0;
}

.style_contentEnterActive__3RlmO,
.style_contentExitActive__2d6RT {
  transition: opacity 0.3s ease-in-out;
}

.style_fremiumEnter__2EJsH {
  opacity: 0;
}

.style_fremiumEnterActive__2PFDc {
  opacity: 1;
}

.style_fremiumExit__dUvZC {
  opacity: 1;
  left: 12.6rem;
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
}

.style_fremiumExitActive__6vljw {
  opacity: 0;
}

.style_fremiumEnterActive__2PFDc,
.style_fremiumExitActive__6vljw {
  transition: opacity 250ms;
}

.style_open__18zrU .style_countDotWrapper__1_Fp_,
.style_open__18zrU .style_fremiumIcon__Sq444 {
  right: 1.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
.style_open__18zrU .style_contentEnter__3HwCn {
  opacity: 0;
}
.style_open__18zrU .style_contentEnterActive__3RlmO {
  opacity: 1;
}
.style_open__18zrU .style_contentExit__1nms- {
  opacity: 1;
  left: 1.85rem;
  top: 0.375rem;
  bottom: initial;
}
.style_open__18zrU .style_contentExitActive__2d6RT {
  opacity: 0;
}
.style_open__18zrU .style_fremiumEnter__2EJsH {
  opacity: 0;
}
.style_open__18zrU .style_fremiumEnterActive__2PFDc {
  opacity: 1;
}
.style_open__18zrU .style_fremiumExit__dUvZC {
  opacity: 1;
  left: 1.6rem;
  width: 1.25rem;
  height: 1.25rem;
  top: 0;
  bottom: initial;
}
.style_open__18zrU .style_fremiumExitActive__6vljw {
  opacity: 0;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.styles_icon__2kJ2X {
  display: inline-block;
  padding: 0 0.25rem;
  line-height: 0;
}

.styles_icon__2kJ2X img {
  width: 16px;
  max-width: 100%;
  display: inline-block;
}

.styles_iconCircle__1WRgC {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #333333;
  border-radius: 100%;
}

.styles_iconCircle__1WRgC svg {
  fill: #ffffff;
}

.styles_ArrowIcon__1fBL- {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.875rem;
  height: 0.875rem;
  background-color: #00b178;
  border-radius: 100%;
  margin-right: 0.5rem;
}

.styles_noMargin__1EJSH {
  margin-right: 0;
}

.styles_ArrowIconRed__2RLl6 {
  background-color: #fb1427;
}

.styles_ArrowIconDown__155Uf img {
  transform: rotate(180deg);
}

.styles_ArrowIconBlank__2k2dy {
  background-color: #bccfdc;
  color: #fff;
}

.styles_ArrowIconBlank__2k2dy span {
  font-size: 18px;
  font-weight: 800;
  display: inline-block;
  padding-left: 1px;
  padding-bottom: 2px;
}

.styles_ArrowIcon__1fBL- svg {
  fill: #ffffff;
}

.styles_objectSymbol__3edEs {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_saveBar__18Gek {
  justify-content: space-between;
  position: relative;
  transition: opacity 0.3s ease-in-out 1s;
  background-color: rgba(51, 51, 51, 0.75) !important;
}
.style_saveBar__18Gek .style_icon__3FGVr {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  margin-right: 1rem;
  background-color: #00b178;
  position: relative;
  transition: opacity 0.3s ease-in-out 1s;
}
.style_saveBar__18Gek .style_icon__3FGVr svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out 1s;
  fill: #fff;
}
.style_saveBar__18Gek .style_savedText__1gCRn {
  position: absolute;
  top: 50%;
  left: 4.375rem;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-in-out 1s;
}
.style_saveBar__18Gek .style_savedText__1gCRn strong {
  font-weight: 800;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_button__2NB1i {
  outline: none;
  border: none;
  border-radius: 0.5rem;
  background: #333333;
  color: #ffffff;
  font-weight: 800;
  font-size: 0.875rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-family: "Nunito Sans", sans-serif, sans-serif;
  height: 3rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.style_button__2NB1i .style_buttonSuffixHasOnClick__3_JEI,
.style_button__2NB1i .style_buttonSuffixHasOnClick__3_JEI * {
  pointer-events: initial;
}

.style_button__2NB1i * {
  pointer-events: none;
}

.style_button__2NB1i:hover:not(:disabled) {
  background-color: #242423;
}

.style_button__2NB1i:disabled {
  background-color: #bccfdc;
  cursor: initial;
  color: #fff;
  border: none;
}

.style_button__2NB1i > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_button__2NB1i svg {
  fill: #fff;
  transition: fill 0.3s ease-in-out;
}

.style_buttonIcon__2ldXy {
  display: flex;
  align-content: center;
  max-height: 1rem;
  justify-content: center;
  color: #ffffff;
}
.style_buttonIcon__2ldXy + span {
  margin-left: 0.625rem;
  line-height: 1.25rem;
}
.style_buttonIcon__2ldXy:not(.style_smallButton__uNrfy) + span {
  transform: translateY(0.0625rem);
}

.style_secondaryButton__2sDxK {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #e2e9f1;
}
.style_secondaryButton__2sDxK.style_iconOnly__2mV4m {
  padding: 0 0.5rem;
}
.style_secondaryButton__2sDxK.style_iconOnly__2mV4m svg {
  margin-right: 0;
}
.style_secondaryButton__2sDxK svg {
  fill: #333333;
}
.style_secondaryButton__2sDxK:disabled {
  background-color: transparent;
  color: #bccfdc;
  border: 1px solid #e2e9f1;
}
.style_secondaryButton__2sDxK:disabled svg {
  fill: #e2e9f1;
}

.style_slateButton__3TxPM {
  background-color: #6a80a7;
}
.style_slateButton__3TxPM:hover:not(:disabled) {
  background-color: #0671ee;
}

.style_redButton__2n-5i {
  background-color: #fb1427;
}
.style_redButton__2n-5i:hover:not(:disabled) {
  background-color: #db0000;
}

.style_orangeButton__2gAIf {
  background-color: #ff9d00;
}
.style_orangeButton__2gAIf:hover:not(:disabled) {
  background-color: #fb1427;
}

.style_redLightButton__u2ua7 {
  background-color: #ffeef0;
  color: #fb1427;
}
.style_redLightButton__u2ua7 svg {
  fill: #fb1427;
}
.style_redLightButton__u2ua7:hover:not(:disabled) {
  background-color: #fb1427;
  color: #fff;
}
.style_redLightButton__u2ua7:hover:not(:disabled) svg {
  fill: #fff;
}

.style_blueButton__2mTDf {
  background-color: #1679fb;
}
.style_blueButton__2mTDf:hover:not(:disabled) {
  background-color: #1471e9;
}

.style_lightBlueButton__2MFYn {
  background-color: #0671ee;
}
.style_lightBlueButton__2MFYn:hover:not(:disabled) {
  background-color: #0671ee;
}

.style_vibrantBlueButton__3d7kO {
  background-color: #0671ee;
}
.style_vibrantBlueButton__3d7kO:hover:not(:disabled) {
  background-color: #0671ee;
}

.style_violetButton__2lhBP {
  background-color: #0671ee;
}
.style_violetButton__2lhBP:hover:not(:disabled) {
  background-color: #0671ee;
}

.style_darkerPurpleButton__3S5j2 {
  background-color: #0671ee;
}
.style_darkerPurpleButton__3S5j2:hover:not(:disabled) {
  background-color: #0671ee;
}

.style_secondaryButton__2sDxK:hover:not(:disabled) {
  color: #fff !important;
  background-color: #242423 !important;
  border-color: #242423;
}
.style_secondaryButton__2sDxK:hover:not(:disabled) span {
  color: #fff !important;
}

.style_secondaryButton__2sDxK:hover:not(:disabled) svg {
  fill: #fff;
}

.style_tertiaryButton__1WtAz {
  border: 1px solid #e2e9f1;
  background-color: #ffffff;
  color: #333333;
}

.style_tertiaryButton__1WtAz:hover:not(:disabled) {
  background-color: #6a80a7 !important;
  color: #fff !important;
  border: 1px solid #6a80a7;
}

.style_tertiaryButton__1WtAz:hover:not(:disabled) svg {
  fill: #fff;
}

.style_tertiaryButton__1WtAz svg {
  fill: #6a80a7;
}

.style_greenButton__X0lfd {
  border: 0;
  background-color: #00b178;
  color: #fff;
}

.style_greenButton__X0lfd:hover:not(:disabled) {
  background-color: #008258 !important;
  color: #fff !important;
  border: 0;
}

.style_greenButton__X0lfd:hover:not(:disabled) svg {
  fill: #fff;
}

.style_greenButton__X0lfd svg {
  fill: #fff;
}

.style_margin__2F-yT {
  margin: 0px 4px;
}

.style_buttonInverse__2CxfD .style_buttonIcon__2ldXy,
.style_buttonInverse__2CxfD .style_buttonSuffix__c-dDG {
  color: #bccfdc;
}

.style_buttonIconSuffix__1dcvw {
  display: flex;
  align-content: center;
  max-height: 1rem;
  justify-content: center;
  margin-left: 0.625rem;
  color: #ffffff;
}

.style_buttonInverse__2CxfD {
  color: #000000;
}

.style_smallButton__uNrfy {
  height: 2.25rem;
  font-size: 0.75rem;
  padding: 0 1rem;
}
.style_smallButton__uNrfy .style_buttonIcon__2ldXy {
  max-height: 0.875rem;
}
.style_smallButton__uNrfy .style_buttonIcon__2ldXy svg {
  height: 0.875rem;
  width: 0.875rem;
}
.style_smallButton__uNrfy .style_buttonIcon__2ldXy + span {
  transform: none;
}

.style_mediumButton__2BN80 {
  height: 2.5rem;
  font-size: 0.875rem;
  padding: 0 1rem;
}

.style_headerHeight__1-Qe_ {
  height: 2.75rem;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.style_downloadButton__ldUh3 {
  background-color: #fff;
  border: 1px solid #e2e9f1;
  color: #1b264f;
  padding: 0 0.9385rem;
  height: 2.5rem;
}
.style_downloadButton__ldUh3 span {
  color: #1b264f;
}
.style_downloadButton__ldUh3 .style_buttonIcon__2ldXy {
  height: 1.125rem;
  transform: translateY(-1px);
}
.style_downloadButton__ldUh3 .style_buttonIcon__2ldXy svg {
  fill: #333333;
  transition: fill 0.3s ease-in-out;
}
.style_downloadButton__ldUh3:hover:not(:disabled) {
  color: #242423;
  background-color: #fff !important;
  border-color: #242423;
}
.style_downloadButton__ldUh3:hover:not(:disabled) .style_buttonIcon__2ldXy svg {
  fill: #242423;
}
.style_downloadButton__ldUh3:disabled {
  color: #fff;
}
.style_downloadButton__ldUh3:disabled span {
  color: #fff;
}
.style_downloadButton__ldUh3:disabled svg {
  fill: #fff;
}

.style_hollowButton__37k2m,
.style_hollowGreenButton__1I6eS,
.style_hollowPurpleButton___q-vx {
  background-color: #fff;
  padding: 0 1rem;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.style_hollowButton__37k2m span,
.style_hollowGreenButton__1I6eS span,
.style_hollowPurpleButton___q-vx span {
  display: flex;
  align-items: center;
}
.style_hollowButton__37k2m svg,
.style_hollowGreenButton__1I6eS svg,
.style_hollowPurpleButton___q-vx svg {
  width: auto;
  height: 1rem;
}
.style_hollowButton__37k2m.style_iconOnly__2mV4m,
.style_hollowGreenButton__1I6eS.style_iconOnly__2mV4m,
.style_hollowPurpleButton___q-vx.style_iconOnly__2mV4m {
  padding: 0 0.5rem;
}
.style_hollowButton__37k2m.style_iconOnly__2mV4m svg,
.style_hollowGreenButton__1I6eS.style_iconOnly__2mV4m svg,
.style_hollowPurpleButton___q-vx.style_iconOnly__2mV4m svg {
  margin-right: 0;
}
.style_hollowButton__37k2m:disabled,
.style_hollowGreenButton__1I6eS:disabled,
.style_hollowPurpleButton___q-vx:disabled {
  background-color: transparent;
  color: #bccfdc;
  border: 1px solid #e2e9f1;
}
.style_hollowButton__37k2m:disabled svg,
.style_hollowGreenButton__1I6eS:disabled svg,
.style_hollowPurpleButton___q-vx:disabled svg {
  fill: #e2e9f1;
}

.style_hollowButton__37k2m {
  color: #1b264f;
  border: 1px solid #e2e9f1;
}
.style_hollowButton__37k2m:not(:disabled):not(.style_iconFullColor__msYBC) svg,
.style_hollowButton__37k2m:not(:disabled):not(.style_iconFullColor__msYBC) path {
  fill: #333333;
  transition: fill 0.3s ease-in-out;
}
.style_hollowButton__37k2m:hover:not(:disabled):not(.style_iconFullColor__msYBC) {
  background-color: #fff !important;
  color: #333333;
  border-color: #242423;
}
.style_hollowButton__37k2m:hover:not(:disabled):not(.style_iconFullColor__msYBC) svg,
.style_hollowButton__37k2m:hover:not(:disabled):not(.style_iconFullColor__msYBC) path {
  fill: #242423;
}

.style_hollowGreenButton__1I6eS {
  color: #00b178;
  border: 1px solid #e2e9f1;
}
.style_hollowGreenButton__1I6eS svg {
  fill: #00b178;
}
.style_hollowGreenButton__1I6eS:hover:not(:disabled) {
  background-color: #e6f9f5 !important;
  border-color: #00b178;
}

.style_hollowPurpleButton___q-vx {
  color: #333333;
  border: 1px solid #333333;
}
.style_hollowPurpleButton___q-vx svg {
  fill: #333333;
}
.style_hollowPurpleButton___q-vx:hover:not(:disabled) {
  background-color: #f5f6f9 !important;
}

.style_hollowWhiteButton__2vozy {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}
.style_hollowWhiteButton__2vozy:hover:not(:disabled) {
  background-color: #fff !important;
  color: #242423;
}

.style_emptyBgButton__1g-zG {
  background-color: transparent;
  border: none;
  padding: 0 1rem;
  color: #1b264f;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.style_emptyBgButton__1g-zG.style_iconOnly__2mV4m {
  padding: 0 0.5rem;
}
.style_emptyBgButton__1g-zG.style_mediumButton__2BN80 {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.style_emptyBgButton__1g-zG span {
  display: flex;
  align-items: center;
}
.style_emptyBgButton__1g-zG span:last-of-type {
  transform: translateY(0.0625rem);
}
.style_emptyBgButton__1g-zG svg,
.style_emptyBgButton__1g-zG svg > g {
  fill: #242423;
  transition: fill 0.3s ease-in-out;
}
.style_emptyBgButton__1g-zG:hover {
  background-color: rgba(36, 36, 35, 0.05) !important;
  color: #242423;
}
.style_emptyBgButton__1g-zG:hover svg,
.style_emptyBgButton__1g-zG:hover svg > g {
  fill: #242423;
}
.style_emptyBgButton__1g-zG:disabled, .style_emptyBgButton__1g-zG:disabled:hover {
  background-color: transparent !important;
  color: #bccfdc;
}
.style_emptyBgButton__1g-zG:disabled svg,
.style_emptyBgButton__1g-zG:disabled svg > g, .style_emptyBgButton__1g-zG:disabled:hover svg,
.style_emptyBgButton__1g-zG:disabled:hover svg > g {
  fill: #bccfdc;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_promptContainer__235Wi {
  position: fixed;
  left: 50%;
  bottom: 3rem;
  z-index: 2;
  transform: translate(-50%, 2rem);
  transition: opacity 1s ease, visibility 1s ease, box-shadow 0.3s ease-in-out;
}

@media print {
  .style_promptContainer__235Wi {
    display: none;
  }
}
.style_defaultPrompt__1zaWg {
  position: relative;
  border-radius: 0.75rem;
  background-color: #333333;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  box-shadow: 0 0.625rem 1.25rem 0 rgba(27, 38, 79, 0.12);
}
.style_defaultPrompt__1zaWg::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0 #333333;
  transition: box-shadow 0.6s ease;
  background-color: #333333;
  border-radius: 0.75rem;
}
.style_defaultPrompt__1zaWg > * {
  z-index: 1;
}
.style_defaultPrompt__1zaWg p {
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding-right: 1rem;
}
.style_defaultPrompt__1zaWg .style_icon__2gwsQ {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  border-radius: 0.625rem;
  background-color: #333333;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.style_defaultPrompt__1zaWg .style_icon__2gwsQ svg {
  fill: #fff;
  width: 1rem;
  height: 1rem;
}
.style_defaultPrompt__1zaWg .style_icon__2gwsQ.style_red__3dgfy {
  background-color: #fb1427;
}
.style_defaultPrompt__1zaWg .style_icon__2gwsQ.style_green__2BVPR {
  background-color: #00b178;
}
.style_defaultPrompt__1zaWg button {
  min-width: 6.25rem;
  text-align: center;
}
.style_defaultPrompt__1zaWg:hover {
  box-shadow: 0 0.875rem 1.5rem 0 rgba(27, 38, 79, 0.2);
}
.style_defaultPrompt__1zaWg:hover::before {
  box-shadow: 0 0 0 0.125rem #333333;
}

.style_savePrompt__zcBEg {
  background-color: #333333;
  border-radius: 0.875rem;
  padding: 1.25rem 0.875rem;
  display: flex;
  align-items: center;
  box-shadow: 0 0.625rem 1.25rem 0 rgba(27, 38, 79, 0.12);
}
.style_savePrompt__zcBEg p {
  color: #fff;
  font-size: 0.875rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_videoModal__2c4nF {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
}
.style_videoModal__2c4nF .style_close__1IzjY {
  position: absolute;
  right: 2.125rem;
  top: 2.125rem;
  background-color: transparent;
  z-index: 1;
}
@media (min-width: 64rem) {
  .style_videoModal__2c4nF .style_close__1IzjY {
    right: 3.75rem;
    top: 3.125rem;
  }
}
.style_videoModal__2c4nF .style_close__1IzjY > div::after, .style_videoModal__2c4nF .style_close__1IzjY > div::before {
  background-color: #fff;
}
.style_videoModal__2c4nF .style_close__1IzjY:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.style_videoModal__2c4nF .style_close__1IzjY:hover > div::after, .style_videoModal__2c4nF .style_close__1IzjY:hover > div::before {
  background-color: #fff;
}

.style_modalContent__1zz5x {
  position: relative;
  margin: 3rem 0;
  width: 80vw;
  max-width: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 1rem;
  flex-direction: column;
  padding: 1rem;
}
@media (min-width: 86rem) {
  .style_modalContent__1zz5x {
    max-width: 37.5rem;
  }
}
.style_modalContent__1zz5x .style_modalContentText__3GyiB {
  padding: 2.25rem 2rem 2rem;
  color: #1b264f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.style_modalContent__1zz5x .style_modalContentText__3GyiB svg {
  width: 0.9375rem;
}
.style_modalContent__1zz5x .style_modalContentText__3GyiB h1 {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 26px;
  line-height: 26px;
  margin-bottom: 4px;
}
.style_modalContent__1zz5x .style_modalContentText__3GyiB p {
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 22px;
  max-width: 31.25rem;
  margin-bottom: 1.25rem;
  text-align: center;
}
.style_modalContent__1zz5x .style_modalContentText__3GyiB span {
  font-weight: 800;
}

.style_modalClose__2lQMZ {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #fff;
  font-size: 2rem;
  line-height: 2rem;
  margin-left: auto;
  cursor: pointer;
  background-color: #333333;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  margin-bottom: 0.5rem;
  transition: background-color 0.2s ease-in-out;
}
.style_modalClose__2lQMZ:hover, .style_modalClose__2lQMZ:focus {
  background-color: #0671ee;
}
.style_modalClose__2lQMZ::before, .style_modalClose__2lQMZ::after {
  content: "";
  background-color: #fff;
  height: 0.125rem;
  border-radius: 0.0625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.25rem;
}
.style_modalClose__2lQMZ::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.style_modalClose__2lQMZ::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.style_iframeWrapper__37lRe {
  background: #282828;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 0.625rem;
}
.style_iframeWrapper__37lRe .style_modalIframe__3MlOt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.style_defaultState__1cZtX {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
}

.style_contents__3AXWq {
    display: flex;
    height: 100vh; /* Full viewport height */
}


.style_middleContent__1ZJ8t{
    flex-grow: 1; /* Make the middle column flexible */
    overflow-y: hidden;
    height: calc(100vh - 64px);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_logo__3zQoK {
  position: relative;
  display: inline-block;
  width: 4rem;
  display: flex;
  align-items: center;
  transition: width 200ms ease-in;
  transition-delay: 0.2s;
  padding-left: 1rem;
  border-right: 1px solid #e2e9f1;
}
.style_logo__3zQoK .style_logoIcon__1pcg7 {
  position: absolute;
  left: 1rem;
}
.style_logo__3zQoK .style_logoFull__1E_Yt {
  position: absolute;
  left: 3.4rem;
  width: 100px;
  top: 1.1rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease-in, visibility 200ms ease-in;
}
.style_logo__3zQoK .style_logoFull__1E_Yt svg {
  width: 100px;
}
.style_logo__3zQoK.style_logoExpanded__cWlmw {
  width: 15.375rem;
  transition-delay: 0s;
}
.style_logo__3zQoK.style_logoExpanded__cWlmw .style_logoFull__1E_Yt {
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 500px) {
  .style_logo__3zQoK {
    cursor: pointer;
  }
}
@media only all and (max-width: 48rem) {
  .style_logo__3zQoK {
    display: none;
  }
}
.style_askChatGpt__3Ltqz svg {
  width: 1rem !important;
  height: 1rem !important;
}
.style_askChatGpt__3Ltqz:disabled svg {
  fill: #bccfdc;
}
.style_askChatGpt__3Ltqz:disabled svg path {
  fill: #bccfdc;
}
.style_askChatGpt__3Ltqz span:nth-child(2) {
  margin-left: 0.35rem;
}

.style_userTag__2aFBJ {
  display: flex;
  align-items: center;
}
.style_userTag__2aFBJ .style_ring__1wuRH {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
  top: -0.3125rem;
  left: -0.3125rem;
  border-radius: 50%;
  border: 0.1875rem solid #333333;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.style_userTag__2aFBJ .style_userArrowIcon__pLnsD {
  display: flex;
  margin-left: 0.625rem;
}
.style_userTag__2aFBJ .style_userArrowIcon__pLnsD svg {
  fill: #1b264f;
  transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;
}
@media all and (min-width: 700px) {
  .style_userTag__2aFBJ .style_userName__zf62z {
    vertical-align: middle;
    display: inline-block;
    margin: 0 0 0 0.5rem;
    color: #1b264f;
  }
}
.style_userTag__2aFBJ:hover .style_ring__1wuRH, .style_userTag__2aFBJ.style_open__yvmaC .style_ring__1wuRH {
  opacity: 1;
}
.style_userTag__2aFBJ:hover .style_userArrowIcon__pLnsD svg, .style_userTag__2aFBJ.style_open__yvmaC .style_userArrowIcon__pLnsD svg {
  fill: #333333;
}
.style_userTag__2aFBJ.style_open__yvmaC .style_userArrowIcon__pLnsD svg {
  transform: rotate(180deg);
}

.style_userPanelContainer__3hTW6 {
  height: 4rem;
  z-index: 50;
  display: flex;
  min-height: 64px;
  background-color: #fff;
}

.style_userPanelContainerChildren__2qR1L {
  align-items: center;
  display: flex;
  flex: 1 1;
  padding: 0 1rem;
  min-width: 0;
  border-bottom: 1px solid #eaf0f7;
  box-shadow: 0 1px 4px 0 rgba(56, 69, 81, 0.03);
}

.style_userOptions__3RrBy {
  cursor: pointer;
  position: relative;
  margin-right: 1.75rem;
}
.style_userOptions__3RrBy.style_userMenuOptions__212FW {
  margin-right: 0;
}

.style_helpIcon__ZPoA5 {
  cursor: pointer;
  text-decoration: none;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  border-radius: 0.625rem;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
}
.style_helpIcon__ZPoA5 svg {
  height: 1.25rem;
  width: 1.25rem;
  transition: fill 0.3s ease-in-out;
}
.style_helpIcon__ZPoA5:hover, .style_helpIcon__ZPoA5.style_isOpen__y3nue {
  background-color: #f5f6f9;
}
.style_helpIcon__ZPoA5:hover svg, .style_helpIcon__ZPoA5.style_isOpen__y3nue svg {
  fill: #333333;
}

@media all and (max-width: 700px) {
  .style_userPanelContainerChildren__2qR1L {
    padding: 0 1rem;
  }
  .style_userArrowIcon__pLnsD {
    display: none !important;
  }
  .style_ring__1wuRH {
    display: none;
  }
  .style_userMenuOptions__212FW > ul {
    display: none;
  }
  .style_helpIcon__ZPoA5 {
    display: none;
  }
  .style_userName__zf62z {
    display: none;
  }
}
.style_upgradeButton__3ROXT {
  margin-right: 2rem;
  color: #0671ee;
}
.style_upgradeButton__3ROXT span:first-of-type {
  max-height: 1rem;
}
.style_upgradeButton__3ROXT span:first-of-type svg {
  width: 1rem;
  height: 1rem;
}
.style_upgradeButton__3ROXT span:last-of-type {
  margin-left: 0.375rem;
  font-size: 0.875rem;
}
.style_upgradeButton__3ROXT:hover {
  color: #242423;
}

.style_userMenuRoot__2fhhK .style_userMenuPaper__MbwCc {
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100% !important;
  border-radius: 1.25rem 1.25rem 0 0 !important;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem !important;
}

.style_userMenuHeading__jPxEu {
  font-size: 1.125rem;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 0.625rem;
}

.style_buttonLogOut__2tNNe {
  margin-top: 1.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_container__36EtX {
  position: absolute;
  border-radius: 0.625rem;
  background-color: #fff;
  border: 1px solid #e2e9f1;
  margin: 0;
  padding: 0;
  bottom: -0.25rem;
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.08);
}
.style_container__36EtX.style_hideBorder__1SVyL {
  border: none;
}
.style_container__36EtX.style_top__hreIb {
  top: 0;
}
.style_container__36EtX.style_bottom__1XiJN {
  bottom: 0;
}
.style_container__36EtX.style_right__2Pier {
  right: 0;
}
.style_container__36EtX.style_left__W1Jl0 {
  left: 0;
}
.style_container__36EtX.style_small__2ile7 {
  border-radius: 0.5rem;
}

.style_open__2hziP {
  opacity: 1;
  visibility: initial;
  display: block;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_simpleListItem__1up4X {
  color: #1b264f;
  font-size: 14px;
  list-style: none;
  padding: 0.375rem 0.5rem 0.25rem;
  font-weight: 400;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  line-height: 24px;
  min-height: 2.25rem;
  align-items: center;
  white-space: nowrap;
}
.style_simpleListItem__1up4X > a {
  transition: color 0.3s ease-in-out;
}
.style_simpleListItem__1up4X:first-child {
  margin-top: 0.5rem;
}
.style_simpleListItem__1up4X:last-child {
  margin-bottom: 0.5rem;
}
.style_simpleListItem__1up4X svg,
.style_simpleListItem__1up4X svg > g {
  transition: fill 0.3s ease-in-out;
  fill: #333333;
}
.style_simpleListItem__1up4X:hover {
  background-color: rgba(36, 36, 35, 0.05);
  color: #242423;
}
.style_simpleListItem__1up4X:hover > a {
  color: #242423;
}
.style_simpleListItem__1up4X:hover svg,
.style_simpleListItem__1up4X:hover svg > g {
  fill: #242423;
}
.style_simpleListItem__1up4X.style_isActive__zjBkm {
  font-weight: 700;
}
.style_simpleListItem__1up4X.style_wrap__1d_bQ {
  white-space: normal;
}
.style_simpleListItem__1up4X.style_small__3qMHx {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  min-height: 2rem;
  font-size: 0.8125rem;
  border-radius: 0.375rem;
}
.style_simpleListItem__1up4X.style_small__3qMHx:first-child {
  margin-top: 0.25rem;
}
.style_simpleListItem__1up4X.style_small__3qMHx:last-child {
  margin-bottom: 0.25rem;
}
.style_simpleListItem__1up4X.style_small__3qMHx.style_active__3UBBc {
  font-weight: 700;
}
.style_simpleListItem__1up4X.style_disabled__2XhpG {
  pointer-events: none;
  opacity: 0.6;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_userDropdown__4HaIb {
  min-width: 15.5rem;
  right: -0.5rem !important;
  bottom: -0.5rem;
}

.style_userDropdown__4HaIb hr {
  height: 1px;
  background: #e2e9f1;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.style_userDropdown__4HaIb li {
  margin: 0 0.5rem;
}

.style_anomalyDropdown__2dZeW {
  bottom: 1.8rem;
  right: -0.45rem;
  z-index: 10;
}

.style_anomalyDropdownListItem__22QtK {
  margin-top: 0.5rem;
  white-space: nowrap;
}

.style_emailTestDropdown__3Bm_i {
  padding: 4px;
  min-width: 120px;
  right: 40px;
  bottom: 10px;
  z-index: 10;
}

.style_emailTestDropdownListItem__zvMwr {
  margin-top: 0.5rem;
  white-space: nowrap;
}

.style_viewsDropdown__3Wteu {
  z-index: 10;
  padding: 0.5rem 0;
  right: -0.35rem;
  bottom: 0rem;
}

.style_viewsDropdownListItem__3vv0M {
  color: #1b264f !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  justify-content: left !important;
  line-height: 2rem;
  min-width: 9rem;
  padding: 0 0.75rem !important;
  margin: 0 0.5rem;
}

.style_viewsDropdownDelete__1L-Be {
  color: #fb1427 !important;
}

.style_helpsDropdown__1Xy7W {
  z-index: 10;
  padding: 0.5rem 0 0;
  right: -0.5rem !important;
  bottom: -0.125rem;
  width: auto;
  text-align: left;
}

.style_helpsDropdown__1Xy7W hr {
  height: 1px;
  background: #e2e9f1;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.style_helpsDropdownTitle__3QG17 {
  width: 200px;
  color: #1b264f;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  padding: 0.5rem 1rem;
  cursor: default;
}

.style_helpsDropdownListItem__22Kz9 {
  color: #1b264f !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  justify-content: left !important;
  line-height: 2rem;
  min-width: 9rem;
  padding: 0 1rem !important;
  margin: 0 0.5rem;
}

.style_link__3Oubs {
  text-decoration: none;
  color: #1b264f;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: block;
  text-align: left;
}

@media (max-width: 48rem) {
  .style_largeScreenOnly__2d7fk {
    display: none;
  }
}
.style_shareDropdown__2F4Dw {
  z-index: 10;
  left: 0;
  bottom: -0.3rem;
  border-radius: 0.625rem;
}
.style_shareDropdown__2F4Dw a {
  text-decoration: none;
}
.style_shareDropdown__2F4Dw .style_dropDownLink__2sjRz {
  padding: 0.75rem 0.875rem;
  display: flex;
  align-items: flex-start;
}
.style_shareDropdown__2F4Dw .style_dropDownLink__2sjRz svg {
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  margin-right: 1rem;
  margin-top: 0.25rem;
}
.style_shareDropdown__2F4Dw .style_dropDownLink__2sjRz h3 {
  font-size: 14px;
  font-weight: 800;
  margin: 0;
  line-height: 18px;
}
.style_shareDropdown__2F4Dw .style_dropDownLink__2sjRz p {
  max-width: 250px;
  white-space: break-spaces;
  line-height: 16px;
}
.style_shareDropdown__2F4Dw .style_dropDownLink__2sjRz.style_shareLink__3b3JO svg > g {
  fill: #333333;
}
.style_shareDropdown__2F4Dw .style_dropDownLink__2sjRz.style_shareLink__3b3JO svg > g > g {
  fill: #333333;
}

.style_dropdownItem__Dx8H5 {
  display: flex;
  align-items: center;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.style_dropdownItem__Dx8H5 svg {
  width: 1rem;
  height: 1rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_avatarHeader__27ItM {
  padding: 1rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: default;
}

.style_avatarHeaderInfo__3ah_m {
  display: inline-block;
  margin-left: 0.75rem;
  text-transform: capitalize;
}

.style_avatarHeaderUsername__3NCeX {
  color: #1b264f;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 800;
}

.style_avatarHeaderSubtitle__jcabF {
  color: #6a80a7;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;
}

@media (max-width: 48rem) {
  .style_avatarHeader__27ItM {
    padding: 1rem 0 0.5rem;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_notificationsContainer__3_MQU {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  margin-right: 0.75rem;
}
.style_notificationsContainer__3_MQU .style_notificationDropdown__1boII {
  right: -0.5rem;
  bottom: -0.125rem;
}

.style_notificationsContainer__3_MQU ul {
  right: 0.5rem !important;
  width: 26.5rem !important;
}

.style_notificationsContainer__3_MQU hr {
  height: 1px;
  background: #e2e9f1;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.style_notificationIcon__1qYaA {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}
.style_notificationIcon__1qYaA svg {
  fill: #6a80a7;
  height: 1.375rem;
  width: 1.125rem;
  transition: fill 0.3s ease-in-out;
}
.style_notificationIcon__1qYaA:hover, .style_notificationIcon__1qYaA.style_isOpen__125fJ {
  background-color: #f5f6f9;
}
.style_notificationIcon__1qYaA:hover svg, .style_notificationIcon__1qYaA.style_isOpen__125fJ svg {
  fill: #333333;
}
.style_notificationIcon__1qYaA:hover + .style_counter__2Ulur, .style_notificationIcon__1qYaA.style_isOpen__125fJ + .style_counter__2Ulur {
  border-color: #f5f6f9;
}
@media (max-width: 48rem) {
  .style_notificationIcon__1qYaA {
    display: none;
  }
}

.style_notificationsCollection__1V4PC {
  height: 25rem;
  overflow-y: auto;
}
.style_notificationsCollection__1V4PC a {
  text-decoration: none;
}

.style_searchAlerts__2APZf {
  border-bottom: 1px solid #e2e9f1;
  padding-left: 0.5rem;
  width: 100% !important;
}
.style_searchAlerts__2APZf > input {
  background-color: transparent;
  border: none;
}
.style_searchAlerts__2APZf > svg {
  left: 1.25rem !important;
}

.style_mentionNotification__-OF2X {
  display: flex;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  padding: 0.625rem 1.25rem 1rem;
  position: relative;
  border-bottom: 1px solid #e2e9f1;
  transition: background-color 0.3s ease-in-out;
}

.style_mentionNotificationInner__3xZa6 {
  display: flex;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.style_mentionNotificationInner__3xZa6 span {
  font-size: 0.75rem;
  font-weight: 400;
  color: #6a80a7;
}

.style_mentionNotificationInner__3xZa6 > img,
.style_mentionNotificationInner__3xZa6 > .style_image__21tw8 {
  width: 2rem !important;
  height: 2rem !important;
  min-width: 2rem !important;
  margin-right: 1rem;
  border-radius: 2rem;
  overflow: hidden;
}

.style_mentionComment__1izfA {
  color: #1b264f;
  font-size: 0.88rem;
  line-height: 1.25rem;
}

.style_mentionText__lYm0U {
  font-weight: bold;
  font-size: 0.88rem;
}

.style_mentionAccount__1T4k- {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  color: #6a80a7;
}

.style_mentionMetaInfo__3Yb7U {
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
}

.style_mentionInfo__1saZ8 p {
  margin: 0;
}

.style_mentionTime__3hqJG {
  color: #a0b5c4;
  font-size: 0.75rem;
}

.style_mentionInfo__1saZ8 {
  width: 100%;
}

.style_notificationHeader__mMh4u {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.375rem 1.125rem 1rem 1.25rem;
  border-bottom: 1px solid #e2e9f1;
  cursor: default;
}
.style_notificationHeader__mMh4u span {
  font-size: 1.25rem;
  font-weight: 800;
  color: #1b264f;
}
.style_notificationHeader__mMh4u svg {
  width: 1.25rem;
  height: 1.25rem;
}
.style_notificationHeader__mMh4u svg path {
  fill: #242423;
}
.style_notificationHeader__mMh4u .style_alertSettingsButton__3PXnp {
  cursor: pointer;
}

.style_notificationFooter__264Zj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  height: 3.25rem;
  cursor: default;
  border-top: 1px solid #e2e9f1;
}
.style_notificationFooter__264Zj > span {
  font-size: 0.75rem;
  font-weight: 400;
  color: #6a80a7;
}

.style_mentionNotificationIcon__1hPhx {
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.style_mentionNotification__-OF2X:hover {
  cursor: pointer;
  background-color: #f5f6f9;
}

.style_mentionNotification__-OF2X:hover .style_deleteButton__1CM-- {
  opacity: 1;
  visibility: visible;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.style_deleteButton__1CM-- {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 1.25rem;
  top: 0.75rem;
  z-index: 10000;
}

.style_deleteButton__1CM-- span {
  color: #6a80a7 !important;
  font-size: 14px !important;
  width: 0.5rem;
  height: 0.5rem;
}

.style_deleteButton__1CM--:hover span {
  color: #fb1427 !important;
}

.style_counter__2Ulur {
  width: 1.188rem;
  height: 1.188rem;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.375rem;
  right: 3px;
  font-weight: 900;
  font-size: 0.63rem;
  color: #fff;
  background: #fb1427;
  border-radius: 2rem;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
}
.style_counter__2Ulur svg {
  width: unset;
  height: unset;
}

.style_noNotifications__F3FrG {
  padding: 4.25rem 3rem 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Nunito Sans";
  text-align: center;
  cursor: default;
}

.style_noNotifications__F3FrG h2 {
  color: #1b264f;
  font-size: 1.25rem;
  font-weight: 800;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.style_noNotifications__F3FrG p {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #6a80a7;
}

.style_mention__30Emi {
  color: #333333;
  background: #ebf5fe;
  border-radius: 0.25rem;
  padding: 0 2px;
}

.style_mentionNotification__-OF2X.style_read__H-_-8 .style_mentionText__lYm0U {
  color: #1b264f;
}

.style_mentionNotification__-OF2X.style_read__H-_-8 .style_mentionComment__1izfA,
.style_mentionNotification__-OF2X.style_read__H-_-8 .style_mentionAccount__1T4k- {
  color: #6a80a7;
}

.style_notificationsContainer__3_MQU .style_avatar__1-bW7 {
  background-color: #ffbc00 !important;
  padding: 0.75rem;
  margin-right: 1rem;
}

.style_notificationsContainer__3_MQU .style_avatar__1-bW7 svg {
  fill: #fff;
  padding: 0;
  margin: 0;
}

@media (max-width: 630px) {
  .style_notificationsContainer__3_MQU ul {
    width: 18rem;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_searchWrapper__VmzWM {
  position: relative;
  width: 12rem;
}
.style_searchWrapper__VmzWM > svg {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
}
.style_searchWrapper__VmzWM:focus-within > svg {
  fill: #333333;
}

.style_filterSearch__koCJT {
  background-color: rgba(226, 233, 241, 0.3);
  height: 2.5rem;
  width: 100%;
  padding: 0.875rem 0.875rem 0.875rem 2.25rem;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 0.5rem;
  border: 1px solid rgba(226, 233, 241, 0.3);
  outline: none;
  font-size: 0.875rem;
  background-repeat: no-repeat;
  background-position: 0.875rem center;
  color: #1b264f;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.style_filterSearch__koCJT:focus {
  outline: none;
  border-color: #333333;
  background-color: #fff;
}
.style_filterSearch__koCJT::placeholder {
  font-size: 0.875rem;
  color: #a0b5c4;
  font-family: "Nunito Sans", sans-serif;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_close__3PPY8 {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}

.style_customDialog__2rfQv div {
  font-family: "Nunito Sans", sans-serif !important;
}
.style_customDialog__2rfQv h3 {
  color: #1b264f !important;
  font-size: 1.25rem !important;
  font-weight: 800 !important;
  line-height: 1.5rem !important;
  padding: 3.5rem 0 0 3.5rem !important;
}
.style_customDialog__2rfQv h2 {
  color: #1b264f !important;
  font-size: 1.5rem !important;
  font-weight: 800 !important;
  line-height: 1.5rem !important;
  padding: 3.5rem 0 0 0 !important;
  margin-bottom: 0.25rem !important;
}
.style_customDialog__2rfQv .style_dialogPaper__3gGI1 {
  border-radius: 0.875rem !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0 0.875rem 2.813rem, rgba(0, 0, 0, 0.09) 0 0.625rem 1.125rem !important;
  width: 100%;
}
.style_customDialog__2rfQv .style_dialogContent__2igSm {
  padding: 0.5rem 3.5rem 3.5rem 3.5rem !important;
}
.style_customDialog__2rfQv .style_dialogFooter__3OchH {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 0 0 0.625rem 0.625rem;
  background-color: #f8f9fb;
  border-top: 1px solid #e2e9f1;
  padding: 1rem 2rem !important;
}
.style_customDialog__2rfQv .style_dialogFooter__3OchH button {
  margin-left: 0.5rem;
  height: 2.5rem;
  justify-content: flex-end !important;
}
.style_customDialog__2rfQv:not(.style_dialogOpen__2xwmj) .style_dialogWrapper__3h4kY {
  transform: translateY(4rem) scale(0.7) !important;
}

.style_dialogRoot__10quF .style_paper__dk9Wv {
  border-radius: 0.875rem;
  padding: 2.5rem 5.25rem;
  width: 42rem;
  max-width: 42rem;
  box-shadow: 0 0.875rem 2.75rem rgba(0, 0, 0, 0.09), 0 0.625rem 1.125rem rgba(0, 0, 0, 0.09);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_wrapper__3Q7yw {
  background-color: #fff;
  height: 100%;
  position: relative;
  right: 0;
  border-left: 1px solid #eaf0f7;
  flex-shrink: 0; /* Prevent shrinking */
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}
.style_wrapper__3Q7yw.style_open__exCsS {
  opacity: 1;
  visibility: visible;
}
.style_wrapper__3Q7yw .style_headerContainer__19x0b {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e2e9f1;
  grid-gap: 1rem;
  gap: 1rem;
}
.style_wrapper__3Q7yw .style_headerContainer__19x0b .style_headerTitle__3o1uO {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.625rem;
  min-height: 2.5rem;
  text-align: left;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-grow: 1;
}
.style_wrapper__3Q7yw .style_headerContainer__19x0b .style_headerTitle__3o1uO svg {
  width: 1.5rem;
  height: 1.5rem;
}
.style_wrapper__3Q7yw .style_headerContainer__19x0b .style_closeIcon__181tI {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.style_wrapper__3Q7yw .style_headerContainer__19x0b .style_closeIcon__181tI svg {
  width: 1rem;
  height: 1rem;
}
.style_wrapper__3Q7yw .style_top__2NE1r {
  padding: 16px 20px;
}
.style_wrapper__3Q7yw .style_top__2NE1r > div {
  margin-bottom: 0;
}
.style_wrapper__3Q7yw .style_top__2NE1r .style_closeButton__1_LFg {
  width: 2.25rem;
  margin-left: 1rem;
}
.style_wrapper__3Q7yw .style_top__2NE1r .style_closeButton__1_LFg svg {
  width: 10px;
  height: 10px;
  fill: #242423;
}
.style_wrapper__3Q7yw .style_top__2NE1r .style_dropDownItem__1_5Yx {
  width: 9rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  z-index: 1;
}
.style_wrapper__3Q7yw .style_top__2NE1r .style_dropDownItem__1_5Yx ul {
  width: auto !important;
}
.style_wrapper__3Q7yw .style_top__2NE1r .style_iconBackground__J_oA2 {
  background-color: #10a37f;
}
.style_wrapper__3Q7yw .style_middle__3UkPt {
  padding: 1rem;
  overflow-y: auto;
  transition: height 1s ease-in-out;
  height: calc(100vh - 220px);
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: column;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_avatar__3ToOE {
  float: left;
  background: #f1f4f8;
  border: 0;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_avatar__3ToOE img,
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_avatar__3ToOE svg {
  width: auto !important;
  height: 0.875rem !important;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_userMessage__3a9ha {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: top;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_userMessage__3a9ha p {
  color: #333333 !important;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_assistantMessage__1krXX {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: top;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_assistantMessage__1krXX sample {
  background-color: #f8f9fb;
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 0.5rem;
  display: block;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_conversation__3eyj_ .style_assistantMessage__1krXX sample:hover {
  background-color: #e2e9f1;
}
.style_wrapper__3Q7yw .style_middle__3UkPt img {
  cursor: pointer;
}
.style_wrapper__3Q7yw .style_middle__3UkPt div,
.style_wrapper__3Q7yw .style_middle__3UkPt p,
.style_wrapper__3Q7yw .style_middle__3UkPt span,
.style_wrapper__3Q7yw .style_middle__3UkPt h1,
.style_wrapper__3Q7yw .style_middle__3UkPt h2,
.style_wrapper__3Q7yw .style_middle__3UkPt h3,
.style_wrapper__3Q7yw .style_middle__3UkPt h4,
.style_wrapper__3Q7yw .style_middle__3UkPt h5,
.style_wrapper__3Q7yw .style_middle__3UkPt ul,
.style_wrapper__3Q7yw .style_middle__3UkPt ol,
.style_wrapper__3Q7yw .style_middle__3UkPt li,
.style_wrapper__3Q7yw .style_middle__3UkPt code,
.style_wrapper__3Q7yw .style_middle__3UkPt pre {
  font-family: "Nunito Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  color: #242423;
  white-space: normal;
}
.style_wrapper__3Q7yw .style_middle__3UkPt h1,
.style_wrapper__3Q7yw .style_middle__3UkPt h2,
.style_wrapper__3Q7yw .style_middle__3UkPt h3,
.style_wrapper__3Q7yw .style_middle__3UkPt h4,
.style_wrapper__3Q7yw .style_middle__3UkPt h5 {
  font-weight: 700;
}
.style_wrapper__3Q7yw .style_middle__3UkPt strong {
  font-weight: 600;
  color: #000;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_loadingWrapper__1db53 {
  margin: auto;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_loadingWrapper__1db53 p {
  margin-top: 1rem;
  text-align: center;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_samplesWrapper__KFj2P h1 {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0rem;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_samplesWrapper__KFj2P .style_samplesContainer__m-OV8 {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_samplesWrapper__KFj2P .style_samplesContainer__m-OV8 .style_sample__7-E6s {
  background-color: #f8f9fb;
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 0.5rem;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_samplesWrapper__KFj2P .style_samplesContainer__m-OV8 .style_sample__7-E6s:hover {
  background-color: #e2e9f1;
}
.style_wrapper__3Q7yw .style_middle__3UkPt .style_attachment__2EXic {
  margin: 1rem 0;
}
.style_wrapper__3Q7yw .style_bottom__91v6A {
  padding: 20px;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.style_wrapper__3Q7yw .style_bottom__91v6A button {
  width: 64px;
  height: 40px;
}
.style_wrapper__3Q7yw .style_bottom__91v6A textarea {
  color: #8698a5;
  font-family: "Nunito Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  border-radius: 8px;
  border: none;
  background-color: rgba(106, 128, 167, 0.07);
  width: 100%;
  height: 40px;
  padding: 12px 16px;
  transition: height 1s ease-in-out;
}
.style_wrapper__3Q7yw .style_bottom__91v6A textarea:disabled {
  cursor: none;
  color: #8698a5;
}
.style_wrapper__3Q7yw .style_bottom__91v6A textarea:focus {
  color: #242423;
  border: none;
  outline: 0;
}

.style_hawkCharacter__nt0jl {
  width: 48px;
  height: 48px;
  position: relative;
  background-size: 140%;
  background-position: 50% 50%;
}

@media only all and (max-width: 48rem) {
  .style_top__2NE1r {
    display: none;
  }
  .style_wrapper__3Q7yw {
    border: none;
    height: calc(100% - 9.3125rem);
    display: flex;
    flex-direction: column;
    margin-top: 1.3125rem;
  }
  .style_middle__3UkPt {
    flex: 1 1;
    padding: 1.25rem !important;
  }
  .style_middle__3UkPt > div > h3 {
    font-size: 1rem !important;
    font-weight: 800 !important;
  }
  .style_middle__3UkPt .style_samplesContainer__m-OV8 {
    display: flex !important;
    flex-direction: column !important;
  }
  .style_bottom__91v6A {
    padding: 0.625rem 1rem !important;
  }
  .style_bottom__91v6A > img {
    display: none !important;
  }
  .style_bottom__91v6A > textarea {
    padding-right: 3rem !important;
    overflow: auto !important;
  }
  .style_bottom__91v6A > button {
    width: 2rem !important;
    height: 2rem !important;
    padding: 0;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1rem;
    right: 1.375rem;
  }
  .style_bottom__91v6A > button > span {
    line-height: 0;
  }
  .style_bottom__91v6A > button > span > svg {
    height: 0.875rem;
  }
}
.style_ThreeDots___7J5A {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1.5rem;
}

.style_ThreeDots___7J5A span {
    height: 0.375rem;
    width: 0.375rem;
    border-radius: 50%;
    animation: style_sk-bouncedelay__2YRXM 1.4s infinite ease-in-out both;
}

.style_ThreeDots___7J5A span:first-child {
    animation-delay: -0.32s;
}

.style_ThreeDots___7J5A span:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes style_sk-bouncedelay__2YRXM {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.style_smallDots__2K7bC {
    width: 1rem;
}

.style_smallDots__2K7bC span {
    height: 0.25rem;
    width: 0.25rem;
}

.style_requestState__2EZfP {
    max-width: 600px;
    margin: 10% auto;
}

/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_cardContainer__1WS01 {
  padding: 1.5rem 1.5rem 1.75rem 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid #e2e9f1;
  box-shadow: 0 2px 4px 0 rgba(51, 57, 68, 0.05);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_competitorsWarning__2W-GD {
  border-radius: 0.625rem !important;
  background-color: rgba(255, 157, 0, 0.1) !important;
  padding: 1rem !important;
  box-shadow: none;
  display: table;
  width: 100%;
  position: relative;
  font-family: "Nunito Sans", sans-serif !important;
  text-align: left;
  border-color: transparent;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.style_competitorsWarning__2W-GD h3 {
  color: #1b264f;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 0.15rem;
}

.style_competitorsWarning__2W-GD p,
.style_competitorsWarning__2W-GD li,
.style_competitorsWarning__2W-GD span {
  color: #1b264f;
  font-size: 14px;
  line-height: 1.375rem;
}

.style_competitorsWarningAvatar__1ghWR {
  background-color: #ff9d00 !important;
  float: left;
  padding: 12px;
  font-weight: 800;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
}

.style_competitorsWarningAvatar__1ghWR img {
  object-fit: none;
}

.style_competitorsWarningAvatar__1ghWR path {
  fill: #fff !important;
}

.style_buttons__1AvFd {
  display: flex;
}
.style_buttons__1AvFd .style_competitorsWarningButton__2oX8B {
  margin-left: 1rem;
}
.style_buttons__1AvFd .style_competitorsWarningButton__2oX8B:first-child {
  margin-left: 0;
}
.style_buttons__1AvFd .style_competitorsWarningButton__2oX8B span {
  color: #ffffff !important;
  font-size: 0.875rem !important;
}

.style_closeButton__3cbwm {
  background-color: transparent;
  padding: 3px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.style_closeButton__3cbwm span {
  color: #ffbc00 !important;
}

.style_closeButton__3cbwm:hover {
  background-color: #ffbc00 !important;
  padding: 3px;
}

.style_closeButton__3cbwm:hover span {
  color: #fff !important;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_container__22Dmh {
  color: #e2e9f1;
  width: 100%;
  background: #242423;
  position: relative;
  top: 0;
  z-index: 100;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.style_button__33cGm {
  position: absolute;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

.style_standardNotification__3GqpL {
  margin: 0;
  font-size: 0.875rem;
  padding: 0.5rem;
  text-align: center;
}

.style_standardNotification__3GqpL a {
  font-size: 0.875rem;
  color: #e2e9f1;
  cursor: pointer;
  text-decoration: none;
  font-weight: 800;
}

.style_standardNotification__3GqpL a:hover {
  color: #fff;
}

.style_standardNotification__3GqpL a:active,
.style_standardNotification__3GqpL a:focus {
  color: #ffffff;
}

.style_notificationContainer__3jcHT {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.style_notificationContainer__3jcHT p {
  flex: 1 1;
  margin: 0.5rem;
}

.style_btnDismiss__1688c {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  flex: 0 1;
  font-size: 1.5rem;
  outline: 0;
  height: 0.875rem;
  width: 0.875rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-in-out;
}
.style_btnDismiss__1688c::after, .style_btnDismiss__1688c::before {
  content: "";
  display: block;
  width: 0.0625rem;
  height: 0.875rem;
  background-color: #fff;
  border-radius: 0.125rem;
  position: absolute;
  left: 0;
  top: 0;
  transition: background-color 0.3s ease-in-out;
}
.style_btnDismiss__1688c::after {
  transform: rotate(45deg);
}
.style_btnDismiss__1688c::before {
  transform: rotate(-45deg);
}
.style_btnDismiss__1688c span {
  position: absolute;
  opacity: 0;
  line-height: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

.style_btnDismiss__1688c:active,
.style_btnDismiss__1688c:focus,
.style_btnDismiss__1688c:hover {
  opacity: 0.5;
}

.style_sliderContainer__3xlKr {
  position: relative;
}

.style_carouselControls__1NKx0 {
  position: absolute;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  right: 4.44rem;
}

.style_carouselControls__1NKx0 p {
  color: #ffffff;
  margin: 0;
  font-size: 0.88rem;
}

.style_carouselControls__1NKx0 p span:first-of-type img {
  transform: rotate(180deg);
}

.style_carouselControls__1NKx0 p span img {
  margin-bottom: -2px;
}

.style_carouselControls__1NKx0 p span {
  font-weight: 800;
  cursor: pointer;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_modalWrapper__CDc2I {
  width: 100%;
  padding: 2rem 0;
}

.style_backbutton__3W5y- {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.style_logo__2ajIt {
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  width: 9.875rem;
  height: auto;
}

.style_heading__K_A1J {
  text-align: center;
  margin-bottom: 2rem;
}
.style_heading__K_A1J h2 {
  margin-bottom: 0.75rem;
  font-weight: 800;
  font-size: 1.75rem;
  line-height: 2rem;
}
.style_heading__K_A1J h2 strong {
  font-weight: 800;
}
.style_heading__K_A1J p {
  font-weight: 300;
  font-size: 1.375rem;
  line-height: 1.5rem;
}
.style_heading__K_A1J p strong {
  font-weight: 800;
}

.style_pricingToggle__2pBpJ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.75rem;
  flex-direction: column;
}
@media (min-width: 64rem) {
  .style_pricingToggle__2pBpJ {
    flex-direction: row;
  }
}
.style_pricingToggle__2pBpJ.style_appSumo__2zsNp {
  margin-bottom: 4.75rem;
}
.style_pricingToggle__2pBpJ .style_toggleButton__3-BHV {
  width: 15.625rem;
  height: 3rem;
  background-color: #f8f9fb;
  border-radius: 1.5rem;
  display: flex;
  padding: 0.25rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media (min-width: 64rem) {
  .style_pricingToggle__2pBpJ .style_toggleButton__3-BHV {
    margin-left: 12.25rem;
  }
}
.style_pricingToggle__2pBpJ .style_toggleButton__3-BHV::before {
  content: "";
  border: 1px solid #eaf0f7;
  background-color: #fff;
  box-shadow: 0 0.124rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  width: 7.5rem;
  height: 2.5rem;
  top: 0.25rem;
  left: 0.25rem;
  position: absolute;
  border-radius: 1.25rem;
  transition: left 0.3s ease-in-out;
}
.style_pricingToggle__2pBpJ .style_toggleButton__3-BHV.style_toggle__3RnVt::before {
  left: 7.875rem;
}
.style_pricingToggle__2pBpJ .style_toggleButton__3-BHV .style_toggleOption__3P-io {
  padding: 0.375rem 1rem 0.25rem;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  position: relative;
}
.style_pricingToggle__2pBpJ .style_toggleButton__3-BHV .style_toggleOption__3P-io:not(.style_active__2yIe4) {
  cursor: pointer;
}
.style_pricingToggle__2pBpJ .style_toggleButton__3-BHV .style_toggleOption__3P-io:not(.style_active__2yIe4):hover span {
  color: #333333;
}
.style_pricingToggle__2pBpJ .style_toggleButton__3-BHV .style_toggleOption__3P-io span {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 800;
  transition: color 0.3s ease-in-out;
}
.style_pricingToggle__2pBpJ p {
  color: #00b178;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-left: 0.75rem;
  margin-top: 0.375rem;
}
@media (min-width: 64rem) {
  .style_pricingToggle__2pBpJ p {
    margin-top: 0;
  }
}

.style_options__1JFHq {
  width: 100%;
  max-width: 67.5rem;
  margin: 0 auto;
}
@media (min-width: 48rem) {
  .style_options__1JFHq {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
}
@media (min-width: 64rem) {
  .style_options__1JFHq {
    grid-column-gap: 1.875rem;
    column-gap: 1.875rem;
  }
}
.style_options__1JFHq .style_option__2OHkf {
  border: 1px solid #e2e9f1;
  background-color: #fff;
  border-radius: 1.25rem;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  position: relative;
  margin-bottom: 0.5rem;
}
@media (min-width: 48rem) {
  .style_options__1JFHq .style_option__2OHkf {
    margin-bottom: 0;
  }
}
.style_options__1JFHq .style_option__2OHkf:hover {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(51, 57, 68, 0.08);
}
.style_options__1JFHq .style_option__2OHkf.style_basic__2QVMM .style_top__1IUZN h2 {
  color: #242423;
}
.style_options__1JFHq .style_option__2OHkf.style_basic__2QVMM .style_bottom__1jiq7 .style_price__2SbTY {
  font-size: 2.5rem;
}
.style_options__1JFHq .style_option__2OHkf.style_basic__2QVMM .style_bottom__1jiq7 .style_info__11Vac {
  margin-top: 0.875rem;
}
.style_options__1JFHq .style_option__2OHkf.style_basic__2QVMM.style_appSumo__2zsNp .style_bottom__1jiq7 .style_price__2SbTY {
  font-size: 2rem;
  margin-top: 2rem;
}
.style_options__1JFHq .style_option__2OHkf.style_basic__2QVMM.style_appSumo__2zsNp .style_bottom__1jiq7 .style_info__11Vac {
  margin-top: 0.5rem;
}
.style_options__1JFHq .style_option__2OHkf.style_basic__2QVMM.style_inTrial__3tL9v .style_bottom__1jiq7 .style_priceDeets__1hITr {
  margin-top: 0.625rem;
}
.style_options__1JFHq .style_option__2OHkf.style_basic__2QVMM.style_inTrial__3tL9v .style_bottom__1jiq7 .style_info__11Vac {
  margin-top: 0.125rem;
}
.style_options__1JFHq .style_option__2OHkf.style_pro__18D4P {
  box-shadow: 0 12px 24px 0 rgba(84, 113, 142, 0.12);
}
.style_options__1JFHq .style_option__2OHkf.style_pro__18D4P.style_appSumo__2zsNp .style_bottom__1jiq7 .style_price__2SbTY {
  font-size: 2.5rem;
}
.style_options__1JFHq .style_option__2OHkf.style_pro__18D4P.style_appSumo__2zsNp .style_bottom__1jiq7 .style_price__2SbTY > span {
  color: #fb1427;
}
.style_options__1JFHq .style_option__2OHkf.style_custom__3Iqtu .style_top__1IUZN h2,
.style_options__1JFHq .style_option__2OHkf.style_custom__3Iqtu .style_bottom__1jiq7 h2 {
  font-size: 30px;
  line-height: 32px;
}
.style_options__1JFHq .style_option__2OHkf.style_custom__3Iqtu .style_bottom__1jiq7 {
  height: 100%;
}
.style_options__1JFHq .style_option__2OHkf.style_custom__3Iqtu .style_bottom__1jiq7 h2 {
  max-width: 15.125rem;
  display: block;
  margin: 2rem auto 0;
}
.style_options__1JFHq .style_option__2OHkf.style_custom__3Iqtu .style_bottom__1jiq7 .style_info__11Vac {
  margin-top: 0.5rem;
}
.style_options__1JFHq .style_option__2OHkf .style_top__1IUZN {
  text-align: center;
  border-bottom: 1px solid #e2e9f1;
  padding: 0.9375rem 1rem 0.875rem 1rem;
}
.style_options__1JFHq .style_option__2OHkf .style_top__1IUZN h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 800;
  margin-bottom: 0;
  color: #0671ee;
}
.style_options__1JFHq .style_option__2OHkf .style_topHighlight__1ebdf {
  position: absolute;
  background-color: #9959ff;
  text-align: center;
  border-radius: 1.125rem 1.125rem 0 0;
  padding: 0.4375rem 0.5rem 1.4375rem;
  position: absolute;
  top: -2.375rem;
  left: 0;
  width: 100%;
  z-index: -1;
}
.style_options__1JFHq .style_option__2OHkf .style_topHighlight__1ebdf span {
  color: #fff;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.5rem;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 {
  padding: 1.375rem 1.5rem 2.75rem;
  text-align: center;
  height: calc(100% - 54px);
  min-height: 15.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 86rem) {
  .style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 {
    padding: 1.375rem 3rem 2.75rem;
  }
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 800;
  margin-bottom: 0;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_prePrice__3twzG {
  margin-bottom: 0.5rem;
  display: inline-block;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_price__2SbTY {
  font-size: 3rem;
  line-height: 2.75rem;
  font-weight: 800;
  display: block;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_price__2SbTY small {
  font-weight: 400;
  font-size: 1rem;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_price__2SbTY strike {
  text-decoration: none;
  position: relative;
  margin-right: 0.375rem;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_price__2SbTY strike::after {
  content: "";
  width: calc(100% + 0.25rem);
  height: 0.125rem;
  border-radius: 0.125rem;
  background-color: #1b264f;
  position: absolute;
  top: 50%;
  left: -0.125rem;
  transform: rotate(-15deg) translateY(-50%);
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_priceDeets__1hITr {
  color: #6a80a7;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_info__11Vac {
  font-size: 1rem;
  line-height: 1.625rem;
  display: block;
  margin-top: 0.125rem;
  margin-bottom: 1.5rem;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_info__11Vac strong {
  font-weight: 800;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_infoRed__q-5Cx {
  font-size: 1rem;
  line-height: 1.625rem;
  display: block;
  margin-top: 0.125rem;
  margin-bottom: 1.5rem;
  color: #fb1427;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_infoRed__q-5Cx strong {
  font-weight: 800;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_bigText__1KLWB {
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 300;
  display: block;
  padding: 1.75rem 0 1rem;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 .style_bigText__1KLWB strong {
  font-weight: 800;
}
.style_options__1JFHq .style_option__2OHkf .style_bottom__1jiq7 button {
  width: 100%;
  margin: auto auto 0;
  max-width: 13.625rem;
  flex-shrink: 0;
}

.style_popUpRoot__1Hdpa .style_popUpPaper__2WEdz {
  padding: 3.5rem 2.5rem 2.375rem !important;
  overflow-y: visible;
  max-width: 50rem;
  width: 44rem !important;
}
@media (max-height: 40rem) {
  .style_popUpRoot__1Hdpa .style_popUpPaper__2WEdz {
    overflow-y: auto;
  }
}
@charset "UTF-8";
.style_mainWrapper__C6ekX {
  display: flex;
  justify-content: center;
}

.style_leftContainer__aSJuU {
  width: 456px;
  background: linear-gradient(171.13deg, #0671EE 0%, #5a61d9 87.44%, #565dd4 100%);
  padding: 40px 56px;
}
.style_leftContainer__aSJuU h1 {
  color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  width: 100%;
  margin: 8px 0;
}
.style_leftContainer__aSJuU h2 {
  color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 20px;
  line-height: 30px;
}
.style_leftContainer__aSJuU ul {
  list-style: none; /* Remove default bullets */
}
.style_leftContainer__aSJuU ul li::before {
  content: "•"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ffbc00; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.style_leftContainer__aSJuU li {
  color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height: 28px;
}
.style_leftContainer__aSJuU .style_avatar__3BY6H {
  background: #ffbc00;
  width: 48px;
  height: 48px;
  float: none;
  margin: 0 auto 12px;
}
.style_leftContainer__aSJuU button {
  margin: auto;
}
.style_leftContainer__aSJuU hr {
  opacity: 0.25;
  background-color: #e2e9f1;
  margin: 32px 0;
}

.style_rightContainer__-7j7C {
  width: 456px;
  background: #fff;
  padding-top: 28px;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_modal__3jbzx {
  z-index: 51;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: none;
  background-color: white;
}
.style_modal__3jbzx.style_open__Bmeh- {
  display: block;
}
.style_modal__3jbzx.style_spaceAround__rcN5z {
  background-color: rgba(51, 51, 51, 0.9);
}
.style_modal__3jbzx.style_spaceAround__rcN5z .style_modalWrapper__O0_bp {
  border-radius: 1.5rem;
  background-color: #fff;
  margin-top: 2rem;
  margin-left: 2rem;
  max-height: calc(100vh - 4rem);
  width: calc(100% - 4rem);
}
.style_modal__3jbzx.style_spaceAround__rcN5z .style_closeWrapper__1HCl9 {
  right: 4.25rem;
  top: 4.25rem;
}
.style_modal__3jbzx.style_spaceAround__rcN5z .style_modalContent__2uET- {
  min-height: calc(100vh - 4rem);
}

.style_closeWrapper__1HCl9 {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 1;
  text-align: center;
}
@media (min-width: 64rem) {
  .style_closeWrapper__1HCl9 {
    right: 2.625rem;
    top: 2.5rem;
  }
}

.style_sidebar__1uHZg {
  background: linear-gradient(37.39deg, #6024ff 0%, #5100d3 100%);
  position: fixed;
  display: none;
  width: 30%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 0.8s ease-in-out;
}
@media (min-width: 64rem) {
  .style_sidebar__1uHZg {
    width: 20rem;
  }
}
@media (min-width: 86rem) {
  .style_sidebar__1uHZg {
    width: 24rem;
  }
}
@media (min-width: 48rem) {
  .style_sidebar__1uHZg.style_show__69m7e {
    display: block;
  }
}
.style_sidebar__1uHZg.style_sideBarEnter__1pW9G.style_sideBarEnterActive__39o5r, .style_sidebar__1uHZg.style_sideBarEnterDone__2gm2P {
  transform: translateX(0);
}
@media (min-width: 48rem) {
  .style_sidebar__1uHZg.style_show__69m7e + .style_modalWrapper__O0_bp {
    padding-left: 30%;
  }
}
@media (min-width: 64rem) {
  .style_sidebar__1uHZg.style_show__69m7e + .style_modalWrapper__O0_bp {
    padding-left: 20rem;
  }
}
@media (min-width: 86rem) {
  .style_sidebar__1uHZg.style_show__69m7e + .style_modalWrapper__O0_bp {
    padding-left: 24rem;
  }
}
.style_sidebar__1uHZg > img {
  position: absolute;
  top: 2.5rem;
  left: 1.875rem;
}
@media (min-width: 64rem) {
  .style_sidebar__1uHZg > img {
    left: 2.5rem;
  }
}
@media (max-height: 52.5rem) {
  .style_sidebar__1uHZg > img {
    top: 1.5rem;
  }
}
@media (max-height: 46.875rem) {
  .style_sidebar__1uHZg > img {
    display: none;
  }
}

.style_modalWrapper__O0_bp {
  overflow-y: auto;
  max-height: 100vh;
  width: 100%;
}

.style_modalContent__2uET- {
  margin: 0 auto;
  padding: 4rem 0 4.625rem;
  min-height: 100vh;
  max-width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.style_modalContent__2uET- input {
  height: 3rem;
  font-size: 1rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_upgradeHeading__1AKQX {
  margin-bottom: 0;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_mainWrapper__2hCXD {
  margin: 0 auto;
  width: 100%;
}
.style_mainWrapper__2hCXD h3 {
  font-size: 1.25rem;
  font-weight: 800;
}
.style_mainWrapper__2hCXD .style_leftWrapper__1APIC {
  margin-top: 3.625rem;
  padding-right: 2rem;
}
@media (min-width: 64rem) {
  .style_mainWrapper__2hCXD .style_leftWrapper__1APIC {
    padding-right: 5.625rem;
  }
}
.style_mainWrapper__2hCXD .style_leftWrapper__1APIC h3 {
  margin-bottom: 1.25rem;
}
.style_mainWrapper__2hCXD .style_leftWrapper__1APIC .style_stripeWrapper__1MSlb {
  text-align: center;
  margin: auto;
  margin-top: 2.125rem;
}
.style_mainWrapper__2hCXD .style_leftWrapper__1APIC .style_moreAccountsOption__2xS_m {
  margin: -1.5rem 0 1.5rem;
  background: #f5f6f9;
  padding: 0.75rem 1rem;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.style_mainWrapper__2hCXD .style_leftWrapper__1APIC .style_moreAccountsOption__2xS_m span {
  font-weight: 700;
}
.style_mainWrapper__2hCXD .style_leftWrapper__1APIC .style_moreAccountsOption__2xS_m:hover {
  color: #333333;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_rightContent__2j9fk {
  padding-top: 3rem;
}
@media (min-width: 64rem) {
  .style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_rightContent__2j9fk {
    max-width: 23.75rem;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_summary__2Zfpt {
  width: 100%;
  background-color: #f8f9fb;
  border-radius: 1rem;
  padding: 1.875rem 2.5rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_coupon__mBbbK {
  display: flex;
  margin-bottom: 0.75rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_coupon__mBbbK button {
  margin-left: 0.5rem;
  font-size: 0.75rem;
  min-width: 4.125rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_coupon__mBbbK .style_couponField__mqi2n {
  flex-grow: 2;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_coupon__mBbbK .style_couponField__mqi2n input {
  height: 2.25rem;
  padding: 0 0.75rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_error__2nIsu {
  color: #fb1427;
  font-size: 0.75rem;
  margin: -0.75rem 0 0.875rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl h3 {
  margin-bottom: 1.125rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9 {
  border-top: 1px solid #e2e9f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO .style_name__3wtGr,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9 .style_name__3wtGr {
  font-size: 0.85rem;
  line-height: 1.625rem;
  font-weight: 800;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO .style_name__3wtGr small,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9 .style_name__3wtGr small {
  font-size: 0.75rem;
  font-weight: 600;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO .style_price__3tiIo,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9 .style_price__3tiIo {
  text-align: right;
  font-size: 1.125rem;
  font-weight: 800;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO .style_price__3tiIo small,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9 .style_price__3tiIo small {
  font-size: 0.875rem;
  font-weight: 400;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO.style_withCoupon__3CEfk,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9.style_withCoupon__3CEfk {
  padding: 0.75rem 0 0.25rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO.style_withCoupon__3CEfk .style_name__3wtGr,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9.style_withCoupon__3CEfk .style_name__3wtGr {
  font-size: 0.875rem;
  font-weight: 400;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_plan__2mxnO.style_withCoupon__3CEfk .style_price__3tiIo,
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_total__2Him9.style_withCoupon__3CEfk .style_price__3tiIo {
  text-align: right;
  font-size: 1rem;
  font-weight: 700;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_couponLine__1TV5Z {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0.75rem 0;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_couponLine__1TV5Z .style_name__3wtGr {
  font-size: 0.875rem;
  line-height: 1.625rem;
  font-weight: 400;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_couponLine__1TV5Z .style_name__3wtGr > span {
  font-size: 0.75rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_couponLine__1TV5Z .style_name__3wtGr > span svg {
  transform: translateY(0.1875rem);
  margin-left: 0.25rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_couponLine__1TV5Z .style_price__3tiIo {
  text-align: right;
  font-size: 1rem;
  font-weight: 700;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_couponLine__1TV5Z .style_price__3tiIo small {
  font-size: 0.875rem;
  font-weight: 400;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_planDetails__1bT4W {
  border-top: 1px solid #e2e9f1;
  width: 100%;
  padding: 1rem 0;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_planDetails__1bT4W p {
  color: #1b264f;
  font-family: "Nunito Sans";
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.5rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_planDetails__1bT4W .style_savings__1ywVU {
  color: #00b178;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_planDetails__1bT4W .style_accounts__2iwU3 {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_planDetails__1bT4W .style_accounts__2iwU3 strong {
  font-weight: 800;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_planDetails__1bT4W .style_subtext__hHkKu {
  color: #6a80a7;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl h4 {
  font-weight: 800;
  margin-top: 1.875rem;
  margin-bottom: 0.5rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl ul.style_features__3loeI {
  list-style-type: none;
  padding-left: 0;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl ul.style_features__3loeI li {
  color: #1b264f;
  font-size: 0.9375rem;
  line-height: 1.875rem;
  display: flex;
  align-items: center;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl ul.style_features__3loeI li.style_vibrant__2DlMN {
  color: #6634ff;
  font-weight: 800;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl ul.style_features__3loeI li svg {
  width: 0.75rem;
  fill: #6634ff;
  margin-right: 1rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_bookMeeting__35l_c {
  margin-top: 1.25rem;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_bookMeeting__35l_c p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_bookMeeting__35l_c p > span {
  color: #333333;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.style_mainWrapper__2hCXD .style_rightWrapper__3AIvl .style_bookMeeting__35l_c p > span:hover {
  color: #0671ee;
}

.style_editBillingForm__3iXju {
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 64rem) {
  .style_editBillingForm__3iXju {
    display: grid;
    max-width: 59.375rem;
    grid-template-columns: auto 23.75rem;
  }
}
.style_editBillingForm__3iXju .style_options__22PMI {
  width: 100%;
  margin-bottom: 2.375rem;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionDropDown__14eqj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionDropDown__14eqj label {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionDropDown__14eqj label small {
  padding-left: 8px;
  font-weight: 700;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j {
  border-radius: 0.625rem;
  border: 1px solid #e2e9f1;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j .style_radioCircle__1LBsp {
  width: 1.875rem;
  height: 1.875rem;
  flex-shrink: 0;
  border-radius: 1rem;
  border: 0.25rem solid #333333;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j .style_radioCircle__1LBsp svg {
  width: 0.875rem;
  height: 0.875rem;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j .style_radioCircle__1LBsp svg polyline {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j label {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j .style_optionAccountNum__1pWyC {
  font-size: 0.875rem;
  line-height: 1.5rem;
  transform: translateY(0.0625rem);
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j .style_optionAccountNum__1pWyC strong {
  font-weight: 800;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_disabled__18Z_J .style_radioCircle__1LBsp {
  border-color: #bccfdc;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_disabled__18Z_J label,
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_disabled__18Z_J span,
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_disabled__18Z_J .style_optionPrice__HjgqQ,
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_disabled__18Z_J .style_priceSubText__1UvsI {
  color: #bccfdc;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_disabled__18Z_J .style_optionPrice__HjgqQ strike::after {
  background-color: #bccfdc;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_selected__3UQ7j .style_radioCircle__1LBsp {
  border-color: #00b178;
  background-color: #00b178;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j.style_selected__3UQ7j .style_radioCircle__1LBsp svg polyline {
  stroke: #fff;
  animation: style_draw__2JoFb 5s linear 0.3s forwards;
}
.style_editBillingForm__3iXju .style_options__22PMI .style_optionCheckbox__13b_j:hover:not(.style_selected__3UQ7j):not(.style_disabled__18Z_J) {
  border-color: #333333;
}
.style_editBillingForm__3iXju .style_optionsCycle__3UzZy {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}
.style_editBillingForm__3iXju .style_optionsCycle__3UzZy label small {
  color: #00b178;
  padding-left: 0.625rem;
  font-weight: 600;
}
.style_editBillingForm__3iXju .style_optionsPlans__2Zeyg .style_optionCheckbox__13b_j {
  margin-top: 0.5rem;
  padding: 0.4375rem 1rem;
  min-height: 3.375rem;
}
.style_editBillingForm__3iXju .style_optionsPlans__2Zeyg .style_optionCheckbox__13b_j:first-child {
  margin-top: 0;
}
.style_editBillingForm__3iXju .style_optionsPlans__2Zeyg .style_optionCheckbox__13b_j .style_radioCircle__1LBsp {
  width: 1.75rem;
  height: 1.75rem;
}
.style_editBillingForm__3iXju .style_optionsPlans__2Zeyg .style_optionCheckbox__13b_j label::after {
  content: "";
  background-color: #bccfdc;
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 50%;
  display: block;
  margin: 0 0.75rem;
}
.style_editBillingForm__3iXju .style_billingDetails__NQyzw label {
  color: #1b264f;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
  display: block;
  font-family: "Nunito Sans", sans-serif, sans-serif;
}
.style_editBillingForm__3iXju .style_billingDetails__NQyzw .style_doubleInputBox__1I100 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem;
}
.style_editBillingForm__3iXju .style_billingDetails__NQyzw .style_renderInputField__1_YFi {
  margin-bottom: 0.75rem;
}
.style_editBillingForm__3iXju .style_billingDetails__NQyzw .style_renderInputField__1_YFi.style_inputBox__1UxWm {
  margin-bottom: 1rem;
}
.style_editBillingForm__3iXju .style_billingDetails__NQyzw .style_StripeElement__3U3sx {
  border: 1px solid #bccfdc;
  border-radius: 0.375rem;
  transition: border-color 0.3s ease;
  width: 100%;
  height: 40px;
  padding: 0.6875rem 0.75rem 0.8125rem 0.75rem;
  color: #1b264f;
  background-color: #ffffff;
  margin-bottom: 0.75rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem !important;
}
.style_editBillingForm__3iXju button.style_submit__15WrA {
  width: 100%;
  height: 3.5rem;
  margin-top: 0.5rem;
}

.style_disclaimer__37d0m {
  margin-top: 1rem;
  text-align: center;
}

@keyframes style_draw__2JoFb {
  to {
    stroke-dashoffset: 0;
  }
}
@media (min-width: 64rem) {
  .style_mainWrapper__2hCXD.style_isOnboarding__3UJTI .style_editBillingForm__3iXju {
    max-width: 52rem;
    grid-template-columns: auto 21rem;
  }
}
@media (min-width: 64rem) {
  .style_mainWrapper__2hCXD.style_isOnboarding__3UJTI .style_editBillingForm__3iXju .style_leftWrapper__1APIC {
    padding-right: 2rem;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_compareDropDown__Px_aC {
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_selectInput__1uldm {
  height: 2.5rem;
  width: 15rem;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.style_selectInput__1uldm > div:first-child {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e2e9f1;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
.style_selectInput__1uldm > div:first-child .style_value__BH09N {
  transform: translateY(0.0625rem);
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: inline-block;
  font-weight: 800;
  flex-grow: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease-in-out;
  font-family: "Nunito Sans", sans-serif;
  color: #333333;
}
.style_selectInput__1uldm > div:first-child .style_value__BH09N + svg {
  margin-left: 0.625rem;
}
.style_selectInput__1uldm > div:first-child > svg {
  fill: #333333;
  min-width: 0.625rem;
  transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;
}
@media only all and (max-width: 48rem) {
  .style_selectInput__1uldm > div:first-child {
    padding: 0.25rem 0.75rem;
  }
  .style_selectInput__1uldm > div:first-child .style_value__BH09N + svg {
    margin-left: 0.438rem;
  }
}
.style_selectInput__1uldm .style_icon__33U-h {
  display: flex;
  align-content: center;
  max-height: 1rem;
  justify-content: center;
  fill: #333333;
}
.style_selectInput__1uldm .style_icon__33U-h svg,
.style_selectInput__1uldm .style_icon__33U-h path {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  fill: #333333;
  transition: fill 0.3s ease-in-out;
}
.style_selectInput__1uldm .style_icon__33U-h + .style_value__BH09N {
  margin-left: 0.625rem;
}
.style_selectInput__1uldm div:first-child:hover, .style_selectInput__1uldm.style_open__m_4RM > div:first-child {
  border-color: #333333;
}
.style_selectInput__1uldm div:first-child:hover .style_value__BH09N, .style_selectInput__1uldm.style_open__m_4RM > div:first-child .style_value__BH09N {
  color: #333333;
}
.style_selectInput__1uldm.style_open__m_4RM > div:first-child {
  background-color: #fff;
}
.style_selectInput__1uldm.style_open__m_4RM > div:first-child > svg {
  transform: rotate(180deg);
}
.style_selectInput__1uldm.style_calendarIcon__YqVcv .style_icon__33U-h svg {
  width: 1.0625rem;
  min-width: 1.0625rem;
  height: 1.0625rem;
  transform: translateY(-0.0625rem);
}
.style_selectInput__1uldm.style_clearActive__5Eovy > div:first-child .style_close__3raR6 {
  transition: opacity 0.3s ease, background-color 0.3s ease;
  background-color: #333333;
  height: 0.875rem;
  width: 0.875rem;
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.625rem;
}
.style_selectInput__1uldm.style_clearActive__5Eovy > div:first-child .style_close__3raR6:hover {
  background-color: #fb1427;
}
.style_selectInput__1uldm.style_clearActive__5Eovy > div:first-child .style_close__3raR6 svg path {
  fill: #fff;
  width: 0.375rem;
  height: 0.375rem;
}
.style_selectInput__1uldm.style_disabled__aZIdS {
  pointer-events: none;
}
.style_selectInput__1uldm.style_disabled__aZIdS > div:first-child {
  background-color: #f8f9fb;
}
.style_selectInput__1uldm.style_disabled__aZIdS > div:first-child .style_value__BH09N {
  color: #bccfdc;
}
.style_selectInput__1uldm.style_disabled__aZIdS > div:first-child > svg {
  fill: #bccfdc;
}

.style_blackIcon__d_cld div:first-child:hover svg,
.style_blackIcon__d_cld div:first-child:hover path,
.style_blackIcon__d_cld div:first-child:hover ellipse,
.style_blackIcon__d_cld div:first-child:hover rect, .style_blackIcon__d_cld.style_open__m_4RM > div:first-child svg,
.style_blackIcon__d_cld.style_open__m_4RM > div:first-child path,
.style_blackIcon__d_cld.style_open__m_4RM > div:first-child ellipse,
.style_blackIcon__d_cld.style_open__m_4RM > div:first-child rect {
  fill: #333333;
}
.style_blackIcon__d_cld:hover div span svg,
.style_blackIcon__d_cld:hover div span path,
.style_blackIcon__d_cld:hover div span ellipse,
.style_blackIcon__d_cld:hover div span rect {
  fill: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_downgradeHeading__31yyL {
  max-width: 42.5rem;
  margin: 0 auto 3rem;
}
.style_downgradeHeading__31yyL p {
  line-height: 2rem;
}

.style_content__9zyCE {
  height: 5.875rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.style_content__9zyCE button {
  margin: 0 0.5rem;
  min-width: 13.625rem;
}
.style_content__9zyCE .style_customPricing__1O1QP {
  width: 100%;
  text-align: center;
  margin: 1.75rem 0 0;
  color: #6a80a7;
  font-size: 0.875rem;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.style_content__9zyCE .style_customPricing__1O1QP:hover {
  color: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_downgradeHeading__1BQ9Z {
  max-width: 42.5rem;
  margin: 0 auto 3rem;
}
.style_downgradeHeading__1BQ9Z p {
  line-height: 2rem;
}

.style_content__14pCW {
  height: 5.875rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.style_content__14pCW button {
  margin: 0 0.5rem;
  min-width: 13.625rem;
}
.style_content__14pCW .style_customPricing__12Ehm {
  width: 100%;
  text-align: center;
  margin: 1.75rem 0 0;
  color: #6a80a7;
  font-size: 0.875rem;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.style_content__14pCW .style_customPricing__12Ehm:hover {
  color: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_header__2vQ6G {
  color: #00b178;
}

.style_content__2YUAB {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.style_content__2YUAB button {
  margin: 0 0.5rem;
  min-width: 13.625rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_pricingTable__3dzAg {
  margin-top: 3.75rem;
  padding-bottom: 7.5rem;
}
.style_pricingTable__3dzAg h2 {
  text-align: center;
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 800;
}
.style_pricingTable__3dzAg h3,
.style_pricingTable__3dzAg h4 {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.625rem;
  margin-bottom: 0.75rem;
}
.style_pricingTable__3dzAg .style_pricingGroup__2uOW6 {
  max-width: 67.5rem;
  margin: 0 auto 1.5rem;
}
.style_pricingTable__3dzAg .style_tableHeader__1Vd55,
.style_pricingTable__3dzAg .style_tableRow__bvdkt {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.style_pricingTable__3dzAg .style_tableHeader__1Vd55 {
  padding: 0 1.5rem;
}
@media (min-width: 64rem) {
  .style_pricingTable__3dzAg .style_tableHeader__1Vd55 {
    padding: 0 1.875rem;
  }
}
.style_pricingTable__3dzAg .style_tableRows__24hLN .style_tableRow__bvdkt {
  background-color: #fafbfd;
  border-bottom: 1px solid #eaf0f7;
  padding: 0.875rem 1.5rem;
  border-left: 1px solid #eaf0f7;
  border-right: 1px solid #eaf0f7;
}
@media (min-width: 64rem) {
  .style_pricingTable__3dzAg .style_tableRows__24hLN .style_tableRow__bvdkt {
    padding: 0.875rem 1.875rem;
  }
}
.style_pricingTable__3dzAg .style_tableRows__24hLN .style_tableRow__bvdkt:first-child {
  border-top: 1px solid #eaf0f7;
  border-radius: 0.625rem 0.625rem 0 0;
}
.style_pricingTable__3dzAg .style_tableRows__24hLN .style_tableRow__bvdkt:last-child {
  border-radius: 0 0 0.625rem 0.625rem;
}
.style_pricingTable__3dzAg .style_tableRows__24hLN .style_tableRow__bvdkt:first-child:last-child {
  border-radius: 0.625rem;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C {
  min-width: 10rem;
  text-align: center;
}
@media (min-width: 64rem) {
  .style_pricingTable__3dzAg .style_tableColumn__1zX-C {
    min-width: 14rem;
  }
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C.style_firstColumn__fFB9H {
  margin-right: auto;
  margin-top: 0;
  min-width: 18rem;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
}
@media (min-width: 64rem) {
  .style_pricingTable__3dzAg .style_tableColumn__1zX-C.style_firstColumn__fFB9H {
    min-width: 20rem;
  }
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C.style_firstColumn__fFB9H p {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0;
}
@media (min-width: 64rem) {
  .style_pricingTable__3dzAg .style_tableColumn__1zX-C.style_firstColumn__fFB9H p {
    font-size: 1rem;
    line-height: 1.875rem;
  }
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C.style_firstColumn__fFB9H .style_columnText__1K6M- {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.25rem;
  margin-top: 0.75rem;
}
@media (min-width: 64rem) {
  .style_pricingTable__3dzAg .style_tableColumn__1zX-C.style_firstColumn__fFB9H .style_columnText__1K6M- {
    font-size: 1rem;
  }
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C .style_columnText__1K6M- {
  font-size: 1rem;
  line-height: 1.875rem;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C .style_columnText__1K6M- strong {
  font-weight: 800;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C .style_info__1wubD {
  margin-left: auto;
  margin-right: 2.5rem;
  align-self: flex-start;
  margin-top: 0.5rem;
  position: relative;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C .style_info__1wubD svg {
  transition: fill 0.3s ease-in-out;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C .style_info__1wubD:hover svg {
  fill: #333333;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C svg {
  display: block;
  margin: 0 auto;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C svg.style_checkmarkIcon__b_8Zl {
  width: 1rem;
  height: 0.75rem;
  margin-top: 0.625rem;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C svg.style_xIcon__3tEu3 {
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 0.625rem;
  fill: #fb1427;
}
.style_pricingTable__3dzAg .style_tableColumn__1zX-C svg + .style_columnText__1K6M- {
  font-size: 0.875rem;
  line-height: 2.25rem;
  margin-top: 1.25rem;
}

.style_footer__1aJ9y {
  max-width: 55rem;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #6a80a7;
  margin-top: 3.75rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_popUp__2QT_p {
  text-align: center;
  max-width: 34.25rem;
  margin: 0 auto 1.25rem;
}
.style_popUp__2QT_p .style_top__1VmOb {
  border-bottom: 1px solid #e2e9f1;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.style_popUp__2QT_p .style_top__1VmOb h2 {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #1b264f;
  margin-bottom: 1rem;
}
.style_popUp__2QT_p .style_top__1VmOb p {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #6a80a7;
  margin-bottom: 1rem;
}
.style_popUp__2QT_p .style_top__1VmOb > button {
  margin: 0 auto;
}
.style_popUp__2QT_p .style_bottom__1kv8Y .style_buttons__3a45y {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_popUp__2QT_p .style_bottom__1kv8Y .style_buttons__3a45y button {
  min-width: 8rem;
}
.style_popUp__2QT_p .style_bottom__1kv8Y .style_buttons__3a45y button:first-of-type {
  margin-right: 0.5rem;
}
.style_errorFragment__2lDWw {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 660px;
    margin: 20% auto;
}

/* DayPicker styles */
.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  -webkit-user-select: none;
          user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
          user-select: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */
.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */
.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4a90e2;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

/* DayPickerInput */
.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
body {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  color: #1b264f;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
label {
  margin-top: 0;
}

h1,
h2 {
  font-weight: 100;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 2rem;
}

h3 {
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 1rem;
}

p,
li,
a,
label {
  font-size: 0.75rem;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.material-icons.md-light {
  color: rgb(255, 255, 255);
}

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
body {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif !important;
}

body > div:first-of-type {
  height: 100%;
}

html {
  height: 100%;
}

hr {
  border-top: 1.2px #00bcd4;
  width: 100%;
}

.Select-control {
  border: none;
}

@media print {
  body {
    height: auto;
    overflow-y: initial;
    overflow-x: initial;
  }
}
@page {
  size: A4;
  margin: 16px 30px 30px 30px;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #333333;
}

.Select-control {
  border: none;
  border-bottom: 1px solid #c5d7e2;
  border-radius: 0;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.Select-control:hover {
  border-color: #333333;
}
.Select-control:hover .Select-arrow {
  background-image: url(/static/media/Icon-Arrow-Down-Purple.d7ddf863.svg);
}

.Select--multi .Select-input {
  margin-left: 0;
}

.Select-placeholder {
  padding-left: 0;
  font-size: 0.75rem;
  color: #c2c7de;
}

.Select--single > .Select-control .Select-value {
  padding-left: 0;
  line-height: 40px;
}

.Select-input {
  padding-left: 0;
}
.Select-input > input {
  max-height: 100%;
  padding: 0;
}

.Select-value-label {
  color: #1b264f !important;
}

.rt-expander {
  margin: 0px 11px !important;
}

.Select-arrow {
  height: 0.5rem;
  width: 0.625rem;
  border: 0;
  background-image: url(/static/media/Icon-Arrow-Down-Dark.7bb4f3a3.svg);
  display: inline-block;
  background-repeat: no-repeat;
}

.Select.is-open .Select-arrow {
  background-image: url(/static/media/Icon-Arrow-Down-Purple.d7ddf863.svg);
}

.Select-arrow-zone {
  padding-right: 1rem;
}

.Select.is-open > .Select-control .Select-arrow {
  transform: rotate(180deg);
}

.Select-clear-zone {
  color: #1b264f;
  margin-right: -0.25rem;
}
.Select-clear-zone:hover {
  color: #fb1427;
}

.Select-clear {
  font-size: 22px;
  font-weight: 600;
}

.Select-menu-outer {
  border-radius: 0.375rem;
  box-shadow: 0 0.325rem 0.625rem 0 rgba(51, 57, 68, 0.1);
  padding: 0.5rem;
}
.Select-menu-outer > div {
  max-height: 7.5rem;
}
.Select-menu-outer > div > div {
  border-radius: 0.5rem;
  background-color: transparent !important;
  color: #1b264f;
  padding: 0.875rem 0.75rem 0.75rem;
  font-size: 0.875rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.Select-menu-outer > div > div:hover:not(.is-disabled) {
  color: #333333;
  background-color: #f5f6f9 !important;
}
.Select-menu-outer > div > div.is-selected {
  font-weight: 800;
}
.Select-menu-outer > div > div:last-of-type {
  border-radius: 0.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.daySelectContainer {
  position: relative;
}
.daySelectContainer .DayPicker-Day {
  font-size: 0.75rem;
  width: 2.125rem;
  height: 2.125rem;
  font-weight: 500;
  cursor: pointer;
}
.daySelectContainer .DayPicker-Day:active, .daySelectContainer .DayPicker-Day:focus {
  outline: none;
}
.daySelectContainer .DayPicker-Weekday {
  font-size: 0.75rem;
  font-weight: 600;
  color: #6a80a7;
  padding: 0 0.5rem;
}
.daySelectContainer .DayPicker-Month {
  border-collapse: initial;
  border-spacing: 0 0.25rem;
}
.daySelectContainer .DayPicker-Month:active, .daySelectContainer .DayPicker-Month:focus {
  outline: none;
}
.daySelectContainer .DayPicker-wrapper {
  outline: none;
}
.daySelectContainer .DayPicker-Day {
  color: #1b264f;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
}
.daySelectContainer .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--today):not(.DayPicker-Day--outside):not(.DayPicker-Day--highlighted):hover {
  color: #ffffff;
  font-weight: 800;
  background-color: #333333 !important;
}
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside):hover {
  color: #ffffff !important;
  background-color: #333333 !important;
  font-weight: 800;
  border-radius: 5rem;
}
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside) {
  border-radius: 5rem;
}
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #333333;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}
.daySelectContainer .DayPicker-Day--outside {
  background-color: transparent !important;
}
.daySelectContainer .DayPicker-Day--highlighted {
  color: #ffffff !important;
  background-color: #333333;
  font-weight: 800;
  cursor: default;
}
.daySelectContainer .DayPicker-Day--disabled {
  color: #e2e9f1;
  cursor: default;
}
.daySelectContainer .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--outside):after {
  content: "";
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background: #333333;
}
.daySelectContainer .DayPicker-Day--daysWithAnomalies:hover:after {
  background: #ffffff;
}
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to,
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo,
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 100%;
  background: #333333;
  color: #ffffff !important;
}
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
  display: none;
}
.daySelectContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:after,
.daySelectContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:after,
.daySelectContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:after {
  background: #ffffff;
}
.daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: transparent;
  border-radius: initial;
  color: #fff;
}
.daySelectContainer .daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #333333;
  color: #ffffff !important;
  border-radius: 100%;
}
.daySelectContainer .DayPicker-NavButton--prev {
  background-image: url(/static/media/Icon-Arrow-Right.6489a28e.svg);
  transform: scaleX(-1);
}
.daySelectContainer .DayPicker-NavButton--next {
  background-image: url(/static/media/Icon-Arrow-Right.6489a28e.svg);
}
.daySelectContainer .DayPicker-NavButton {
  width: 0.875rem;
  outline: none;
  margin-top: -0.0625rem;
}
.daySelectContainer .DayPicker-NavButton--prev {
  left: 1rem;
}
.daySelectContainer .DayPicker-NavButton--next {
  right: 1rem;
}
.daySelectContainer .DayPicker-Caption {
  text-align: center;
  margin-bottom: 0.625rem;
}
.daySelectContainer .DayPicker-Caption > div {
  font-size: 0.875rem;
  font-weight: 800;
  color: #1b264f;
}
.daySelectContainer svg {
  fill: #ffffff;
}
.daySelectContainer .DayPicker-Day {
  min-width: 2.125rem;
}
.daySelectContainer .daySelect {
  box-shadow: 0 4px 8px 0 rgba(51, 57, 68, 0.05);
  border-radius: 8px;
  z-index: 100;
  position: absolute;
  background-color: #ffffff;
  right: 0;
  top: 2.75rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  border: 1px #e2e9f1 solid;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0s ease 0.3s;
}
.daySelectContainer .daySelectOpen {
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.3s ease 0.1s, transform 0s ease;
}
.daySelectContainer .DayPicker-Months {
  flex-wrap: initial;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.month-picker {
  font-family: "Nunito Sans", sans-serif;
}
.month-picker .rmp-pad {
  width: 16rem !important;
}
.month-picker .rmp-popup.range {
  width: 34.5rem !important;
}
.month-picker .rmp-popup {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.05) !important;
  border-radius: 0.625rem !important;
  border: 1px solid #eaf0f7 !important;
  transform: none !important;
  animation-name: fadeInOpacity !important;
  animation-iteration-count: 1 !important;
  animation-timing-function: ease-in !important;
  animation-duration: 2s !important;
}
.month-picker .rmp-popup > div {
  background-color: #fff !important;
}
.month-picker .rmp-btn {
  border-radius: 8px !important;
  transition: background-color 0.5s ease !important;
  line-height: 3rem !important;
}
.month-picker .rmp-btn:hover {
  background-color: #333333 !important;
  color: #fff !important;
}
.month-picker .rmp-btn.active {
  background-color: #333333 !important;
  color: #fff !important;
  border-radius: 8px 0px 0px 8px !important;
}
.month-picker .rmp-btn.select {
  background-color: #f3f7ff !important;
  border-radius: 0px !important;
}
.month-picker .rmp-btn.select:hover {
  color: inherit !important;
}
.month-picker .rmp-btn.disable {
  color: #6a80a7 !important;
  font-weight: 400 !important;
}
.month-picker .rmp-btn.disable:hover {
  background-color: transparent !important;
  color: inherit !important;
}
.month-picker .rmp-tab {
  line-height: 3rem !important;
}
.month-picker label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px !important;
  font-weight: 800 !important;
  color: #1b264f;
  line-height: 3rem;
}
.month-picker li {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #1b264f;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.dateRangeContainer {
  position: relative;
}
.dateRangeContainer .DayPicker-Day {
  font-size: 0.75rem;
  width: 2.125rem;
  height: 2.125rem;
  font-weight: 500;
  cursor: pointer;
}
.dateRangeContainer .DayPicker-Day:active, .dateRangeContainer .DayPicker-Day:focus {
  outline: none;
}
.dateRangeContainer .DayPicker-Weekday {
  font-size: 0.75rem;
  font-weight: 600;
  color: #6a80a7;
  padding: 0 0.375rem;
  min-width: 2.125rem;
}
.dateRangeContainer .DayPicker-Month {
  border-collapse: initial;
  border-spacing: 0 0.25rem;
}
.dateRangeContainer .DayPicker-Month:active, .dateRangeContainer .DayPicker-Month:focus {
  outline: none;
}
.dateRangeContainer .DayPicker-wrapper {
  outline: none;
}
.dateRangeContainer .DayPicker-Day {
  color: #1b264f;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
}
.dateRangeContainer .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--today):not(.DayPicker-Day--outside):not(.DayPicker-Day--highlighted):hover {
  color: #ffffff;
  font-weight: 800;
  background-color: #333333 !important;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
  color: #1b264f !important;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside):hover {
  color: #ffffff !important;
  background-color: #333333 !important;
  font-weight: 800;
  border-radius: 5rem;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside) {
  border-radius: 5rem;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:before(.DayPicker-Day--from):hover {
  border-radius: 50% 0 0 50%;
  background-color: #f5f6f9;
  content: "";
  display: block;
  position: absolute;
  height: 2.125rem;
  width: 2.157rem;
  top: 0;
  left: 0;
  z-index: -2;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before {
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f6f9;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}
.dateRangeContainer .DayPicker-Day--outside {
  background-color: transparent !important;
}
.dateRangeContainer .DayPicker-Day--highlighted {
  color: #ffffff !important;
  background-color: #333333;
  font-weight: 800;
  cursor: default;
}
.dateRangeContainer .DayPicker-Day--disabled {
  color: #e2e9f1;
  cursor: default;
}
.dateRangeContainer .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--outside):after {
  content: "";
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background: #333333;
}
.dateRangeContainer .DayPicker-Day--daysWithAnomalies:hover:after {
  background: #ffffff;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:before {
  border-radius: 50% 0 0 50%;
  background-color: #f5f6f9;
  content: "";
  display: block;
  position: absolute;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
  z-index: -2;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:before,
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
  border-radius: 0 50% 50% 0;
  background-color: #f5f6f9;
  content: "";
  display: block;
  position: absolute;
  z-index: -2;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to,
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo,
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 100%;
  background: #333333;
  color: #ffffff !important;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
  display: none;
}
.dateRangeContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:after,
.dateRangeContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:after,
.dateRangeContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:after {
  background: #ffffff;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #f5f6f9;
  border-radius: initial;
}
.dateRangeContainer .daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #333333;
  color: #ffffff !important;
  border-radius: 100%;
}
.dateRangeContainer .DayPicker-NavButton--prev {
  background-image: url(/static/media/Icon-Arrow-Right.6489a28e.svg);
  transform: scaleX(-1);
}
.dateRangeContainer .DayPicker-NavButton--next {
  background-image: url(/static/media/Icon-Arrow-Right.6489a28e.svg);
}
.dateRangeContainer .DayPicker-NavButton {
  width: 0.875rem;
  outline: none;
  margin-top: -0.0625rem;
}
.dateRangeContainer .DayPicker-NavButton--prev {
  left: 1rem;
}
.dateRangeContainer .DayPicker-NavButton--next {
  right: 1rem;
}
.dateRangeContainer .DayPicker-Caption {
  text-align: center;
  margin-bottom: 0.625rem;
}
.dateRangeContainer .DayPicker-Caption > div {
  font-size: 0.875rem;
  font-weight: 800;
  color: #1b264f;
}
.dateRangeContainer .DayPicker-Week > div:first-of-type:before {
  border-radius: 2rem 0 0 2rem;
}
.dateRangeContainer .DayPicker-Week > div:last-of-type:before {
  border-radius: 0 2rem 2rem 0;
}
.dateRangeContainer .daySelectContainer {
  position: relative;
}
.dateRangeContainer .daySelectContainer svg {
  fill: #ffffff;
}
.dateRangeContainer .daySelectContainer .DayPicker-Day {
  min-width: 2.125rem;
}
.dateRangeContainer .daySelectContainer .daySelect {
  box-shadow: 0 4px 8px 0 rgba(51, 57, 68, 0.05);
  border-radius: 8px;
  z-index: 100;
  position: absolute;
  background-color: #ffffff;
  right: 0;
  top: 3.1rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  border: 1px #e2e9f1 solid;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0s ease 0.3s;
}
.dateRangeContainer .daySelectContainer .daySelectOpen {
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.3s ease 0.1s, transform 0s ease;
}
.dateRangeContainer .daySelectContainer .DayPicker-Months {
  flex-wrap: initial;
}
.dateRangeContainer svg {
  fill: #fff;
}
.dateRangeContainer .DayPicker-Day {
  min-width: 2.125rem;
}
.dateRangeContainer .dateRange {
  position: relative;
  border-radius: 0.625rem 0 0 0.625rem;
  z-index: 100;
  background-color: #fff;
  padding: 0.5rem 0.5rem 0 0.5rem;
  transform: scale(1);
}
.dateRangeContainer .DayPicker-Months {
  flex-wrap: initial;
}
.dateRangeContainer .DayPicker-Day--to,
.dateRangeContainer .DayPicker-Day--from {
  font-weight: 800;
  color: #fff !important;
}
.dateRangeContainer .DayPicker-Day--compareTo,
.dateRangeContainer .DayPicker-Day--compareFrom {
  font-weight: 800;
  color: #fff !important;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:before {
  border-radius: 50% 0 0 50%;
  background-color: #ff9d00;
  content: "";
  display: block;
  position: absolute;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
  z-index: -2;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareTo:before,
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:before {
  border-radius: 0 50% 50% 0;
  background-color: #ff9d00;
  content: "";
  display: block;
  position: absolute;
  z-index: -2;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
}
.dateRangeContainer .DayPicker-Day--compareFrom,
.dateRangeContainer .DayPicker-Day--compareTo {
  background: #fb1427;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareTo,
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo,
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 100%;
  background: #fb1427;
  color: #ffffff !important;
}
.dateRangeContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:before {
  display: none;
}
.dateRangeContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareTo:after,
.dateRangeContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:after,
.dateRangeContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:after {
  background: #ffffff;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.dateRangeCompareContainer {
  position: relative;
}
.dateRangeCompareContainer .DayPicker-Day {
  font-size: 0.75rem;
  width: 2.125rem;
  height: 2.125rem;
  font-weight: 500;
  cursor: pointer;
  z-index: 1;
}
.dateRangeCompareContainer .DayPicker-Day:active, .dateRangeCompareContainer .DayPicker-Day:focus {
  outline: none;
}
.dateRangeCompareContainer .DayPicker-Weekday {
  font-size: 0.75rem;
  font-weight: 600;
  color: #6a80a7;
  padding: 0 0.375rem;
  min-width: 2.125rem;
}
.dateRangeCompareContainer .DayPicker-Month {
  border-collapse: initial;
  border-spacing: 0 0.25rem;
}
.dateRangeCompareContainer .DayPicker-Month:active, .dateRangeCompareContainer .DayPicker-Month:focus {
  outline: none;
}
.dateRangeCompareContainer .DayPicker-wrapper {
  outline: none;
}
.dateRangeCompareContainer .DayPicker-Day {
  color: #1b264f;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
}
.dateRangeCompareContainer .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--today):not(.DayPicker-Day--outside):not(.DayPicker-Day--highlighted):hover {
  color: #ffffff;
  font-weight: 800;
  background-color: #0671ee !important;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
  color: #fff !important;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside):hover {
  color: #ffffff !important;
  background-color: #0671ee !important;
  font-weight: 800;
  border-radius: 5rem;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside) {
  border-radius: 5rem;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:before(.DayPicker-Day--from):hover {
  border-radius: 50% 0 0 50%;
  background-color: #0671ee;
  content: "";
  display: block;
  position: absolute;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
  z-index: -2;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before {
  content: "";
  width: 100%;
  height: 100%;
  background: #0671ee;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: -1;
}
.dateRangeCompareContainer .DayPicker-Day--outside {
  background-color: transparent !important;
}
.dateRangeCompareContainer .DayPicker-Day--highlighted {
  color: #ffffff !important;
  background-color: #0671ee;
  font-weight: 800;
  cursor: default;
}
.dateRangeCompareContainer .DayPicker-Day--disabled {
  color: #e2e9f1;
  cursor: default;
}
.dateRangeCompareContainer .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--outside):after {
  content: "";
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background: #0671ee;
}
.dateRangeCompareContainer .DayPicker-Day--daysWithAnomalies:hover:after {
  background: #ffffff;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--compareEnteredTo {
  border-radius: 0;
  background: #6a80a7;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:before {
  border-radius: 50% 0 0 50%;
  background-color: #0671ee;
  content: "";
  display: block;
  position: absolute;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
  z-index: -2;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:before,
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
  border-radius: 0 50% 50% 0;
  background-color: #0671ee;
  content: "";
  display: block;
  position: absolute;
  z-index: -2;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to,
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo,
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 100%;
  background: #0671ee;
  color: #ffffff !important;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
  display: none;
}
.dateRangeCompareContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:after,
.dateRangeCompareContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:after,
.dateRangeCompareContainer .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:after {
  background: #ffffff;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #0671ee;
  border-radius: initial;
}
.dateRangeCompareContainer .daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #0671ee;
  color: #ffffff !important;
  border-radius: 100%;
}
.dateRangeCompareContainer .DayPicker-NavButton--prev {
  background-image: url(/static/media/Icon-Arrow-Right.6489a28e.svg);
  transform: scaleX(-1);
}
.dateRangeCompareContainer .DayPicker-NavButton--next {
  background-image: url(/static/media/Icon-Arrow-Right.6489a28e.svg);
}
.dateRangeCompareContainer .DayPicker-NavButton {
  width: 0.875rem;
  outline: none;
  margin-top: -0.0625rem;
}
.dateRangeCompareContainer .DayPicker-NavButton--prev {
  left: 1rem;
}
.dateRangeCompareContainer .DayPicker-NavButton--next {
  right: 1rem;
}
.dateRangeCompareContainer .DayPicker-Caption {
  text-align: center;
  margin-bottom: 0.625rem;
}
.dateRangeCompareContainer .DayPicker-Caption > div {
  font-size: 0.875rem;
  font-weight: 800;
  color: #1b264f;
}
.dateRangeCompareContainer .DayPicker-Week > div:first-of-type:before {
  border-radius: 2rem 0 0 2rem;
}
.dateRangeCompareContainer .DayPicker-Week > div:last-of-type:before {
  border-radius: 0 2rem 2rem 0;
}
.dateRangeCompareContainer .daySelectContainer {
  position: relative;
}
.dateRangeCompareContainer .daySelectContainer svg {
  fill: #ffffff;
}
.dateRangeCompareContainer .daySelectContainer .DayPicker-Day {
  min-width: 2.125rem;
}
.dateRangeCompareContainer .daySelectContainer .daySelect {
  box-shadow: 0 4px 8px 0 rgba(51, 57, 68, 0.05);
  border-radius: 8px;
  z-index: 100;
  position: absolute;
  background-color: #ffffff;
  right: 0;
  top: 3.1rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  border: 1px #e2e9f1 solid;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0s ease 0.3s;
}
.dateRangeCompareContainer .daySelectContainer .daySelectOpen {
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.3s ease 0.1s, transform 0s ease;
}
.dateRangeCompareContainer .daySelectContainer .DayPicker-Months {
  flex-wrap: initial;
}
.dateRangeCompareContainer svg {
  fill: #ffffff;
}
.dateRangeCompareContainer .DayPicker-Day {
  min-width: 2.125rem;
}
.dateRangeCompareContainer .dateRange {
  border-radius: 0.625rem 0 0 0.625rem;
  z-index: 100;
  background-color: #fff;
  padding: 0.5rem 0.5rem 0 0.5rem;
  transform: scale(1);
}
.dateRangeCompareContainer .DayPicker-Months {
  flex-wrap: initial;
}
.dateRangeCompareContainer .DayPicker-Day--compareTo,
.dateRangeCompareContainer .DayPicker-Day--compareFrom {
  font-weight: 800;
  color: #ffffff !important;
}
.dateRangeCompareContainer :not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:before {
  border-radius: 50% 0 0 50%;
  background-color: #333333;
  content: "";
  display: block;
  position: absolute;
  height: 2.125rem;
  width: 2.165rem;
  top: 0;
  left: 0;
  z-index: -2;
}
.dateRangeCompareContainer :not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--compareTo:before {
  border-radius: 50%;
  background-color: #333333;
  content: "";
  display: block;
  position: absolute;
  height: 2.125rem;
  width: 2.225rem;
  top: 0;
  left: 0;
  z-index: -2;
}
.dateRangeCompareContainer :not(.DayPicker-Day--outside).DayPicker-Day--compareTo.DayPicker-Day--compareEnteredTo:before {
  border-radius: 0 50% 50% 0;
  background-color: #333333;
  content: "";
  display: block;
  position: absolute;
  z-index: -2;
  height: 2.125rem;
  width: 2.125rem;
  top: 0;
  left: 0;
}
.dateRangeCompareContainer :not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:before {
  background-color: #333333;
  content: "";
  display: block;
  position: absolute;
  z-index: -2;
  height: 2.125rem;
  width: 2.17rem;
  top: 0;
  left: 0;
}
.dateRangeCompareContainer .DayPicker-Day--compareFrom,
.dateRangeCompareContainer .DayPicker-Day--compareTo {
  background: #333333;
}
.dateRangeCompareContainer .DayPicker-Day--compareEnteredTo {
  color: #fff;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo {
  border-radius: 0;
}
.dateRangeCompareContainer :not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:before {
  display: none;
}
.dateRangeCompareContainer .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareTo:after,
.dateRangeCompareContainer .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:after,
.dateRangeCompareContainer .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:after {
  background: #ffffff;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom {
  background-color: #6a80a7;
  border-radius: 0;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo {
  background-color: #6a80a7;
  border-radius: 0;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to.DayPicker-Day--compareEnteredTo {
  background-color: #6a80a7;
  border-radius: 0;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:hover {
  border-radius: 0;
  background-color: #6a80a7 !important;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--to.DayPicker-Day--enteredTo {
  border-radius: 0;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--to.DayPicker-Day--enteredTo:before {
  content: none;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--enteredTo:hover {
  background-color: #6a80a7 !important;
  border-radius: 0;
}
.dateRangeCompareContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--enteredTo:hover:before {
  content: none;
}
.dateRangeCompareContainer .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:hover,
.dateRangeCompareContainer .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:hover,
.dateRangeCompareContainer .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--compareTo:hover {
  font-weight: 800;
  background-color: initial;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
[class*=daySelectInput] {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0;
  min-width: 180px;
}
[class*=daySelectInput].headerHeight .Select-control {
  height: 2.75rem;
}
[class*=daySelectInput] .Select-control {
  width: 16.875rem;
  height: 2.5rem;
  background-color: transparent;
  border: 1px solid #e2e9f1 !important;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: border 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
[class*=daySelectInput] .Select-control .Select-placeholder {
  color: #1b264f !important;
  display: flex;
  align-items: center;
}
[class*=daySelectInput] .Select-control .Select-value {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #1b264f !important;
  width: 100%;
  padding: 0 1rem;
  cursor: pointer;
}
[class*=daySelectInput] .Select-control .Select-value::before {
  content: "";
  display: flex;
  align-items: center;
  width: 1.0625rem;
  height: 1.0625rem;
  transform: translateY(-0.0625rem);
  background-color: #242423;
  -webkit-mask-image: url(/static/media/calendar-icon-grey.aed042b8.svg);
  mask-image: url(/static/media/calendar-icon-grey.aed042b8.svg);
  margin: 0;
  transition: background-color 0.3s ease-in-out;
}
[class*=daySelectInput] .Select-control .Select-value .Select-value span {
  color: #1b264f !important;
}
[class*=daySelectInput] .Select-control .Select-value .Select-value-label {
  display: flex;
  align-items: center;
  line-height: 1rem;
  font-weight: 800;
  font-size: 0.875rem;
  color: #1b264f !important;
  margin: 0.125rem 0 0 0.625rem;
  transition: color 0.3s ease-in-out;
}
[class*=daySelectInput] .Select-control .Select-input {
  background-color: transparent;
  display: block !important;
}
[class*=daySelectInput] .Select-control .Select-input input {
  visibility: hidden;
}
[class*=daySelectInput] .Select-control .Select-arrow-zone {
  text-align: right;
  padding: 0;
  margin-left: 0.625rem;
  display: block;
}
[class*=daySelectInput] .Select-control .Select-arrow-zone .Select-arrow {
  border: none;
  height: 0.5rem;
  width: 0.625rem;
  margin: -2px 18px 0 0;
  background-color: #333333;
  -webkit-mask-image: url(/static/media/arrow-down-icon-white.05b0df8c.svg);
  mask-image: url(/static/media/arrow-down-icon-white.05b0df8c.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 0.3s ease-in-out;
}
[class*=daySelectInput] .Select-control:hover, [class*=daySelectInput].is-open .Select-control {
  border-color: #333333 !important;
  border-radius: 0.5rem;
}
[class*=daySelectInput] .Select-control:hover .Select-value::before, [class*=daySelectInput].is-open .Select-control .Select-value::before {
  background-color: #333333;
}
[class*=daySelectInput] .Select-control:hover .Select-value .Select-value-label, [class*=daySelectInput].is-open .Select-control .Select-value .Select-value-label {
  color: #333333 !important;
}
[class*=daySelectInput] .Select-control:hover .Select-arrow-zone .Select-arrow, [class*=daySelectInput].is-open .Select-control .Select-arrow-zone .Select-arrow {
  background-color: #333333;
}
[class*=daySelectInput].is-open .Select-control {
  background-color: #f5f6f9;
}
[class*=daySelectInput] .Select-menu-outer {
  max-height: 16.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border-color: #e2e9f1;
  margin-top: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.08);
  padding: 0;
}
[class*=daySelectInput] .Select-menu-outer .Select-menu {
  max-height: 16rem;
}
[class*=daySelectInput] .Select-menu-outer .Select-menu .Select-option {
  position: relative;
  padding: 0.375rem 0.5rem 0.25rem;
  font-weight: 400;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  min-height: 2.25rem;
  color: #1b264f;
  display: flex;
  align-items: center;
}
[class*=daySelectInput] .Select-menu-outer .Select-menu .Select-option:first-child {
  margin-top: 0.5rem;
}
[class*=daySelectInput] .Select-menu-outer .Select-menu .Select-option:last-child {
  margin-bottom: 0.5rem;
}
[class*=daySelectInput] .Select-menu-outer .Select-menu .Select-option.is-selected {
  font-weight: 800;
}
[class*=daySelectInput] .Select-menu-outer .Select-menu .Select-option:hover {
  color: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
[class*=selectInput] {
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0;
  min-width: 180px;
}
[class*=selectInput].headerHeight .Select-control {
  min-height: 2.75rem;
}
[class*=selectInput].is-disabled {
  pointer-events: none;
}
[class*=selectInput].is-disabled .Select-control {
  background-color: #fff;
}
[class*=selectInput].is-disabled .Select-control .Select-value .Select-value-label {
  color: #a0b5c4 !important;
}
[class*=selectInput].is-disabled .Select-control .Select-arrow-zone .Select-arrow {
  background-color: #bccfdc;
}
[class*=selectInput] .Select-control {
  width: 100%;
  background-color: #fff;
  min-height: 2.5rem;
  height: auto;
  border: 1px solid #e2e9f1 !important;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: border 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
[class*=selectInput] .Select-control .Select-multi-value-wrapper {
  width: 100%;
}
[class*=selectInput] .Select-control .Select-placeholder {
  color: #c5d7e2 !important;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 0.875rem;
  margin: 0.125rem 0 0;
}
[class*=selectInput] .Select-control .Select-value {
  background-color: transparent;
  color: #1b264f !important;
}
[class*=selectInput] .Select-control .Select-value::before {
  display: flex;
  align-items: center;
  width: 1rem;
  height: 1rem;
  mask-size: 1rem;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-size: 1rem;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #6a80a7;
  margin: 0.125rem 0.75rem 0 0;
  transition: background-color 0.3s ease-in-out;
}
[class*=selectInput] .Select-control .Select-value .Select-value-label {
  color: #1b264f;
}
[class*=selectInput] .Select-control .Select-value .Select-value-label svg {
  display: none;
}
[class*=selectInput] .Select-control .Select-input {
  background-color: transparent;
  padding: 0 1rem;
  margin-top: 0.125rem;
  color: #1b264f !important;
  cursor: pointer;
}
[class*=selectInput] .Select-control .Select-input input {
  width: 100% !important;
  height: 32px;
  display: block;
  border: 0;
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
[class*=selectInput] .Select-control .Select-input input:focus {
  border: 0;
  background-color: transparent;
  cursor: text;
}
[class*=selectInput] .Select-control .Select-arrow-zone {
  text-align: right;
  padding: 0;
  margin-left: 0.625rem;
  display: block;
}
[class*=selectInput] .Select-control .Select-arrow-zone .Select-arrow {
  border: none;
  height: 0.5rem;
  width: 0.625rem;
  margin: -2px 18px 0 0;
  background-color: #333333;
  background-image: none;
  -webkit-mask-image: url(/static/media/arrow-down-icon-white.05b0df8c.svg);
  mask-image: url(/static/media/arrow-down-icon-white.05b0df8c.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color 0.3s ease-in-out;
}
[class*=selectInput] .Select-clear {
  margin-top: 0.125rem;
}
[class*=selectInput] .Select-control:hover, [class*=selectInput].is-open .Select-control {
  border-color: #333333 !important;
  border-radius: 0.5rem;
  box-shadow: none;
}
[class*=selectInput] .Select-control:hover .Select-value::before, [class*=selectInput].is-open .Select-control .Select-value::before {
  background-color: #333333;
}
[class*=selectInput] .Select-control:hover .Select-value .Select-value-label, [class*=selectInput].is-open .Select-control .Select-value .Select-value-label {
  color: #333333 !important;
}
[class*=selectInput] .Select-control:hover .Select-arrow-zone .Select-arrow, [class*=selectInput].is-open .Select-control .Select-arrow-zone .Select-arrow {
  background-color: #333333;
}
[class*=selectInput].is-open .Select-control {
  background-color: #f5f6f9;
}
[class*=selectInput] .Select-menu-outer {
  max-height: 16.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border-color: #e2e9f1;
  margin-top: 0.125rem;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.08);
  padding: 0;
  top: 100%;
  bottom: auto;
}
[class*=selectInput] .Select-menu-outer .Select-menu {
  max-height: 15.25rem;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option {
  position: relative;
  padding: 0.375rem 0.5rem 0.25rem;
  font-weight: 400;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  min-height: 2.25rem;
  color: #1b264f;
  display: flex;
  align-items: center;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option svg {
  fill: #bccfdc;
  transition: fill 0.3s ease-in-out;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option:first-child {
  margin-top: 0.5rem;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option:last-child {
  margin-bottom: 0.5rem;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option.is-selected {
  font-weight: 800;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option.is-disabled {
  color: #a0b5c4;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option:hover:not(.is-disabled) {
  color: #333333;
}
[class*=selectInput] .Select-menu-outer .Select-menu .Select-option:hover:not(.is-disabled) svg {
  fill: #333333;
}
[class*=selectInput].darkBoarder:not(.is-open) .Select-control:not(:hover) {
  border-color: #bccfdc !important;
}
[class*=selectInput].Select--single .Select-control .Select-value {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  cursor: pointer;
}
[class*=selectInput].Select--single .Select-control .Select-value .Select-value-label {
  display: flex;
  align-items: center;
  line-height: 1rem;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0.125rem 0 0;
  transition: color 0.3s ease-in-out;
}
[class*=selectInput].Select--single .Select-control .Select-input {
  width: 100%;
}
[class*=selectInput].Select--multi.has-value .Select-control .Select-value {
  border-color: #e2e9f1;
  border-radius: 0.375rem;
}
[class*=selectInput].Select--multi.has-value .Select-control .Select-value .Select-value-icon:hover {
  background-color: #f5f6f9;
  color: #333333;
}
[class*=selectInput].Select--multi.has-value .Select-control .Select-value .Select-value-label {
  font-weight: 800;
  font-size: 0.75rem;
}
[class*=selectInput].Select--multi.has-value .Select-control .Select-input {
  margin-left: 0;
  padding: 0 0.5rem;
}

[class*=integrationSelector] .Select-control .Select-multi-value-wrapper {
  position: relative !important;
}
[class*=integrationSelector] .Select-control .Select-multi-value-wrapper .Select-value-label {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  display: block !important;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: #333944 !important;
}

.__react_component_tooltip.type-dark.place-left:after {
  border-left-color: #333944 !important;
}

.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #333944 !important;
}

.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: #333944 !important;
}

.data-tip {
  position: relative;
}
.data-tip:hover:after {
  content: attr(data-tip);
  position: absolute;
  bottom: 110%;
  font-style: "Nunito Sans", sans-serif;
  line-height: 16px;
  font-weight: 400;
  border: 1px solid #e2e9f1;
  font-size: 12px;
  padding: 10px 18px;
  background-color: #ffffff;
  min-width: 160px;
  max-width: 280px;
  border-radius: 6px;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.Close-Large,
.Close-Drawer,
.Close-Dialog {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  border-radius: 0.625rem;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Close-Large span,
.Close-Drawer span,
.Close-Dialog span {
  position: absolute;
  opacity: 0;
  line-height: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.Close-Large > div,
.Close-Drawer > div,
.Close-Dialog > div {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  transition: transform 0.3s ease;
}
.Close-Large > div::after, .Close-Large > div::before,
.Close-Drawer > div::after,
.Close-Drawer > div::before,
.Close-Dialog > div::after,
.Close-Dialog > div::before {
  content: "";
  display: block;
  width: 0.125rem;
  height: 2.125rem;
  background-color: #6a80a7;
  border-radius: 0.125rem;
  position: absolute;
  left: 0.6875rem;
  top: -0.25rem;
  transition: background-color 0.3s ease-in-out;
}
.Close-Large > div::after,
.Close-Drawer > div::after,
.Close-Dialog > div::after {
  transform: rotate(45deg);
}
.Close-Large > div::before,
.Close-Drawer > div::before,
.Close-Dialog > div::before {
  transform: rotate(-45deg);
}
.Close-Large:hover,
.Close-Drawer:hover,
.Close-Dialog:hover {
  background-color: #f5f6f9;
}
.Close-Large:hover > div::after, .Close-Large:hover > div::before,
.Close-Drawer:hover > div::after,
.Close-Drawer:hover > div::before,
.Close-Dialog:hover > div::after,
.Close-Dialog:hover > div::before {
  background-color: #333333;
}

.Close-Large {
  background-color: #fff;
}
.Close-Large > div {
  transform: scale(0.9);
}
.Close-Large:hover > div {
  transform: scale(0.8);
}

.Close-Drawer > div {
  height: 1rem;
  width: 1rem;
}
.Close-Drawer > div::after, .Close-Drawer > div::before {
  width: 0.125rem;
  height: 1.375rem;
  left: 0.45rem;
  top: -0.2rem;
}
.Close-Drawer:hover {
  background-color: rgba(82, 46, 255, 0.07);
}

.Close-Suggestion.Close-Drawer > div::after, .Close-Suggestion.Close-Drawer > div::before {
  background-color: #fff;
}
.Close-Suggestion.Close-Drawer:hover {
  background-color: rgba(37, 51, 72, 0.1);
}
.Close-Suggestion.Close-Drawer:hover > div::after, .Close-Suggestion.Close-Drawer:hover > div::before {
  background-color: #fff;
}

.Close-Dialog {
  height: 2.5rem;
  width: 2.5rem;
}
.Close-Dialog > div {
  height: 1rem;
  width: 1rem;
  transform: translateY(1px);
}
.Close-Dialog > div::after, .Close-Dialog > div::before {
  width: 0.125rem;
  height: 1.375rem;
  left: 0.45rem;
  top: -0.2rem;
}
.Close-Dialog:hover {
  background-color: rgba(106, 128, 167, 0.07);
}
.Close-Dialog:hover > div::after, .Close-Dialog:hover > div::before {
  background-color: #1b264f;
}
.noTopLine .recharts-cartesian-grid-horizontal > line:last-of-type {
  display: none;
}

.noBottomLine .recharts-cartesian-grid-horizontal > line:first-of-type {
  display: none;
}

@media print {
  .recharts-wrapper,
  .recharts-surface,
  .recharts-legend-wrapper {
    width: 100% !important;
  }
  .recharts-legend-item > svg {
    width: auto !important;
    display: inline-block !important;
    right: inherit !important; /* align to left */
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.ReactTable.AnomalyDrawerTable .rt-thead .rt-th.-sort-asc,
.ReactTable.AnomalyDrawerTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
}
.ReactTable.AnomalyDrawerTable .rt-thead .rt-th.-sort-asc svg,
.ReactTable.AnomalyDrawerTable .rt-thead .rt-th.-sort-desc svg {
  opacity: 1;
  fill: #333333;
  transition: transform 0.2s ease;
}
.ReactTable.AnomalyDrawerTable .rt-thead .rt-th.-sort-asc svg {
  transform: rotate(180deg);
}

.TableScrollWrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}
.TableScrollWrapper::-webkit-scrollbar {
  height: 1.5rem;
  background-color: transparent;
}
.TableScrollWrapper::-webkit-scrollbar-thumb {
  background-color: rgba(27, 38, 79, 0.5);
  border-width: 0.5rem;
  border: 0.5rem #fff solid;
  border-radius: 1rem;
}
.TableScrollWrapper .MorphioTable {
  table-layout: fixed;
}
.TableScrollWrapper.emptyWrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.ScrollSpacer {
  display: block;
  z-index: 1;
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
  transform: translateY(-1.5rem);
}
.ScrollSpacer.isPaginated {
  background-color: none;
}

.MorphioTable {
  font-family: "Nunito Sans", sans-serif;
  max-width: 100%;
}
.MorphioTable .MuiTableCell-root {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  overflow-wrap: break-word;
  color: #1b264f;
  border-color: #eaf0f7;
  letter-spacing: 0;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.MorphioTable .MuiTableHead-root {
  border-top: 1px solid #eaf0f7;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head {
  background-color: #fbfcfc;
  color: #1b264f;
  font-weight: 700;
  line-height: 1rem;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head:not(.noDividers) {
  border-left: 1px solid #eaf0f7;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head:first-of-type, .MorphioTable .MuiTableHead-root .MuiTableCell-head.emptyCell.noDividers {
  border-left: none;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head.freezeCell {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  transition: box-shadow 0.3s ease-in-out;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head.freezeCell::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0.25rem;
  height: 100%;
  left: calc(100% - 0.25rem);
  z-index: -1;
  box-shadow: 0.25rem 0 0.25rem 0 rgba(51, 57, 68, 0.07);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head.freezeCell.isScrolled::after {
  opacity: 1;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .optionsIcon {
  transform: rotate(90deg);
  order: -2;
  opacity: 0;
  cursor: pointer;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head:hover .optionsIcon {
  opacity: 1;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .MuiTableSortLabel-root {
  padding: 1rem 0.625rem 1rem 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.2s ease;
  transform: translateY(1px);
  min-height: 3.5rem;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .MuiTableSortLabel-root .MuiSvgIcon-root {
  display: none;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .MuiTableSortLabel-root .arrowSort {
  min-width: 0.75rem;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease, fill 0.2s ease;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .MuiTableSortLabel-root:hover {
  color: #333333;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .MuiTableSortLabel-root:hover .arrowSort {
  opacity: 1;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #333333;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head .MuiTableSortLabel-root.MuiTableSortLabel-active .arrowSort {
  opacity: 1;
  fill: #333333;
}
.MorphioTable .MuiTableHead-root .MuiTableCell-head[aria-sort=descending] .MuiTableSortLabel-active .arrowSort {
  transform: rotate(180deg);
}
.MorphioTable .MuiTableHead-root .bulkActionButton {
  display: none;
}
.MorphioTable .MuiTableBody-root .MuiTableRow-root.Mui-selected {
  background-color: #f5f6f9;
}
.MorphioTable .MuiTableBody-root .MuiTableRow-root.Mui-selected .MuiTableCell-root {
  background-color: #f5f6f9 !important;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body {
  padding: 0.75rem 0.875rem;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body.expandContentHeight {
  height: 0;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body.freezeCell {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2 !important;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body.freezeCell::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0.25rem;
  height: 100%;
  left: calc(100% - 0.25rem);
  z-index: -1;
  box-shadow: 0.25rem 0 0.25rem 0 rgba(51, 57, 68, 0.07);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body.freezeCell.isScrolled::after {
  opacity: 1;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle > svg {
  transform: rotate(-90deg) translateX(-0.25rem);
  transform-origin: center;
  min-width: 0.625rem;
  min-height: 0.625rem;
  transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle > span {
  margin-left: 0.625rem;
  display: block;
  transition: color 0.3s ease-in-out;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle:hover > svg, .MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle.open > svg {
  fill: #333333;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle:hover > span, .MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle.open > span {
  color: #333333;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body .collapseToggle.open > svg {
  transform: rotate(0deg) translateY(0.25rem);
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body.collapsedCell {
  padding: 0;
}
.MorphioTable .MuiTableBody-root .MuiTableCell-body.collapsedCell .MorphioTable .MuiTableHead-root .MuiTableCell-head {
  background-color: #fff;
}
.MorphioTable .MuiTableRow-root.totalsRow .MuiTableCell-root {
  border-top: 0.1875rem solid #e2e9f1;
  border-bottom: none;
}
.MorphioTable .MuiTableRow-root.totalsRow .MuiTableCell-root:first-child {
  border-radius: 0 0 0 0.625rem;
}
.MorphioTable .MuiTableRow-root.totalsRow .MuiTableCell-root:last-child {
  border-radius: 0 0 0.625rem 0;
}
.MorphioTable .MuiTableRow-root {
  transition: background-color 0.3s ease-in-out;
}
.MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #f8f9fb;
}
.MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-root {
  background-color: #f8f9fb;
}
.MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-body .collapseToggle svg {
  fill: #333333;
}
.MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-body .collapseToggle > span {
  color: #333333;
}
.MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-body .collapseToggle:hover svg, .MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-body .collapseToggle.open svg {
  fill: #333333;
}
.MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-body .collapseToggle:hover > span, .MorphioTable .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-body .collapseToggle.open > span {
  color: #333333;
}
.MorphioTable .MuiTableRow-root.collapsedOpen, .MorphioTable .MuiTableRow-root.collapsedOpen.MuiTableRow-hover:hover {
  background-color: #f5f6f9;
}
.MorphioTable .MuiTableRow-root.collapsedOpen .MuiTableCell-root, .MorphioTable .MuiTableRow-root.collapsedOpen.MuiTableRow-hover:hover .MuiTableCell-root {
  background-color: #f5f6f9;
}
.MorphioTable .MuiTableRow-root.hasCollapsedComponent:not(.collapsedOpen) .MuiTableCell-body {
  border-bottom-color: transparent;
}
.MorphioTable.allowScroll {
  max-width: none;
}
.MorphioTable.verticalAlignTop .MuiTableBody-root .MuiTableCell-body {
  vertical-align: top;
}

.MuiPaper-root.MuiPopover-paper {
  border-radius: 0.5rem;
  border: 1px solid #e2e9f1;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.05);
}
.MuiPaper-root.MuiPopover-paper .MuiList-root {
  padding: 0;
}
.MuiPaper-root.MuiPopover-paper .MuiList-root .MuiListItem-root {
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  color: #1b264f;
}
.MuiPaper-root.MuiPopover-paper .MuiList-root .MuiListItem-root:first-of-type {
  margin-top: 0.5rem;
}
.MuiPaper-root.MuiPopover-paper .MuiList-root .MuiListItem-root:last-of-type {
  margin-bottom: 0.5rem;
}
.MuiPaper-root.MuiPopover-paper .MuiList-root .MuiListItem-root:hover {
  background-color: #f5f6f9;
  color: #333333;
}
.MuiPaper-root.MuiPopover-paper .MuiList-root .MuiListItem-root.Mui-selected {
  background-color: transparent;
  font-weight: 700;
}
.MuiPaper-root.MuiPopover-paper .MuiList-root .MuiListItem-root.Mui-selected:hover {
  background-color: #f5f6f9;
}

.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root {
  border: none;
  font-family: "Nunito Sans", sans-serif;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root p {
  font-family: "Nunito Sans", sans-serif;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar {
  justify-content: center;
  padding: 0.75rem;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiTablePagination-caption {
  letter-spacing: 0;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiTablePagination-spacer {
  display: none;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiSelect-select {
  font-family: "Nunito Sans", sans-serif;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiSelect-select:focus {
  background-color: transparent;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiSelect-selectMenu {
  min-height: 0;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiTablePagination-actions .MuiIconButton-root {
  transition: background-color 0.3s ease-in-out;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiTablePagination-actions .MuiIconButton-root svg {
  transition: fill 0.3s ease-in-out;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiTablePagination-actions .MuiIconButton-root:hover {
  background-color: #f5f6f9;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiTablePagination-actions .MuiIconButton-root:hover svg {
  fill: #333333;
}
.PaginationTable .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root .MuiTablePagination-toolbar .MuiTablePagination-actions .MuiIconButton-root .MuiTouchRipple-root {
  display: none;
}
.PaginationTable.allowScroll .MuiTableFooter-root .MuiTablePagination-root.MuiTableCell-root {
  border-top: 1px solid #e2e9f1;
}

@-moz-document url-prefix() {
  .MorphioTable .MuiTableBody-root .MuiTableCell-body.expandContentHeight {
    height: auto;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.MuiSlider-root .MuiSlider-rail {
  background-color: #e2e9f1;
  height: 0.25rem;
  border-radius: 0.25rem;
}
.MuiSlider-root .MuiSlider-track {
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: #333333;
}
.MuiSlider-root .MuiSlider-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border: 0.3125rem solid #333333;
  box-shadow: 0 0.25rem 0.375rem 0 rgba(68, 76, 89, 0.2);
  transition: border-color 0.3s ease-in-out;
}
.MuiSlider-root .MuiSlider-thumb:hover {
  box-shadow: 0 0.25rem 0.375rem 0 rgba(68, 76, 89, 0.2);
  border-color: #333333;
}
.MuiSlider-root .MuiSlider-thumb::after {
  display: none;
}
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel,
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] {
  left: calc(50% - 1px);
  transform: translate(-50%, -0.5rem);
}
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel [class*=PrivateValueLabel-circle],
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel > [class*=jss],
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] [class*=PrivateValueLabel-circle],
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] > [class*=jss] {
  border-radius: 0.375rem;
  transform: rotate(0deg);
  height: 1.625rem;
  width: 3.25rem;
  background-color: #333333;
}
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel [class*=PrivateValueLabel-circle]::after,
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel > [class*=jss]::after,
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] [class*=PrivateValueLabel-circle]::after,
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] > [class*=jss]::after {
  position: absolute;
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  transform: rotate(45deg) translateX(-50%);
  background-color: #333333;
  border-radius: 0.125rem;
  bottom: -0.5rem;
  left: 50%;
}
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel [class*=PrivateValueLabel-circle] [class*=PrivateValueLabel-label],
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel [class*=PrivateValueLabel-circle] > [class*=jss],
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel > [class*=jss] [class*=PrivateValueLabel-label],
.MuiSlider-root .MuiSlider-thumb [class*=PrivateValueLabel-offset].MuiSlider-valueLabel > [class*=jss] > [class*=jss],
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] [class*=PrivateValueLabel-circle] [class*=PrivateValueLabel-label],
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] [class*=PrivateValueLabel-circle] > [class*=jss],
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] > [class*=jss] [class*=PrivateValueLabel-label],
.MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel[class*=jss] > [class*=jss] > [class*=jss] {
  transform: rotate(0);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  font-size: 0.6875rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.intercom-lightweight-app {
  z-index: 100 !important;
}

@media only all and (max-width: 48rem) {
  .intercom-lightweight-app {
    display: none !important;
  }
}
.MuiDialog-root .MuiBackdrop-root {
  background-color: rgba(68, 76, 89, 0.8);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_selectionDropDown__2gNIX {
  min-width: 22rem;
  border: 1px solid #e2e9f1;
  max-width: 40rem;
  border-radius: 0.625rem;
}

@media only all and (max-width: 48rem) {
  .style_selectionDropDown__2gNIX {
    min-width: 18rem;
  }
}
.style_titleWrapper__28CB6 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.style_titleWrapper__28CB6 .style_img__3r9fy {
  width: 2rem;
  height: 2rem;
  border: 1px solid #bccfdc;
  border-radius: 0.375rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-color: #fff;
  margin-right: 0.625rem;
}

.style_title__39F5y {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.style_greetingTitle__2gnxJ {
  font-size: 1rem;
  font-weight: 800;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 1rem;
  animation: style_slideFadeIn__3ttnv 1s ease-in-out forwards;
  animation-delay: 2s;
  opacity: 0;
}

@keyframes style_slideFadeIn__3ttnv {
  from {
    transform: translateX(-0.5rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.style_listContainer__aC8tj {
  height: 22.25rem;
  overflow-y: auto;
}
.style_listContainer__aC8tj .style_listItem__GjC55 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.style_listContainer__aC8tj .style_listFeatureItem__Qo4zg {
  display: flex;
  align-items: center;
}
.style_listContainer__aC8tj .style_listFeatureItem__Qo4zg svg {
  width: 0.875rem;
}
.style_listContainer__aC8tj svg {
  width: 10px;
  fill: #ffbc00;
  margin-right: 0.25rem;
  transform: translateY(-0.0625rem);
}

.style_dropDownTitle__1t_No {
  display: flex;
  align-items: center;
}
.style_dropDownTitle__1t_No .style_iconTitle__UFuhK {
  margin-right: 0.5rem;
}
.style_dropDownTitle__1t_No .style_iconTitle__UFuhK svg {
  width: 0.875rem;
}
.style_dropDownTitle__1t_No .style_iconTitle__UFuhK svg,
.style_dropDownTitle__1t_No .style_iconTitle__UFuhK g,
.style_dropDownTitle__1t_No .style_iconTitle__UFuhK path {
  fill: #242423;
}

.style_dropDownContainer__1_nD_ {
  position: relative;
}

.style_slash__Ag5PU {
  color: #6a80a7;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  text-align: center;
  margin: 0 0.375rem;
}

.style_iconLoading__2XCZm {
  margin-right: 0.875rem;
}

.style_titleContainer__2betB {
  align-items: center;
  display: flex;
  flex: 1 1;
  margin: 0 1.5rem 0 0;
  min-width: 0;
  position: relative;
}
.style_titleContainer__2betB svg {
  height: 32px;
}

.style_icon__umECH {
  cursor: pointer;
  height: 1rem !important;
  margin-left: 0.25rem;
}

@media only all and (max-width: 48rem) {
  .style_icon__umECH {
    margin-left: 0rem;
  }
}
.style_search__3eAj7 {
  min-width: 16.5rem;
}

.style_loadingPrompt__Z4bWX {
  justify-content: flex-start;
}

.style_selectionArrow__vPSHa {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
  border-radius: 0.5rem;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  background-color: rgba(226, 233, 241, 0.4);
  border: 0.0625rem solid rgba(226, 233, 241, 0.4);
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.style_selectionArrow__vPSHa h1 {
  transition: color 0.2s ease-in-out;
}
.style_selectionArrow__vPSHa .style_selectionArrowButton__2rHo_ {
  margin-left: 0.25rem;
}
.style_selectionArrow__vPSHa:hover {
  background-color: #fff;
  box-sizing: border-box;
  border: 0.0625rem solid #242423;
}
.style_selectionArrow__vPSHa.style_open__23KAa {
  box-sizing: border-box;
  border: 0.0625rem solid #242423;
  background-color: #fff;
}
.style_selectionArrow__vPSHa.style_open__23KAa .style_selectionArrowButton__2rHo_ svg {
  transform: rotate(180deg);
}

.style_icon__umECH {
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: rgba(226, 233, 241, 0.4);
}
.style_icon__umECH svg,
.style_icon__umECH g,
.style_icon__umECH path {
  fill: #242423;
}
.style_icon__umECH:hover {
  box-sizing: border-box;
  border: 0.0625rem solid #242423;
}

.style_iconItem__3Mn-U {
  min-width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: start;
}
.style_iconItem__3Mn-U svg,
.style_iconItem__3Mn-U g,
.style_iconItem__3Mn-U path {
  fill: #242423;
}

.style_animateBot___Hpf3 {
  animation-duration: 1.5s;
  animation-name: style_animate-bot__NPbET;
  animation-iteration-count: infinite;
  position: absolute;
  bottom: -0.5rem;
}
.style_animateBot___Hpf3 svg {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.style_zeroState__2hmW4 {
  padding: 2rem 1.5rem 3rem !important;
  max-width: 18rem;
  margin: 0 auto;
  height: 100%;
}
.style_zeroState__2hmW4 img {
  height: 3.125rem !important;
}
.style_zeroState__2hmW4 h4 {
  font-size: 1.125rem !important;
  margin-bottom: 0 !important;
  margin-top: 0.5rem !important;
}

.style_accountTagsWrapper__1fZa1 {
  margin-left: 0.5rem;
}

@keyframes style_animate-bot__NPbET {
  from {
    left: -2.5rem;
    transform: rotate(0deg);
  }
  25% {
    left: 0;
    transform: rotate(0deg);
  }
  35% {
    left: 0;
    transform: rotate(0deg);
  }
  65% {
    left: 0;
    transform: rotate(360deg);
  }
  75% {
    left: 0;
    transform: rotate(360deg);
  }
  to {
    left: 2.5rem;
    transform: rotate(360deg);
  }
}
.style_filterContainer__2xlSM {
  width: 16.75rem;
  border-left: 1px solid #e2e9f1;
  padding: 1rem 1rem;
  max-height: 25rem;
  overflow-y: auto;
}
.style_filterContainer__2xlSM .style_filterIcon__2lGuP {
  margin-right: 0.25rem;
  color: #242423;
  font-size: 0.875rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}
.style_filterContainer__2xlSM .style_filterIcon__2lGuP svg path {
  fill: #242423;
}
.style_filterContainer__2xlSM .style_filterIcon__2lGuP svg {
  height: unset;
}
.style_filterContainer__2xlSM .style_filterDropdown__10nbY {
  display: flex;
  justify-content: start;
  margin-bottom: 0.5rem;
}
.style_filterContainer__2xlSM .style_filterDropdown__10nbY svg {
  height: 0.875rem;
}

.style_selectionAccount__35Ifo {
  display: flex;
}

.style_checkboxItem__9LfM9 {
  width: 100%;
}
.style_checkboxItem__9LfM9:hover .style_accountTag__17iLT > div {
  opacity: 1;
}

.style_accountTag__17iLT {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.style_tagsSelectedContainer__dolJq {
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.375rem;
  gap: 0.375rem;
}

.style_platformSelectedContainer__1CDRI {
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.style_platformSelectedContainer__1CDRI .style_platformIcon__3UOPE {
  width: auto;
  height: auto;
  padding: 0.313rem;
  border-radius: 0.625rem;
}
.style_platformSelectedContainer__1CDRI .style_platformIcon__3UOPE svg {
  height: 0.875rem;
}

.style_filterDropdownTags__23pAe > ul {
  max-height: 14rem !important;
  display: flex;
  flex-direction: column;
}

.style_listAccountContainer__276Sr {
  width: 25rem;
}

.style_tagsDropdown__jK3ER {
  z-index: 2;
  overflow: hidden;
  left: 0;
  height: 14rem;
  position: absolute;
  border-radius: 0.625rem;
  background-color: #fff;
  border: 1px solid #e2e9f1;
  margin: 0;
  padding: 0;
  bottom: -0.25rem;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.08);
  width: 100%;
}
.style_tagsDropdown__jK3ER > div {
  width: auto !important;
  height: 13.5rem;
  min-height: unset;
}
.style_tagsDropdown__jK3ER > div > div:first-child {
  width: auto;
  margin-right: 0.5625rem;
}

.style_listTagsContainer__1Y5Lk {
  overflow-y: auto;
}

.style_noAccountFoundIcon__2_re0 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(226, 233, 241, 0.6);
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 0.5rem;
}
.style_noAccountFoundIcon__2_re0 svg {
  width: 1.125rem;
}
.style_noAccountFoundIcon__2_re0 svg path {
  fill: #6a80a7;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_filterDropdown__1i7-k {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  border-radius: 0.5rem;
  background-color: rgba(106, 128, 167, 0.07);
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: #242423;
  font-size: 0.875rem;
  font-weight: 600;
}
.style_filterDropdown__1i7-k:hover {
  border-color: #242423;
}
.style_filterDropdown__1i7-k:hover:not(.style_active__sI31v) {
  background-color: #fff;
}
.style_filterDropdown__1i7-k > svg {
  width: 0.875rem;
  height: 0.875rem;
}
.style_filterDropdown__1i7-k > svg path {
  fill: #242423;
}
.style_filterDropdown__1i7-k .style_option__1J3HW {
  display: flex;
}
.style_filterDropdown__1i7-k > ul {
  max-height: 18.75rem;
  overflow-y: auto;
}
.style_filterDropdown__1i7-k .style_filterDropdownMenu__3ipcZ {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  color: #0671ee;
  font-size: 0.875rem;
  font-weight: bold;
  width: unset !important;
}
.style_filterDropdown__1i7-k .style_filterDropdownMenu__3ipcZ span {
  color: #242423;
}
.style_filterDropdown__1i7-k .style_filterDropdownMenu__3ipcZ svg {
  fill: #242423;
  transition: fill 0.3s ease-in-out;
}
.style_filterDropdown__1i7-k .style_filterDropdownMenu__3ipcZ p {
  color: #0671ee !important;
}
.style_filterDropdown__1i7-k .style_filterDropdownMenu__3ipcZ .style_closeIcon__3XTcv {
  transition: opacity 0.3s ease, background-color 0.3s ease;
  background-color: #333333;
  height: 0.875rem;
  width: 0.875rem;
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.625rem;
}
.style_filterDropdown__1i7-k .style_filterDropdownMenu__3ipcZ .style_closeIcon__3XTcv:hover {
  background-color: #fb1427;
}
.style_filterDropdown__1i7-k .style_filterDropdownMenu__3ipcZ .style_closeIcon__3XTcv svg path {
  fill: #fff;
  width: 0.375rem;
  height: 0.375rem;
}
.style_filterDropdown__1i7-k .style_optionsDropdown__12HNT {
  min-width: 8.5rem;
  bottom: -0.25rem;
  z-index: 6;
}
.style_filterDropdown__1i7-k .style_option__1J3HW .style_menuLabel__1utco {
  display: flex;
  align-items: center;
}
.style_filterDropdown__1i7-k .style_option__1J3HW .style_menuLabel__1utco svg {
  width: 1rem;
  height: auto;
  margin-right: 0.625rem;
  fill: #242423;
}
.style_filterDropdown__1i7-k .style_option__1J3HW.style_delete__2QWno {
  color: #fb1427;
}
.style_filterDropdown__1i7-k .style_option__1J3HW.style_delete__2QWno svg {
  fill: #fb1427;
}
.style_filterDropdown__1i7-k .style_option__1J3HW.style_delete__2QWno:hover {
  background-color: #ffeef0;
}

.style_active__sI31v {
  color: #242423;
  background-color: rgba(6, 113, 238, 0.1);
}
.style_active__sI31v > svg path {
  fill: #242423;
}

.style_open__3B4Ul {
  border-color: #242423;
  color: #242423;
}
.style_open__3B4Ul > svg path {
  fill: #242423;
}

.style_open__3B4Ul:not(.style_active__sI31v) {
  background-color: #fff;
}

.style_hideLabel__2lpki {
  flex: 1 1;
  justify-content: end !important;
}
.style_hideLabel__2lpki > span {
  opacity: 0;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_emptyFilter__3YOh9 {
  font-size: 0.75rem;
  padding: 1rem 1.25rem;
  text-transform: none;
}

.style_search__3Siku {
  position: relative;
}
.style_search__3Siku .style_inputWrapper__3UjEv {
  margin-bottom: 0;
  width: 100%;
}
.style_search__3Siku .style_inputWrapper__3UjEv > div {
  border: none;
}
.style_search__3Siku .style_inputWrapper__3UjEv > div:focus-within {
  border-color: #e2e9f1 !important;
}
.style_search__3Siku .style_inputWrapper__3UjEv > div input {
  padding-left: 2.5rem !important;
  height: 2.5rem !important;
}
.style_search__3Siku.style_hasBorder__2vc0U .style_inputWrapper__3UjEv > div {
  border-bottom: 1px solid #e2e9f1;
  border-radius: 0.5rem 0.5rem 0 0;
}
.style_search__3Siku .style_inputFilters__3j0YF > div input {
  padding-right: 9rem !important;
}
.style_search__3Siku .style_indicator__3l4AW {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
  display: flex;
  align-items: center;
  height: 1rem;
  width: 1rem;
}
.style_search__3Siku .style_indicator__3l4AW svg {
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
}
.style_search__3Siku .style_indicator__3l4AW.style_close__2h4oL {
  cursor: pointer;
}
.style_search__3Siku .style_indicator__3l4AW.style_close__2h4oL svg {
  fill: #6a80a7;
}
.style_search__3Siku:focus-within svg {
  fill: #333333;
}

.style_filters__2lObB {
  position: absolute;
  top: 0.25rem;
  right: 1rem;
  z-index: 1;
}

.style_filters__2lObB button {
  height: 2rem;
  width: 7.375rem;
  border-radius: 0.5rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #f5f6f9;
  border: 1px solid #f5f6f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #1b264f;
  padding: 0 0.75rem;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.style_filters__2lObB button.style_disabled__1Qy8u {
  pointer-events: none;
  background-color: #f8f9fb;
  border-color: #f8f9fb;
  color: #a0b5c4;
}
.style_filters__2lObB button.style_disabled__1Qy8u svg {
  fill: #a0b5c4;
}
.style_filters__2lObB button svg {
  margin-left: 0.375rem;
  width: 0.5rem;
  fill: #1b264f;
  transition: fill 0.3s ease-in-out;
}
.style_filters__2lObB button.style_open__3EJg7 {
  border-color: #333333;
}
.style_filters__2lObB button:focus {
  outline: none;
}
.style_filters__2lObB button:hover {
  color: #333333;
}
.style_filters__2lObB button:hover svg {
  fill: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_accountWrapper__3r4zG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
  flex-wrap: wrap;
}

.style_accountTags__5GmvR {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.style_platformTag__19rJ1 {
  height: 24px !important;
  padding: 0 !important;
  margin-right: 0 !important;
}

.style_hasReport__1Mo1F {
  cursor: pointer !important;
}

.style_tag__2856l.style_addButton__1dstB {
  padding: 0;
  background-color: transparent !important;
}

.style_platformTagIcon__SJ2K2 {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.style_platformTagIcon__SJ2K2 svg {
  height: 0.75rem !important;
  width: auto !important;
}

.style_tag__2856l {
  background-color: rgba(160, 181, 196, 0.12);
  height: 1.75rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  padding: 0 0.5rem;
  position: relative;
}
.style_tag__2856l.style_active__3e-AH .style_tagBorder__14Azc {
  opacity: 1;
}
.style_tag__2856l.style_large__2_xwd {
  height: 2rem;
}
.style_tag__2856l > span {
  font-size: 0.75rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: #1b264f;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 4rem;
}
.style_tag__2856l .style_tagBorder__14Azc {
  height: 1.75rem;
  border-radius: 1rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.style_tag__2856l .style_tagName__q2zSU {
  display: flex;
  align-items: center;
}
.style_tag__2856l .style_tagName__q2zSU svg {
  margin-right: 4px;
  width: 12px;
}
.style_tag__2856l .style_remove__3tAAy {
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 50%;
  background-color: #1b264f;
  cursor: pointer;
  transform: translateX(0.25rem);
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
}
.style_tag__2856l .style_remove__3tAAy:hover {
  background-color: #fb1427;
}
.style_tag__2856l .style_remove__3tAAy svg {
  height: 0.375rem;
  width: 0.375rem;
  fill: #fff;
}

.style_manageTagsWrapper__sbUCY {
  position: relative;
}

.style_manageTagsIcon__2btDx {
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  border-radius: 0.375rem;
  transform: translateY(-0.125rem);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.style_manageTagsIcon__2btDx:hover, .style_manageTagsIcon__2btDx.style_isOpen__1Mgm9 {
  background-color: rgba(51, 51, 51, 0.1);
  visibility: visible;
  opacity: 1;
}
.style_manageTagsIcon__2btDx:hover svg, .style_manageTagsIcon__2btDx.style_isOpen__1Mgm9 svg {
  fill: #333333;
}
.style_manageTagsIcon__2btDx svg {
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
}

.style_manageTags__1zAcf {
  width: 18.5rem;
  background-color: #fff;
  transform: translateY(0.25rem);
  border: 1px solid #e2e9f1;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.08);
}
.style_manageTags__1zAcf .style_tagSearch__-j2I9 {
  border: none;
  min-height: 15rem;
}
.style_manageTags__1zAcf .style_tagSearch__-j2I9 .style_tagsTitle__19MLg {
  margin: 0.75rem 0 0.5rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: 700;
  display: block;
}
.style_manageTags__1zAcf .style_tagSearch__-j2I9 .style_emptySearch__1--OS {
  font-size: 0.875rem;
  padding: 1rem 1.25rem;
  text-transform: none;
  flex-grow: 1;
}
.style_manageTags__1zAcf .style_tagSearch__-j2I9 .style_editTag__1KKJs {
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  z-index: 2;
  right: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.style_manageTags__1zAcf .style_tagSearch__-j2I9 .style_editTag__1KKJs svg {
  width: 0.875rem;
  height: 0.875rem;
}
.style_manageTags__1zAcf .style_tagSearch__-j2I9 .style_editTag__1KKJs svg > g {
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
}
.style_manageTags__1zAcf .style_tagSearch__-j2I9 .style_editTag__1KKJs:hover svg > g {
  fill: #333333;
}
.style_manageTags__1zAcf .style_tagCheckbox__1LUZZ {
  padding: 0 0.5rem;
  margin: 0.125rem 0;
  position: relative;
}
.style_manageTags__1zAcf .style_tagCheckbox__1LUZZ .style_tag__2856l {
  margin-left: 0;
}
.style_manageTags__1zAcf .style_tagCheckbox__1LUZZ .style_tag__2856l > span {
  cursor: pointer;
}
.style_manageTags__1zAcf .style_tagCheckbox__1LUZZ:hover .style_tag__2856l .style_tagBorder__14Azc {
  opacity: 1;
}
.style_manageTags__1zAcf .style_tagCheckbox__1LUZZ:hover .style_editTag__1KKJs {
  opacity: 1;
}
.style_manageTags__1zAcf .style_tagCheckbox__1LUZZ > div > div:first-child > div {
  margin-top: 0.25rem;
}
.style_manageTags__1zAcf .style_tagCheckbox__1LUZZ > div > div:last-child {
  margin-left: 0.375rem !important;
}
.style_manageTags__1zAcf .style_searchFooter__1zLSz {
  padding: 0.375rem 0;
  border-radius: 0 0 0.625rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16.375rem;
}
.style_manageTags__1zAcf .style_searchFooter__1zLSz .style_addNew__1lIsJ {
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.5rem;
  cursor: pointer;
  color: #333333;
  transition: color 0.3s ease-in-out;
}
.style_manageTags__1zAcf .style_searchFooter__1zLSz .style_addNew__1lIsJ:hover {
  color: #333333;
}
.style_manageTags__1zAcf .style_searchFooter__1zLSz button {
  height: 2rem;
}

.style_addTagContainer__39HDa {
  background-color: #fff;
  border-radius: 0.625rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
}
.style_addTagContainer__39HDa .style_heading__2XkdM {
  padding: 0.75rem 1.125rem;
  border-bottom: 1px solid #e2e9f1;
}
.style_addTagContainer__39HDa .style_heading__2XkdM .style_title__21s8k {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 800;
}
.style_addTagContainer__39HDa .style_heading__2XkdM .style_subTitle__1A7tX {
  display: block;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #6a80a7;
}
.style_addTagContainer__39HDa .style_inputWrapper__1L1j_ {
  padding: 1rem 1.125rem;
  display: grid;
  grid-template-columns: 1fr 2.25rem;
  grid-column-gap: 0.875rem;
  column-gap: 0.875rem;
  align-items: flex-start;
}
.style_addTagContainer__39HDa .style_inputWrapper__1L1j_ .style_tagInput__1Qndn {
  margin-bottom: 0;
}
.style_addTagContainer__39HDa .style_inputWrapper__1L1j_ .style_tagInput__1Qndn label {
  font-size: 0.8125rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
}
.style_addTagContainer__39HDa .style_inputWrapper__1L1j_ .style_tagInput__1Qndn input {
  height: 2.125rem;
}
.style_addTagContainer__39HDa .style_inputWrapper__1L1j_ .style_tagColor__18Gzd {
  width: 2.25rem;
  height: 2.25rem;
  margin-top: 1.5rem;
  border-radius: 50%;
  background-color: #f8f9fb;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.3s ease-in-out;
}
.style_addTagContainer__39HDa .style_addTagFooter__3djcA {
  margin-top: auto;
  background-color: #f8f9fb;
  padding: 0.625rem 1.125rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.style_addTagContainer__39HDa .style_addTagFooter__3djcA .style_deleteTag__3VeZd {
  margin-right: auto;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.style_addTagContainer__39HDa .style_addTagFooter__3djcA .style_deleteTag__3VeZd svg {
  height: 0.875rem;
  width: 0.75rem;
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
}
.style_addTagContainer__39HDa .style_addTagFooter__3djcA .style_deleteTag__3VeZd:hover {
  background-color: #ffeef0;
  border-radius: 0.5rem;
}
.style_addTagContainer__39HDa .style_addTagFooter__3djcA .style_deleteTag__3VeZd:hover svg {
  fill: #fb1427;
}
.style_addTagContainer__39HDa .style_addTagFooter__3djcA button {
  height: 2rem;
}
.style_addTagContainer__39HDa .style_addTagFooter__3djcA button:last-child {
  margin-left: 0.25rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_checkboxContainer__2KkIk {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-weight: 400;
  max-width: 100%;
  /* checked */
  /* auto checked */
  /* disabled */
  /* indent */
}
.style_checkboxContainer__2KkIk .style_checkbox__1WlbS {
  display: flex;
}
.style_checkboxContainer__2KkIk .style_checkbox__1WlbS.style_centerAlign__2YxMs {
  align-items: center;
}
.style_checkboxContainer__2KkIk .style_checkbox__1WlbS:hover .style_checkboxLabel__2DuKm {
  color: #333333;
}
.style_checkboxContainer__2KkIk .style_checkBoxCheckContainer__2zIO5 {
  padding: 0.25rem;
  border-radius: 4px 0 0 4px;
}
.style_checkboxContainer__2KkIk .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0 {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  border-radius: 0.3125rem;
  border: 1px solid #c5d7e2;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease-in-out;
}
.style_checkboxContainer__2KkIk .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0 svg {
  width: 10px;
  height: 10px;
}
.style_checkboxContainer__2KkIk .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0 svg g path {
  fill: #fff;
}
.style_checkboxContainer__2KkIk .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0:hover {
  border-color: #333333;
}
.style_checkboxContainer__2KkIk .style_checkboxInput__-tQ8F {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.style_checkboxContainer__2KkIk .style_checkboxLabel__2DuKm {
  color: #1b264f;
  font-size: 0.875rem;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 4px 4px 0;
  text-align: left;
  padding: 0.25rem 0;
  transition: color 0.3s ease-in-out;
}
.style_checkboxContainer__2KkIk .style_checkboxLabel__2DuKm.style_hideLabel__2VDEZ {
  position: absolute;
  opacity: 0;
  line-height: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.style_checkboxContainer__2KkIk .style_checkboxLabel__2DuKm.style_labelDetails__2gujr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.style_checkboxContainer__2KkIk .style_checkboxLabel__2DuKm.style_labelDetails__2gujr .style_details__1IEdl {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
}
.style_checkboxContainer__2KkIk .style_checkboxLabel__2DuKm.style_labelDetails__2gujr .style_details__1IEdl svg {
  height: 0.625rem;
  width: auto;
  margin-right: 0.375rem;
}
.style_checkboxContainer__2KkIk .style_tooltip__jCEIG {
  line-height: 0;
}
.style_checkboxContainer__2KkIk .style_tooltip__jCEIG svg {
  width: 0.75rem;
  height: 0.75rem;
  transition: fill 0.3s ease-in-out;
}
.style_checkboxContainer__2KkIk .style_tooltip__jCEIG:hover svg {
  fill: #333333;
}
.style_checkboxContainer__2KkIk.style_flipped__3TDqT {
  flex-direction: row-reverse;
}
.style_checkboxContainer__2KkIk.style_flipped__3TDqT .style_checkboxLabel__2DuKm {
  margin-right: 0.5rem;
}
.style_checkboxContainer__2KkIk:not(.style_flipped__3TDqT) .style_checkboxLabel__2DuKm {
  margin-left: 0.5rem;
}
.style_checkboxContainer__2KkIk:not(.style_flipped__3TDqT) .style_checkBoxCheckContainer__2zIO5 {
  padding-left: 0;
}
.style_checkboxContainer__2KkIk.style_large__-JHvn .style_checkBoxCheckContainer__2zIO5 {
  padding: 0;
}
.style_checkboxContainer__2KkIk.style_large__-JHvn .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0 {
  width: 1.5rem;
  height: 1.5rem;
}
.style_checkboxContainer__2KkIk.style_checked__3zraC .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0 {
  background: #333333;
  border: initial;
}
.style_checkboxContainer__2KkIk.style_checked__3zraC .style_checkboxLabel__2DuKm {
  font-weight: 700;
}
.style_checkboxContainer__2KkIk.style_autoChecked__2-0WR .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0 {
  background: #bccfdc;
}
.style_checkboxContainer__2KkIk.style_autoChecked__2-0WR span {
  font-weight: 700;
}
.style_checkboxContainer__2KkIk.style_disabled__Vipcd .style_checkBoxCheckContainer__2zIO5 .style_checkBox__2m0u0 {
  background: #bccfdc;
}
.style_checkboxContainer__2KkIk.style_disabled__Vipcd .style_checkboxLabel__2DuKm span {
  color: #a0b5c4;
}
.style_checkboxContainer__2KkIk.style_indent__1f2lk {
  margin-left: 1.875rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_checkbox__3FsRl {
  padding: 0.25rem 0.5rem;
  display: flex;
  transition: background-color 0.3s ease;
  border-radius: 0.5rem;
}
.style_checkbox__3FsRl:first-child {
  margin-top: 0.5rem;
}
.style_checkbox__3FsRl:last-child {
  margin-bottom: 0.5rem;
}
.style_checkbox__3FsRl > div:first-of-type {
  padding: 0;
}
.style_checkbox__3FsRl > div:first-child {
  align-items: center;
}
.style_checkbox__3FsRl > div:last-of-type .style_checkboxLabel__StGWk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.3s ease;
  text-transform: capitalize;
  flex: 1 1;
}
.style_checkbox__3FsRl > div:last-of-type .style_checkboxLabel__StGWk .style_tooltip__FCIEQ {
  text-align: center;
  display: block;
  line-height: 0;
  width: 0.875rem;
}
.style_checkbox__3FsRl > div:last-of-type .style_checkboxLabel__StGWk .style_tooltip__FCIEQ svg {
  transition: fill 0.3s ease;
  width: 0.875rem;
  height: 0.875rem;
}
.style_checkbox__3FsRl > div:last-of-type .style_checkboxLabel__StGWk .style_tooltip__FCIEQ:hover svg {
  fill: #333333;
}
.style_checkbox__3FsRl .style_checkboxSearchLabel__10Fjf {
  font-weight: 400;
  line-height: 1.125rem;
}
.style_checkbox__3FsRl .style_checkboxSearchLabel__10Fjf.style_hasLabelDetails__1i6uj {
  font-weight: 700;
}
.style_checkbox__3FsRl .style_checkboxSearchLabel__10Fjf.style_checkedLabel__vdNLY {
  color: #333333;
  font-weight: 700;
}
.style_checkbox__3FsRl .style_checkboxSearchLabel__10Fjf.style_checkedLabel__vdNLY > span {
  color: #1b264f;
}
.style_checkbox__3FsRl:hover {
  background-color: #f5f6f9;
}
.style_checkbox__3FsRl:hover > div:first-of-type > div > div {
  border-color: #333333;
}
.style_checkbox__3FsRl:hover .style_checkboxLabel__StGWk {
  color: #333333;
}
.style_checkbox__3FsRl.style_disabled__2feOU, .style_checkbox__3FsRl.style_autoChecked__1CbX6 {
  pointer-events: none;
}

.style_catHeading__1OmUX {
  font-size: 0.8125rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}

.style_lazyWrapper__6oO9I:first-child {
  margin-top: 0.5rem;
}
.style_lazyWrapper__6oO9I:last-child {
  margin-bottom: 0.5rem;
}
.style_lazyWrapper__6oO9I .style_checkbox__3FsRl:first-child {
  margin-top: 0;
}
.style_lazyWrapper__6oO9I .style_checkbox__3FsRl:last-child {
  margin-bottom: 0;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_Wrapper__HXm76 {
  border-radius: 0.625rem;
  max-height: 19.5rem;
  min-height: 10rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #e2e9f1;
}

.style_ScrollContainer__14zNM {
  height: inherit;
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
  border-radius: 0.625rem;
  padding: 0 0.5rem;
  overflow-x: hidden;
}
.style_ScrollContainer__14zNM > span {
  width: 100%;
  line-height: 1;
}
.style_ScrollContainer__14zNM .style_loading__j39VI {
  padding: 2rem;
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_ScrollContainer__14zNM .style_loading__j39VI p {
  color: #6a80a7;
  font-size: 1.125rem;
  text-align: center;
}
.style_ScrollContainer__14zNM .style_checkLabel__25vdL {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
}
.style_ScrollContainer__14zNM .style_checkLabel__25vdL .style_checkTooltip__2avkm {
  text-align: center;
  display: block;
  line-height: 0;
  width: 0.875rem;
  margin-right: 0.25rem;
  margin-left: auto;
}
.style_ScrollContainer__14zNM .style_checkLabel__25vdL .style_checkTooltip__2avkm svg {
  transition: fill 0.3s ease;
  width: 0.875rem;
  height: 0.875rem;
}
.style_ScrollContainer__14zNM .style_checkLabel__25vdL .style_checkTooltip__2avkm:hover svg {
  fill: #333333;
}

.style_Option__HewZV {
  display: flex;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}
.style_Option__HewZV:first-of-type {
  margin-top: 0.5rem;
}
.style_Option__HewZV:last-of-type {
  margin-bottom: 0.5rem;
}
.style_Option__HewZV img {
  margin-right: 1rem;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
.style_Option__HewZV p {
  margin: 0;
}
.style_Option__HewZV label {
  flex: 1 1;
}
.style_Option__HewZV span {
  transition: color 0.3s ease;
}
.style_Option__HewZV:hover {
  background-color: #f5f6f9;
}
.style_Option__HewZV:hover label > div:first-child > div {
  border-color: #333333;
}
.style_Option__HewZV:hover span {
  color: #333333;
}

.style_SearchInput__193B8 {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
}
.style_SearchInput__193B8 .style_inputWrapper__2C2Zm {
  margin-bottom: 0;
}
.style_SearchInput__193B8 .style_inputWrapper__2C2Zm > div {
  border: none;
  border-bottom: 1px solid #e2e9f1 !important;
  border-radius: initial;
}
.style_SearchInput__193B8 .style_inputWrapper__2C2Zm > div input {
  padding-left: 2.5rem !important;
}
.style_SearchInput__193B8 .style_Indicator__3Fgzu {
  position: absolute;
  left: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.style_SearchInput__193B8 .style_Close__3XIqp {
  cursor: pointer;
}
.style_SearchInput__193B8 .style_Indicator__3Fgzu.style_Close__3XIqp svg {
  fill: #6a80a7;
}
.style_SearchInput__193B8 .style_Indicator__3Fgzu svg {
  fill: #c5d7e2;
  transition: fill 0.3s ease-in-out;
}
.style_SearchInput__193B8:focus-within .style_Indicator__3Fgzu svg {
  fill: #333333;
}

.style_BottomBar__1CIDI {
  background-color: #f8f9fb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  min-height: 2.5rem;
  flex-direction: row-reverse;
  flex-shrink: 0;
}
.style_BottomBar__1CIDI .style_totalSelected__EBtoj {
  font-size: 0.75rem;
  transform: translateY(0.0625rem);
}
.style_BottomBar__1CIDI .style_totalSelected__EBtoj strong {
  font-weight: 800;
}
.style_BottomBar__1CIDI .style_BottomContents__1ykfZ {
  font-size: 0.75rem;
  font-weight: 600;
  width: 100%;
}
.style_BottomBar__1CIDI .style_BottomContents__1ykfZ strong {
  font-weight: 800;
}
.style_BottomBar__1CIDI .style_SelectAll__2Z1bF {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  flex-shrink: 0;
  transform: translateY(0.0625rem);
}
.style_BottomBar__1CIDI .style_SelectAll__2Z1bF.style_fade__3Nsjp label > div > div:last-of-type {
  color: #a0b5c4;
}
.style_BottomBar__1CIDI .style_SelectAll__2Z1bF label {
  margin-left: 0;
}
.style_BottomBar__1CIDI .style_SelectAll__2Z1bF label:hover > div:first-child > div {
  border-color: #333333;
}
.style_BottomBar__1CIDI .style_SelectAll__2Z1bF label > div > div:last-of-type {
  font-size: 0.75rem;
}
.style_BottomBar__1CIDI .style_SelectAll__2Z1bF label > div {
  padding-left: 0;
}
.style_BottomBar__1CIDI .style_clearAll__D5llV {
  margin-right: auto;
}
.style_BottomBar__1CIDI .style_clearAll__D5llV span {
  color: #333333;
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.style_BottomBar__1CIDI .style_clearAll__D5llV:hover span {
  color: #0671ee;
}
.style_BottomBar__1CIDI .style_clearAll__D5llV.style_disabled__diHBo {
  pointer-events: none;
}
.style_BottomBar__1CIDI .style_clearAll__D5llV.style_disabled__diHBo span {
  color: #bccfdc;
}

.style_Wrapper__HXm76.style_reverse__2M6O6 .style_BottomBar__1CIDI {
  flex-direction: row;
  padding: 0.25rem 0.75rem 0.25rem 1rem;
}
.style_Wrapper__HXm76.style_reverse__2M6O6 .style_BottomBar__1CIDI .style_SelectAll__2Z1bF {
  flex-direction: row;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_selectAllCurrentAndFuture__1RyiA {
  background-color: #f8f9fb;
  border-radius: 0.5rem;
  height: 2.75rem;
  margin: 0.5rem 0 0.25rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.style_selectAllCurrentAndFuture__1RyiA svg {
  fill: #333333;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  transition: fill 0.3s ease-in-out;
}
.style_selectAllCurrentAndFuture__1RyiA span {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #1b264f;
  margin-top: 0.0625rem;
  transition: color 0.3s ease-in-out;
}
.style_selectAllCurrentAndFuture__1RyiA .style_toggle__1fXEp {
  margin-left: auto;
}
.style_selectAllCurrentAndFuture__1RyiA:hover:not(.style_active__1duxS) {
  background-color: #f5f6f9;
}
.style_selectAllCurrentAndFuture__1RyiA:hover:not(.style_active__1duxS) span {
  color: #333333;
}
.style_selectAllCurrentAndFuture__1RyiA.style_active__1duxS {
  background-color: #e6f9f5;
}
.style_selectAllCurrentAndFuture__1RyiA.style_active__1duxS svg {
  fill: #00b178;
}
.style_selectAllCurrentAndFuture__1RyiA.style_active__1duxS span {
  font-weight: 600;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_toggle__3FEd0 {
  background-color: #bccfdc;
  height: 1.625rem;
  width: 3rem;
  padding: 0.1875rem;
  cursor: pointer;
  border-radius: 0.8125rem;
  transition: background-color 0.3s ease-in-out;
}
.style_toggle__3FEd0:hover {
  background-color: #a0b5c4;
}
.style_toggle__3FEd0 .style_circle__DEz1U {
  background-color: #fff;
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  transition: transform 0.3s ease-in-out;
}
.style_toggle__3FEd0.style_active__izGZV {
  background-color: #00b178;
}
.style_toggle__3FEd0.style_active__izGZV .style_circle__DEz1U {
  transform: translateX(1.375rem);
}
.style_toggle__3FEd0.style_small__2YFzz {
  width: 2rem;
  height: 1.125rem;
  padding: 0.125rem;
}
.style_toggle__3FEd0.style_small__2YFzz .style_circle__DEz1U {
  height: 0.875rem;
  width: 0.875rem;
}
.style_toggle__3FEd0.style_small__2YFzz.style_active__izGZV .style_circle__DEz1U {
  transform: translateX(0.875rem);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_tag__3drh7 {
  height: 1.75rem;
  border-radius: 0.875rem;
  background-color: rgba(106, 128, 167, 0.06);
  padding: 0.375rem 0.625rem;
  margin: auto 0 0.125rem;
  display: inline-flex;
  align-items: center;
  cursor: default;
}
.style_tag__3drh7 :first-child + * {
  margin-left: 0.375rem;
}
.style_tag__3drh7 svg {
  height: 0.875rem;
  width: 0.875rem;
  fill: #d73bf6;
}
.style_tag__3drh7 img {
  height: 0.875rem;
  width: 0.875rem;
  fill: #d73bf6;
}
.style_tag__3drh7 svg + p,
.style_tag__3drh7 img + p {
  margin-left: 0.375rem;
}
.style_tag__3drh7 p {
  color: #1b264f;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.125rem;
}
.style_tag__3drh7 p strong {
  font-weight: 800;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_emptyButton__1-U5S {
  border: 1px dashed #bccfdc;
  border-radius: 0.5rem;
  height: 2.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  margin: 0 auto;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
}
.style_emptyButton__1-U5S span {
  color: #a0b5c4;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  transition: color 0.3s ease-in-out;
  margin: 0 auto;
}
.style_emptyButton__1-U5S svg {
  fill: #a0b5c4;
  margin-right: 0.375rem;
  transition: fill 0.3s ease-in-out;
}
.style_emptyButton__1-U5S:hover {
  border-color: #333333;
}
.style_emptyButton__1-U5S:hover span {
  color: #333333;
}
.style_emptyButton__1-U5S:hover svg {
  fill: #333333;
}
.style_emptyButton__1-U5S.style_justIcon__2XHQc {
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  padding: unset;
  justify-content: center;
  margin: 0;
  border-color: #6a80a7;
}
.style_emptyButton__1-U5S.style_justIcon__2XHQc svg {
  margin-right: unset;
  fill: #6a80a7;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_toggleFilter__3E8kn {
  margin-right: 0.5rem;
}
.style_toggleFilter__3E8kn > div {
  padding: 0rem !important;
}
.style_toggleFilter__3E8kn .style_icon__3WB8C svg {
  height: 0.4rem !important;
}
.style_toggleFilter__3E8kn:first-child {
  margin-left: 0;
}
.style_toggleFilter__3E8kn.style_layoutFilter__1teJT {
  margin-left: auto;
}
.style_toggleFilter__3E8kn .style_gridOption__cJoED svg > path {
  transition: stroke 0.3s ease-in-out;
}
.style_toggleFilter__3E8kn .style_gridOption__cJoED:hover svg > path {
  stroke: #333333;
}
.style_toggleFilter__3E8kn .style_gridOption__cJoED.style_active__2Anfc svg > path {
  stroke: #1b264f;
}

.style_layoutSettings__14MDS .style_settingsItem__1-85p {
  width: 260px;
}
.style_layoutSettings__14MDS .style_settingsTagFilter__2-aoh {
  margin-right: auto;
  position: relative;
  z-index: 51;
}
.style_layoutSettings__14MDS .style_calendarItem__2emgV {
  font-size: 0.85rem;
  font-weight: 600;
  margin-right: 1.5rem;
  width: auto;
  display: inherit;
}
.style_layoutSettings__14MDS .style_calendarItem__2emgV p {
  font-size: 12px;
  font-weight: 800;
}
.style_layoutSettings__14MDS .style_calendarItem__2emgV svg {
  margin-right: 0.65rem;
  margin-top: 0.65rem;
  display: block;
  float: left;
}

.style_overUnderSelect__1DaoA {
  z-index: 3;
  width: 14rem;
}

.style_platform__2EH2g {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: left;
}
.style_platform__2EH2g .style_platformIcon__2Zvt0 {
  width: auto;
  height: auto;
}
.style_platform__2EH2g p {
  white-space: nowrap;
  font-size: 0.875rem;
}

.style_platformsDropdown__LznZg {
  max-height: 18rem;
  overflow-y: auto;
}

.style_datePicker__3bcOM {
  margin-left: 0 !important;
}

.style_tabContainer__es0Jl {
  border-bottom: none !important;
  border-radius: 0.75rem 0.75rem 0 0 !important;
  transform: translateY(1px);
  overflow: hidden;
}
.style_tabContainer__es0Jl > ul > li:last-child {
  border-right: 0 !important;
}
.style_tabContainer__es0Jl > ul > li > span {
  border-radius: 0 !important;
  cursor: pointer;
}
.style_tabContainer__es0Jl > ul > li > span::before {
  content: none !important;
}
.style_tabContainer__es0Jl > ul > li > span:hover {
  background-color: #242423 !important;
}
.style_tabContainer__es0Jl > ul > li > span:hover svg path {
  fill: #fff !important;
}
.style_tabContainer__es0Jl > ul > li > span:hover h3 {
  color: #fff !important;
}
.style_tabContainer__es0Jl > ul > li > span > div {
  padding: 1.25rem 1.5rem;
  min-width: unset;
}
.style_tabContainer__es0Jl > ul > li > span > div > div:first-child {
  margin-right: 0.5rem;
  width: 0.875rem;
  height: unset;
}
.style_tabContainer__es0Jl > ul > li > span > div > div:first-child svg path {
  fill: #242423;
}
.style_tabContainer__es0Jl > ul > li > span > div > div:last-child h3 {
  margin-bottom: 0;
}

.style_tabsSectionContainer__1o7TR {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.style_tabsSectionContainer__1o7TR .style_settingsContainer__1AMiF {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.style_tabsSectionContainer__1o7TR .style_settingsContainer__1AMiF > div {
  z-index: 5;
}

.style_mainTableFilter__pomdz {
  display: flex;
  width: 100%;
  border: 1px solid #e2e9f1;
  padding: 1rem 1.25rem;
  border-bottom: none;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-top-right-radius: 0.75rem;
  align-items: center;
}
.style_mainTableFilter__pomdz .style_filterIcon__2xd_I {
  margin-right: 0.25rem;
  color: #242423;
  font-size: 0.875rem;
  font-weight: 800;
  display: flex;
  align-items: center;
}
.style_mainTableFilter__pomdz .style_filterIcon__2xd_I svg path {
  fill: #242423;
}
.style_mainTableFilter__pomdz .style_filterDropdown__5XXmK {
  min-height: 2.5rem;
  z-index: 4;
}
.style_mainTableFilter__pomdz .style_filterDropdown__5XXmK > ul {
  z-index: 3;
  min-width: 15rem;
}
.style_mainTableFilter__pomdz .style_clearFilter__2jhlR {
  padding: 0 0.75rem;
  height: 2.25rem;
  color: #bccfdc;
  font-size: 0.875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  margin-right: auto;
  cursor: pointer;
  color: #242423;
}
.style_mainTableFilter__pomdz .style_clearFilter__2jhlR svg path {
  fill: #242423;
}
.style_mainTableFilter__pomdz .style_clearFilter__2jhlR svg {
  width: 0.625rem;
  height: 0.625rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.styleMobile_layoutSettings__yj9nI {
  margin-bottom: 1.25rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}
.styleMobile_layoutSettings__yj9nI .styleMobile_settingsItem__ZkEoE {
  margin-right: 0.5rem;
  width: 16.25rem;
}
.styleMobile_layoutSettings__yj9nI .styleMobile_sectionWrapper__3hRci {
  display: flex;
  width: 100%;
}
.styleMobile_layoutSettings__yj9nI .styleMobile_toggleView__1kGBn {
  justify-content: space-between;
  width: 100%;
}
.styleMobile_layoutSettings__yj9nI .styleMobile_toggleView__1kGBn .styleMobile_toggleViewItem__ZyT7j {
  flex: 1 1;
}
.styleMobile_layoutSettings__yj9nI .styleMobile_monthSelector__3Aii1 {
  flex: 1 1;
}
.styleMobile_layoutSettings__yj9nI .styleMobile_monthSelector__3Aii1 > div {
  width: 100% !important;
  z-index: 3;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_tagFilter__3rfp2 {
  position: unset !important;
}

.style_filterButton__2co6M {
  font-size: 0.8125rem;
  line-height: 1.5rem;
}
.style_filterButton__2co6M.style_isOpen__x9DGk {
  background-color: rgba(82, 46, 255, 0.1) !important;
  color: #333333;
}
.style_filterButton__2co6M.style_isOpen__x9DGk svg {
  fill: #333333;
}
.style_filterButton__2co6M span:last-of-type {
  margin-left: 0.375rem;
}

.style_tagSearchWrapper__Yl6Nm {
  width: 18.5rem;
  position: absolute;
  top: 2.625rem;
  left: 0;
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  border: 1px solid #e2e9f1;
  border-radius: 0.625rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.08);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.style_tagSearchWrapper__Yl6Nm.style_isOpen__x9DGk {
  visibility: visible;
  opacity: 1;
}
.style_tagSearchWrapper__Yl6Nm .style_contentExit__HVC1Y {
  opacity: 1;
}
.style_tagSearchWrapper__Yl6Nm .style_contentEnter__3c35K,
.style_tagSearchWrapper__Yl6Nm .style_contentExit__HVC1Y.style_contentExitActive__VeJxw {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.style_tagSearchWrapper__Yl6Nm .style_contentEnter__3c35K.style_contentEnterActive__2aAtJ {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.style_containsSelect__29_Od {
  width: 17.25rem !important;
  margin-left: 0.5625rem;
  margin-top: 0.625rem;
  height: 2.25rem !important;
  z-index: 3;
}

.style_tagSearch__338q6 {
  width: 18.5rem;
  min-height: 15rem;
  border: none;
}
.style_tagSearch__338q6 .style_tagsTitle__2fYBo {
  margin: 0.75rem 0 0.5rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: 700;
  display: block;
}
.style_tagSearch__338q6 .style_editTag__2LMMH {
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  z-index: 2;
  right: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.style_tagSearch__338q6 .style_editTag__2LMMH svg {
  width: 0.875rem;
  height: 0.875rem;
}
.style_tagSearch__338q6 .style_editTag__2LMMH svg > g {
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
}
.style_tagSearch__338q6 .style_editTag__2LMMH:hover svg > g {
  fill: #333333;
}
.style_tagSearch__338q6 .style_emptySearch__3otXt {
  font-size: 0.875rem;
  padding: 1rem 1.25rem;
  text-transform: none;
  flex-grow: 1;
}
.style_tagSearch__338q6 > div:first-child {
  width: 17.25rem;
  margin-left: 0.5625rem;
  margin-top: 0.375rem;
}
.style_tagSearch__338q6 > div:first-child > div > div {
  border: 1px solid #e2e9f1;
  border-radius: 0.5rem;
}
.style_tagSearch__338q6 > div:first-child > div > div input {
  height: 2.125rem !important;
}
.style_tagSearch__338q6 .style_searchFooter__1_BTM {
  padding: 0.375rem 0;
  border-radius: 0 0 0.625rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16.375rem;
}
.style_tagSearch__338q6 .style_searchFooter__1_BTM .style_addNew__24Sad {
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.5rem;
  cursor: pointer;
  color: #333333;
  transition: color 0.3s ease-in-out;
}
.style_tagSearch__338q6 .style_searchFooter__1_BTM .style_addNew__24Sad:hover {
  color: #0671ee;
}
.style_tagSearch__338q6 .style_searchFooter__1_BTM button {
  height: 2rem;
}

.style_tagCheckbox__3nihB {
  padding: 0 0.5rem !important;
  margin: 0.125rem 0;
  position: relative;
}
.style_tagCheckbox__3nihB .style_accountTag__1m9N1 {
  margin-right: 0;
}
.style_tagCheckbox__3nihB .style_accountTag__1m9N1 > span {
  cursor: pointer;
}
.style_tagCheckbox__3nihB:hover .style_accountTag__1m9N1 > div:last-child {
  opacity: 1;
}
.style_tagCheckbox__3nihB:hover .style_editTag__2LMMH {
  opacity: 1;
}
.style_tagCheckbox__3nihB > div > div:first-child > div {
  margin-top: 0.25rem;
}
.style_tagCheckbox__3nihB > div > div:last-child {
  margin-left: 0.375rem !important;
}

.style_filterDropdown__1j2fU {
  min-height: 2.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_tagsApplied__3xkM4 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.style_tagsApplied__3xkM4 .style_accountTag__3qZEi {
  height: 1.5rem;
  padding: 0.25rem 0.75rem;
  margin-right: 0;
}
.style_tagsApplied__3xkM4 .style_accountTag__3qZEi > div {
  opacity: 1;
  height: 1.5rem;
}
.style_tagsApplied__3xkM4 .style_accountTag__3qZEi > span {
  max-width: unset;
}

.style_clearAll__2Bnpj {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.style_clearAll__2Bnpj span {
  color: #1b264f;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.style_clearAll__2Bnpj:hover span {
  color: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_search__3u_7O input {
  background-color: #fff;
  border-color: #e2e9f1;
}

@media (max-width: 48rem) {
  .style_search__3u_7O {
    flex: 1 1;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_options__zUk7O {
  position: relative;
  z-index: 1;
}
.style_options__zUk7O .style_menu__15Hld {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e9f1;
}
.style_options__zUk7O .style_menu__15Hld svg {
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
}
.style_options__zUk7O .style_menu__15Hld:hover, .style_options__zUk7O .style_menu__15Hld.style_open__2He2N {
  background-color: rgba(36, 36, 35, 0.05);
  border-color: #242423;
}
.style_options__zUk7O .style_menu__15Hld:hover svg, .style_options__zUk7O .style_menu__15Hld.style_open__2He2N svg {
  fill: #242423;
}
.style_options__zUk7O .style_optionsDropdown__3F6Zo {
  min-width: 8.5rem;
  bottom: -0.25rem;
}
.style_options__zUk7O .style_option__2nPLS .style_menuLabel__210LP {
  display: flex;
  align-items: center;
}
.style_options__zUk7O .style_option__2nPLS .style_menuLabel__210LP svg {
  width: 1rem;
  height: auto;
  margin-right: 0.625rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_exportSettingsRoot__wW91- .style_exportSettingsPaper__3negi {
  padding: 2.5rem 2.5rem 2.375rem;
  overflow-y: visible;
}
@media (max-height: 40rem) {
  .style_exportSettingsRoot__wW91- .style_exportSettingsPaper__3negi {
    overflow-y: auto;
  }
}

.style_csvLink__3k8ce {
  text-decoration: none;
  color: #1b264f;
}
.style_csvLink__3k8ce .style_menuItem__1N5KE {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.style_exportSettings__2OAtF .style_heading__1wZ41 {
  text-align: center;
  max-width: 34.25rem;
  margin: 0 auto 1.25rem;
}
.style_exportSettings__2OAtF .style_heading__1wZ41 h3 {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #1b264f;
  margin-bottom: 0.25rem;
}
.style_exportSettings__2OAtF .style_heading__1wZ41 p {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #6a80a7;
}
.style_exportSettings__2OAtF h4 {
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
  font-weight: 800;
}
.style_exportSettings__2OAtF .style_selectText__2S82O {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 800;
}
.style_exportSettings__2OAtF .style_buttons__SCTuC {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.style_exportSettings__2OAtF .style_buttons__SCTuC a {
  text-decoration: none;
}
.style_exportSettings__2OAtF .style_buttons__SCTuC button {
  min-width: 9rem;
}
.style_exportSettings__2OAtF .style_buttons__SCTuC button:first-child {
  margin-right: 0.5rem;
}
.style_exportSettings__2OAtF .style_selectionContainer__34nl_ {
  border: 1px solid #e2e9f1;
  border-radius: 0.625rem;
  position: relative;
}
.style_exportSettings__2OAtF .style_selectionContainer__34nl_ .style_checkboxSearch__2q8d6 {
  height: 17.75rem;
  border-radius: 0 0 0.625rem 0.625rem;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_smallTabs__1qxAz {
  position: relative;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ {
  position: relative;
  margin: 0;
  padding: 0.25rem 0.75rem 0.25rem 0.625rem;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ > li {
  transition: background-color 0.3s ease-in-out;
  position: relative;
  padding: 0.25rem 0.5rem;
  min-height: 2rem;
  min-width: 2rem;
  margin: 0 0.125rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ > li svg {
  height: 0.875rem;
  width: auto;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ > li > span {
  color: #1b264f;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  padding: 0;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ > li > span img {
  margin-right: 0.625rem;
  height: 1rem;
  width: auto;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ > li::after {
  content: "";
  height: 3px;
  width: calc(100% + 0.5rem);
  background-color: transparent;
  position: absolute;
  bottom: -0.25rem;
  left: -0.25rem;
  transition: background-color 0.3s ease-in-out;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ .style_activeTab__2R23c::after {
  background-color: #333333;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ .style_inactiveTab__sf6wP {
  cursor: pointer;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ .style_inactiveTab__sf6wP:not([disabled]):hover {
  background-color: rgba(51, 51, 51, 0.15);
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ .style_inactiveTab__sf6wP:not([disabled]):hover > span {
  color: #333333;
}
.style_smallTabs__1qxAz .style_tabNavigation__3nCP_ .style_inactiveTab__sf6wP:not([disabled]):hover h3 {
  color: #333333;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_tabNavigation__3s4ZU {
  display: flex;
  padding: 0;
}

.style_tabNavigation__3s4ZU li {
  display: flex;
  align-items: center;
}

.style_tabNavigation__3s4ZU .style_tabHeader__1PEBB {
  vertical-align: initial;
  padding: 0.125rem 0.875rem 1rem;
}

.style_shouldScroll__217FI li {
  margin: 0 !important;
}
.style_shouldScroll__217FI .style_tabHeader__1PEBB {
  width: -webkit-max-content;
  width: max-content;
}

.style_tabNavigation__3s4ZU .style_counterWrapper__B7RcV {
  font-size: 0.625rem;
  color: #fff;
  font-weight: 900;
  width: 1.875rem;
  height: 1.25rem;
  background-color: #fb1427;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  float: right;
  justify-content: center;
  margin-left: 0.5rem;
}

.style_tabNavigation__3s4ZU .style_counterWrapperDisabled__2dDyL {
  background-color: #bccfdc;
}

.style_activeTab__1JGxz {
  cursor: default;
  font-size: 11px;
  color: #1b264f;
}

.style_activeTab__1JGxz span {
  color: #1b264f;
  display: none;
}

.style_inactiveTab__1OZeP span {
  cursor: pointer;
  color: #6a80a7;
  font-size: 11px;
}

.style_inactiveTab__1OZeP[disabled] span {
  cursor: not-allowed;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_toggleMulti__15dtU {
  display: flex;
  align-items: center;
  background-color: rgba(226, 233, 241, 0.35);
  border-radius: 0.625rem;
  height: 2.5rem;
  padding: 0.25rem;
  position: relative;
}

.style_subFilter__38dtc {
  display: flex;
  align-items: center;
}

.style_arrowDown__3iS8t {
  margin-left: 0.375rem;
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #a0b5c4;
  transition: background-color 0.3s ease-in-out;
}
.style_arrowDown__3iS8t svg {
  width: 0.5rem !important;
  height: auto;
  transition: fill 0.3s ease-in-out;
}
.style_arrowDown__3iS8t.style_active__30OYk svg {
  pointer-events: none;
  fill: #1b264f;
  width: 0.5rem !important;
}
.style_arrowDown__3iS8t.style_active__30OYk:hover, .style_arrowDown__3iS8t.style_active__30OYk.style_open__2fGPU {
  background-color: #333333;
}
.style_arrowDown__3iS8t.style_active__30OYk:hover svg, .style_arrowDown__3iS8t.style_active__30OYk.style_open__2fGPU svg {
  fill: #fff;
}

.style_switch__1xBhy {
  position: absolute;
  min-width: 2rem;
  height: 2rem;
  top: 0.25rem;
  left: 0.25rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 3px 3px 0 rgba(51, 57, 68, 0.05);
  transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
}

.style_toggleOption__h9j1a {
  padding: 0.25rem 0.625rem;
  color: #6a80a7;
  font-size: 0.8125rem;
  line-height: 1rem;
  height: 2rem;
  font-weight: 700;
  min-width: 2rem;
  display: flex;
  border-radius: 0.5rem;
  margin-left: 0.25rem;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.style_toggleOption__h9j1a:nth-child(2) {
  margin-left: 0;
}
.style_toggleOption__h9j1a.style_hasSubFilters__cM7BE {
  padding: 0.25rem 0.5rem;
}
.style_toggleOption__h9j1a .style_labelText__2mDeO {
  transform: translateY(0.0625rem);
}
.style_toggleOption__h9j1a .style_icon__3IxcT {
  line-height: 0;
}
.style_toggleOption__h9j1a .style_icon__3IxcT svg {
  height: 0.875rem;
  width: auto;
}
.style_toggleOption__h9j1a .style_icon__3IxcT + .style_labelText__2mDeO {
  margin-left: 0.25rem;
}
.style_toggleOption__h9j1a:hover:not(.style_active__30OYk):not(.style_disabled__E6GhC) {
  color: #333333;
  background-color: rgba(51, 51, 51, 0.1);
}
.style_toggleOption__h9j1a.style_active__30OYk:not(.style_disabled__E6GhC) {
  color: #1b264f;
}
.style_toggleOption__h9j1a.style_disabled__E6GhC {
  color: #a0b5c4;
}
.style_toggleOption__h9j1a.style_disabled__E6GhC svg {
  fill: #a0b5c4;
}
.style_toggleOption__h9j1a.style_disabled__E6GhC .style_subFilter__38dtc .style_arrowDown__3iS8t {
  pointer-events: none;
  fill: #a0b5c4;
}

.style_dropdown__3h4p7 {
  position: absolute;
  left: 0;
  top: calc(100% + 0.25rem);
  background-color: #fff;
  min-width: 15rem;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.style_dropdown__3h4p7 .style_filterCheckboxes__1iNUp {
  max-height: 16rem;
}
.style_dropdown__3h4p7 .style_subFilterTitle__2ovSc {
  margin: 1rem 0.4375rem 0.5rem;
  font-size: 0.8125rem;
}
.style_dropdown__3h4p7.style_open__2fGPU {
  visibility: visible;
  opacity: 1;
}

.style_clearSelected__3_rLN {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background-color: #a0b5c4;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_clearSelected__3_rLN svg {
  width: 0.375rem !important;
  height: 0.375rem !important;
  fill: #fff;
}
.style_clearSelected__3_rLN.style_active__30OYk {
  background-color: #333333;
}
.style_clearSelected__3_rLN.style_active__30OYk:hover {
  background-color: #0671ee;
}

.style_toggleBottom__QVMwh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0;
}
.style_toggleBottom__QVMwh .style_clear__3vT1i {
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 0.75rem;
  color: #333333;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.style_toggleBottom__QVMwh .style_clear__3vT1i:hover:not(.style_disabled__E6GhC) {
  color: #0671ee;
}
.style_toggleBottom__QVMwh .style_clear__3vT1i.style_disabled__E6GhC {
  color: #a0b5c4;
}
.style_toggleBottom__QVMwh button {
  height: 1.875rem;
  padding: 0 1rem;
  font-size: 0.75rem;
}
.style_tabContainer__3OfN5 {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.style_open__2Wfbq {
    display: flex;
    flex: 1 1;
    flex-grow: 1;
    max-width: 100%;
    flex-direction: column;
}

.style_closed__2-u3A {
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    display: none;
    flex-direction: column;
}

.style_closedLeft__Kj46P {
    transform: translateX(100%);
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
}

.style_closedRight__1687_ {
    transform: translateX(-100%);
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
}

.style_closed__2-u3A > div,
.style_closedLeft__Kj46P > div,
.style_closedRight__1687_ > div {
    min-width: 0;
    max-width: 0;
    padding-left: 0;
    padding-right: 0;
}

.style_split__3Qarm {
    display: flex;
    flex: 1 1;
    max-width: 100%;
}

/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_dateDropdownContainer__1SePZ {
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
}
.style_dateDropdownContainer__1SePZ .style_nextMonthCta__2KDjq {
  display: block;
  border-bottom: 0.0625rem solid #e2e9f1;
  margin-bottom: 0.5rem;
}
.style_dateDropdownContainer__1SePZ .style_set__2tgs2 {
  display: flex;
  align-items: center;
  position: relative;
}
.style_dateDropdownContainer__1SePZ .style_set__2tgs2 svg {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.75rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.sharedStyles_container__3K9VO {
  position: relative;
  z-index: 100;
}

@media only all and (max-width: 48rem) {
  .sharedStyles_calendarContainer__1YxwR .sharedStyles_monthPicker__2LcFg {
    left: 0 !important;
  }
  .sharedStyles_calendarContainer__1YxwR .sharedStyles_dayPickerWrapper__1rE3Q {
    border: none !important;
  }
  .sharedStyles_calendarContainer__1YxwR .sharedStyles_dayPickerWrapper__1rE3Q > div:first-child {
    display: none;
  }
}
.sharedStyles_mobileWidth__2P8WB {
  width: 11.25rem;
}

.sharedStyles_desktopWidth__2kMjH {
  width: 17.125rem;
}

.sharedStyles_zIndex30__2Hl2D {
  z-index: 30;
}

.sharedStyles_calendarContainer__1YxwR {
  position: relative;
}
.sharedStyles_calendarContainer__1YxwR hr {
  border: 0;
  background-color: #e2e9f1;
  width: 0.0625rem;
  margin: 0;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_dayPickerWrapper__1rE3Q {
  display: flex;
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0s ease 0.3s;
  position: absolute;
  z-index: 100;
  right: 0;
  top: 2.625rem;
  border: 1px #e2e9f1 solid;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 57, 68, 0.05);
  border-radius: 0.625rem;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presets__Slt4Y {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2.75rem;
  border-radius: 0 0.5rem 0.5rem 0;
  border: 1px solid #e2e9f1;
  border-left-color: transparent;
  transition: border 0.3s ease, background-color 0.3s ease;
  margin-left: -0.125rem;
  position: relative;
  z-index: 1;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presets__Slt4Y:hover, .sharedStyles_calendarContainer__1YxwR .sharedStyles_presets__Slt4Y.sharedStyles_open__1dR4z {
  border-color: #333333;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presets__Slt4Y:hover svg, .sharedStyles_calendarContainer__1YxwR .sharedStyles_presets__Slt4Y.sharedStyles_open__1dR4z svg {
  fill: #333333;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presets__Slt4Y.sharedStyles_open__1dR4z {
  background-color: #f3f4ff;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presetSelector__SVz7U {
  position: absolute;
  right: 0;
  bottom: -0.125rem;
  width: 16.75rem;
  z-index: 1;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presetSelector__SVz7U .sharedStyles_customDateRange__3RxZs {
  display: block;
  border-top: 0.0625rem solid #e2e9f1;
  margin-top: 0.5rem;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presetSelector__SVz7U .sharedStyles_customDateRange__3RxZs .sharedStyles_customLabel__1a6eB {
  display: flex;
  align-items: center;
  position: relative;
}
.sharedStyles_calendarContainer__1YxwR .sharedStyles_presetSelector__SVz7U .sharedStyles_customDateRange__3RxZs .sharedStyles_customLabel__1a6eB svg {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.75rem;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ::before {
  background-color: transparent;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_dayPickerWrapper__1rE3Q {
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.3s ease 0.1s, transform 0s ease;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O,
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O:hover {
  border: 1px solid #333333;
  color: #333333;
  background-color: #f3f4ff !important;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O .sharedStyles_calendarIcon___zPhH,
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O svg,
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O:hover .sharedStyles_calendarIcon___zPhH,
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O:hover svg {
  fill: #333333;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O svg:not(.sharedStyles_calendarIcon___zPhH),
.sharedStyles_calendarContainer__1YxwR.sharedStyles_calendarOpen__18nVJ .sharedStyles_calendarButton__yGH5O:hover svg:not(.sharedStyles_calendarIcon___zPhH) {
  transform: scaleY(-1);
  fill: #333333;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_compareCalendar__3jZ6f {
  margin-left: 0.5rem;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_compareCalendar__3jZ6f .sharedStyles_calendarButton__yGH5O {
  width: auto;
  min-width: 13rem;
}
.sharedStyles_calendarContainer__1YxwR.sharedStyles_compareCalendar__3jZ6f .sharedStyles_presetSelector__SVz7U {
  min-width: 13rem;
  width: 100%;
}

.sharedStyles_rangePickerAlignLeft__3tCcT {
  left: 0;
  right: auto !important;
}

.sharedStyles_additionalOptions__1cyCQ {
  border-bottom: 1px solid #e2e9f1;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.sharedStyles_additionalOptions__1cyCQ li:first-child {
  margin-top: 0;
  margin-bottom: 0;
}
.sharedStyles_additionalOptions__1cyCQ li svg {
  width: 14px;
  height: auto;
  margin-right: 0.5rem;
  margin-bottom: -2px;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_dateSelector__3-CQT {
  width: 15.5rem;
  border-radius: 0 0.625rem 0.625rem 0;
  background-color: #fff;
  border-left: 1px #e2e9f1 solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.style_dateSelector__3-CQT p {
  font-size: 0.875rem;
  font-weight: 800;
}
.style_dateSelector__3-CQT .style_dateSelectorContent__2y3uO {
  padding: 1.5rem 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  min-height: 12rem;
}
.style_dateSelector__3-CQT .style_selected__3X-rV {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: auto;
}
.style_dateSelector__3-CQT .style_selected__3X-rV svg {
  margin-right: 0.5rem;
  fill: #6a80a7;
  width: 0.75rem;
  height: 0.75rem;
}
.style_dateSelector__3-CQT .style_selected__3X-rV span {
  font-size: 0.75rem;
}

.style_inputs__uS8WL {
  margin-top: 0.875rem;
  border-radius: 0.5rem;
}
.style_inputs__uS8WL .style_dateInput__1dJXR {
  background: #f8f9fb;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.style_inputs__uS8WL .style_dateInput__1dJXR.style_inputError__1NSZH {
  margin-bottom: 0;
}
.style_inputs__uS8WL .style_dateInput__1dJXR.style_inputError__1NSZH input {
  border: 1px solid #fb1427;
}
.style_inputs__uS8WL .style_dateInput__1dJXR.style_inputError__1NSZH input:focus {
  outline: none;
  border: 1px solid #fb1427;
}
.style_inputs__uS8WL .style_dateInput__1dJXR input {
  border-radius: 0.5rem;
  border: 1px solid #e2e9f1;
  width: 7.25rem;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  color: #333333;
  font-family: "Nunito Sans", sans-serif;
}
.style_inputs__uS8WL .style_dateInput__1dJXR input:focus {
  outline: none;
  border: 1px solid #333333;
}
.style_inputs__uS8WL .style_dateInput__1dJXR span {
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0 0.625rem 0 0.75rem;
}
.style_inputs__uS8WL .style_errorMessage__2VBDh {
  color: #fb1427;
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: block;
  text-align: right;
  margin: 0.125rem 0 0.5rem;
}

.style_optionsContainer__xiddn {
  width: 100%;
  padding: 0.875rem 1.25rem;
  border-top: 1px solid #e2e9f1;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.style_optionsContainer__xiddn button {
  min-width: 4.625rem;
  font-size: 0.875rem;
  flex-grow: 2;
  flex-basis: 0;
}
.style_optionsContainer__xiddn button:first-of-type {
  margin-right: 0.5rem;
}

@media only all and (max-width: 48rem) {
  .style_dateSelector__3-CQT {
    border: 1px #e2e9f1 solid;
    border-radius: 0.625rem;
    min-width: 15.5rem;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_dateSelector__ypNEO {
  width: 15.5rem;
  border-radius: 0 0.625rem 0.625rem 0;
  background-color: #fff;
  border-left: 1px #e2e9f1 solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.style_dateSelector__ypNEO p {
  font-size: 0.875rem;
  font-weight: 800;
}
.style_dateSelector__ypNEO .style_dateSelectorContent__37j7e {
  padding: 1.5rem 1.25rem 1rem;
  display: flex;
  flex-direction: column;
}
.style_dateSelector__ypNEO .style_dateCompareSelector__33fJt {
  padding-top: 0;
}
.style_dateSelector__ypNEO .style_selected__22vnA {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: auto;
}
.style_dateSelector__ypNEO .style_selected__22vnA svg {
  margin-right: 0.5rem;
  fill: #6a80a7;
  width: 0.75rem;
  height: 0.75rem;
}
.style_dateSelector__ypNEO .style_selected__22vnA span {
  font-size: 0.75rem;
}

.style_inputs__3J2vM {
  margin-top: 0.875rem;
  border-radius: 0.5rem;
}
.style_inputs__3J2vM .style_dateInput__1rlOB {
  background: #f8f9fb;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.style_inputs__3J2vM .style_dateInput__1rlOB.style_inputError__3j0vN {
  margin-bottom: 0;
}
.style_inputs__3J2vM .style_dateInput__1rlOB.style_inputError__3j0vN input {
  border: 1px solid #fb1427;
}
.style_inputs__3J2vM .style_dateInput__1rlOB.style_inputError__3j0vN input:focus {
  outline: none;
  border: 1px solid #fb1427;
}
.style_inputs__3J2vM .style_dateInput__1rlOB input {
  border-radius: 0.5rem;
  border: 1px solid #e2e9f1;
  width: 7.25rem;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  color: #333333;
  font-family: "Nunito Sans", sans-serif;
}
.style_inputs__3J2vM .style_dateInput__1rlOB input:focus {
  outline: none;
  border-color: #333333;
}
.style_inputs__3J2vM .style_dateInput__1rlOB span {
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0 0.625rem 0 0.75rem;
}
.style_inputs__3J2vM .style_errorMessage__1p8-s {
  color: #fb1427;
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: block;
  text-align: right;
  margin: 0.125rem 0 0.5rem;
}

.style_optionsContainer__2ktO1 {
  width: 100%;
  padding: 0.875rem 1.25rem;
  border-top: 1px solid #e2e9f1;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.style_optionsContainer__2ktO1 button {
  min-width: 4.625rem;
  font-size: 0.875rem;
  flex-grow: 2;
  flex-basis: 0;
}
.style_optionsContainer__2ktO1 button:first-of-type {
  margin-right: 0.5rem;
}

@media only all and (max-width: 48rem) {
  .style_dateSelector__ypNEO {
    border: 1px #e2e9f1 solid;
    border-radius: 0.625rem;
    min-width: 15.5rem;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_containerTabs__2kkgA {
  border-radius: 0.75rem;
  position: relative;
  border: 1px solid #e2e9f1;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp {
  position: relative;
  background-color: #fff;
  border-radius: 0.75rem 0.75rem 0 0;
  margin: 0;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp > li > span {
  padding: 0;
  position: relative;
  border-radius: 0.625rem;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp > li > span > * {
  position: relative;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp > li > span h3 {
  transition: color 0.3s ease;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_alignRight__1MLyY {
  margin-left: auto;
  border-left: 1px solid #e2e9f1;
  border-top-right-radius: 0.75rem;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_activeTab__qpAh1 {
  border-right: 1px solid #e2e9f1;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #fff;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_activeTab__qpAh1:first-child {
  border-top-left-radius: 0.75rem;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_inactiveTab__39yDU {
  background-color: #fbfcfc;
  border-right: 1px solid #e2e9f1;
  border-bottom: 1px solid #e2e9f1;
  cursor: pointer;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_inactiveTab__39yDU:first-child {
  border-top-left-radius: 0.75rem;
  overflow: hidden;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_inactiveTab__39yDU > span {
  transition: background-color 0.3s ease;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_inactiveTab__39yDU > span::before {
  content: "";
  position: absolute;
  background-color: transparent;
  width: calc(100% - 0.75rem);
  height: calc(100% - 0.75rem);
  top: 0.375rem;
  left: 0.375rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_inactiveTab__39yDU:not([disabled]) > span:hover {
  background-color: #fff;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_inactiveTab__39yDU:not([disabled]) > span:hover::before {
  background-color: #f5f6f9;
}
.style_containerTabs__2kkgA .style_tabNavigation__2yMBp .style_inactiveTab__39yDU:not([disabled]) > span:hover h3 {
  color: #333333;
}
.style_containerTabs__2kkgA.style_fullWidth__2PO6F {
  width: 100%;
}
.style_containerTabs__2kkgA.style_fullWidth__2PO6F .style_tabNavigation__2yMBp li {
  flex-basis: 0;
  flex-grow: 1;
}
.style_containerTabs__2kkgA.style_fullWidth__2PO6F .style_tabNavigation__2yMBp li > span {
  width: 100%;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_tab__3qYgz {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  min-width: 14rem;
  padding: 1.375rem 1.5rem;
}
.style_tab__3qYgz .style_text__qQpKg h3 {
  font-weight: 800;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}
.style_tab__3qYgz .style_text__qQpKg span {
  color: #6a80a7;
  font-size: 0.75rem;
}
.style_tab__3qYgz .style_infoTip__3Ar0W {
  align-self: flex-start;
  margin-left: auto;
  padding-left: 0.5rem;
}
.style_tab__3qYgz.style_disabled__2LKln .style_text__qQpKg h3,
.style_tab__3qYgz.style_disabled__2LKln .style_text__qQpKg h3 + span {
  color: #bccfdc;
}

.style_hasIconColor__9M_68 .style_icon__2f6v7 svg,
.style_hasIconColor__9M_68 .style_icon__2f6v7 path {
  fill: #fff;
}

.style_icon__2f6v7 {
  width: 2rem;
  height: 2rem;
  border-radius: 0.625rem;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_icon__2f6v7 svg {
  width: 0.875rem;
  height: 0.875rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_sortToggle__2eYgs {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: end;
  flex: 1 1;
}
.style_sortToggle__2eYgs .style_sortToggleTitle__3Btq- {
  color: #242423;
  font-size: 0.875rem;
  font-weight: 800;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_zeroStateContainer__3206T {
  width: 100%;
  border-radius: 0.75rem;
  text-align: center;
  padding: 2rem 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.style_zeroStateContainer__3206T.style_hasBorder__2LAav {
  border: 1px solid #e2e9f1;
  background-color: #fff;
}
.style_zeroStateContainer__3206T img {
  height: 3.5rem;
  width: auto;
}
.style_zeroStateContainer__3206T > svg {
  height: 3.5rem;
  width: 3.5rem;
  fill: #e2e9f1;
}
.style_zeroStateContainer__3206T h4 {
  color: #1b264f;
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 2rem;
  text-align: center;
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.style_zeroStateContainer__3206T p {
  color: #6a80a7;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  max-width: 30rem;
}
.style_zeroStateContainer__3206T p a {
  font-size: inherit;
  color: #333333;
  transition: color 0.3s ease-in-out;
}
.style_zeroStateContainer__3206T p a:hover {
  color: #0671ee;
}
.style_zeroStateContainer__3206T button {
  margin: 1.125rem auto 1rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_selectionArrow__32C1I {
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  transform: translateY(-0.0625rem);
  cursor: pointer;
}
.style_selectionArrow__32C1I svg {
  fill: #1b264f;
  transition: fill 0.3s ease, transform 0.3s ease;
}
.style_selectionArrow__32C1I:hover:not(.style_disabled__1JA-0) {
  background-color: #f5f6f9;
}
.style_selectionArrow__32C1I:hover:not(.style_disabled__1JA-0) svg {
  fill: #333333;
}

.style_disabled__1JA-0 {
  cursor: default;
}
.style_disabled__1JA-0 svg {
  fill: #e2e9f1;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_competitorsHelp__1PHM4 {
  border-radius: 0.625rem !important;
  background-color: #cbf6ec !important;
  box-shadow: none;
  margin-bottom: 24px;
  padding: 1.5 !important;
  padding-left: 100px !important;
  display: table;
  width: 100%;
  position: relative;
  font-family: "Nunito Sans", sans-serif !important;
  text-align: left;
}

.style_competitorsHelp__1PHM4 h3 {
  color: #1b264f;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 0.5rem;
}

.style_competitorsHelp__1PHM4 p {
  color: #1b264f;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.style_competitorsHelpAvatar__2RaMF {
  background-color: #00b178 !important;
  margin-right: 16px;
  margin-bottom: 20px;
  float: left;
  padding: 13px;
  margin-left: -70px;
}

.style_competitorsHelpAvatar__2RaMF path {
  fill: #fff !important;
}

.style_buttons__19kxJ {
  display: flex;
  margin-top: 1rem;
}
.style_buttons__19kxJ .style_competitorsHelpButton__Riwzh {
  margin-left: 1rem;
}
.style_buttons__19kxJ .style_competitorsHelpButton__Riwzh:first-child {
  margin-left: 0;
}

.style_closeButton__1dGUm {
  background-color: transparent;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}
.style_closeButton__1dGUm > span {
  line-height: 0;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
}
.style_closeButton__1dGUm > span > span {
  color: #00b178 !important;
  line-height: 1.375rem;
}
.style_closeButton__1dGUm:hover {
  background-color: #00b178 !important;
}
.style_closeButton__1dGUm:hover span {
  color: #fff !important;
}
.style_center__3rw1- {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_mainPanel__2iEn2 {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #fdfdfe;
}

.style_mainContent__YqVkb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 2rem 0 2.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_footerLogo__1t-ni svg {
  fill: #e2e9f1;
}

.style_footerLogo__1t-ni p {
  color: #e2e9f1;
  font-weight: 800;
  font-size: 1.25rem;
}

.style_footerLogo__1t-ni {
  display: block;
  text-align: center;
  padding: 2.75rem 1rem 0;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_title__2r45j {
  margin-right: auto;
  align-self: flex-start;
}
.style_title__2r45j h2 {
  color: #1b264f;
  font-size: 1.625rem;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 2.5rem;
  display: flex;
  align-items: center;
}
.style_title__2r45j h2 .style_tooltip__25mUn {
  margin-left: 0.375rem;
  line-height: 0;
}
.style_title__2r45j h2 .style_tooltip__25mUn svg {
  width: 0.75rem;
  height: 0.75rem;
  transition: fill 0.3s ease-in-out;
}
.style_title__2r45j h2 .style_tooltip__25mUn svg:hover {
  fill: #333333;
}

.style_titleSmall__3RZi9 h2 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 0.125rem;
}
.style_titleSmall__3RZi9 h2 .style_tooltip__25mUn {
  transform: translateY(0.0625rem);
}

.style_headingTextContainer__3uugU {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

.style_childrenContainer__TbPW8 {
  display: flex;
  align-items: center;
  position: relative;
}

.style_autoWrap__1WSfP {
  flex: 1 1;
  justify-content: end;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.style_heading__14F37 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.75rem;
}

.style_headingTextButtonContainer__-7a_M > p {
  line-height: 1.125rem;
  color: #6a80a7;
  font-size: 0.875rem;
  margin: 0;
}

.style_headingButtonContainer__2gyPl {
  justify-content: space-between;
}
@media (max-width: 1240px) {
  .style_headingButtonContainer__2gyPl {
    flex-direction: column;
  }
}

.style_headingTextButtonContainer__-7a_M {
}

.style_headingTextButtonContainer__-7a_M h2 {
  font-size: 1.25rem;
}

.style_button__6O1HS {
  width: 5.5rem;
  height: 2rem;
  background: #bccfdc;
}

.style_icon__2SML1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  margin-right: 1rem;
  overflow: hidden;
  border-radius: 12px;
  background-color: #f8f9fb;
}
.style_icon__2SML1 img {
  max-height: 100%;
  max-width: 100%;
}
.style_icon__2SML1 svg {
  height: 1.25rem;
  width: 1.25rem;
}

.style_subtiteAndVideoWrapper__1zoHz {
  display: flex;
  align-items: flex-start;
}
.style_subtiteAndVideoWrapper__1zoHz .style_subtitle__2LtGd {
  line-height: 1.125rem;
  color: #6a80a7;
  font-size: 0.8125rem;
  margin: 0;
}
.style_subtiteAndVideoWrapper__1zoHz .style_subtitle__2LtGd a {
  color: #333333;
  font-size: inherit;
  text-decoration: none;
  font-weight: 700;
  transition: color 0.3s ease-in-out;
}
.style_subtiteAndVideoWrapper__1zoHz .style_subtitle__2LtGd a:hover {
  color: #333333;
}

.style_watchWrapper__1E721 {
  margin: 0 auto 0 0.5rem;
  display: inline-block;
  line-height: 0.5rem;
}
.style_watchWrapper__1E721 > span:first-child {
  float: left;
  transform: translateY(0.1875rem);
}

.style_infoBar__1Aufq {
  line-height: 1.125rem;
  color: #6a80a7;
  font-size: 0.8125rem;
  margin: 0;
  display: flex;
  align-items: center;
}
.style_infoBar__1Aufq .style_infoBarItem__2otdu {
  display: flex;
  align-items: center;
  cursor: default;
}
.style_infoBar__1Aufq .style_infoBarItem__2otdu::before {
  content: "";
  display: inline-block;
  height: 0.375rem;
  width: 0.375rem;
  margin: 0 0.625rem 0.0625rem;
  border-radius: 50%;
  background-color: #bccfdc;
}
.style_infoBar__1Aufq .style_infoBarItem__2otdu:first-child::before {
  display: none;
}
.style_infoBar__1Aufq .style_generatedList__3g_1t {
  margin-left: 0.25rem;
}
.style_infoBar__1Aufq.style_lightTheme__1udS6 {
  color: #bccfdc;
}
.style_infoBar__1Aufq.style_lightTheme__1udS6 .style_infoBarItem__2otdu::before {
  background-color: #0671ee;
}
.style_infoBar__1Aufq.style_lightTheme__1udS6 .style_tooltipItem__dBGpi .style_infoTooltip__1thVV svg {
  fill: #e2e9f1;
}
.style_infoBar__1Aufq.style_lightTheme__1udS6 .style_tooltipItem__dBGpi:hover .style_infoTooltip__1thVV svg {
  fill: #0671ee;
}

.style_infoTooltip__1thVV {
  line-height: 0;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
}
.style_infoTooltip__1thVV svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.25rem;
  transition: fill 0.3s ease-in-out;
}
.style_infoTooltip__1thVV:hover svg {
  fill: #333333;
}
.style_infoTooltip__1thVV:hover .style_infoTooltipBox__3C4Uv {
  opacity: 1;
  visibility: visible;
}

.style_infoTooltipBox__3C4Uv {
  font-family: "Nunito Sans", sans-serif;
  color: #fff;
  background-color: #333333;
  padding: 1.375rem 1.5rem 1.5rem;
  border-radius: 0.875rem;
  box-shadow: 0 0.5rem 1.25rem 0 rgba(51, 57, 68, 0.2);
  width: 22.5rem;
  z-index: 5;
  position: absolute;
  top: -1.375rem;
  left: 100%;
  transform: translateX(0.75rem);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.style_infoTooltipBox__3C4Uv::before {
  content: "";
  position: absolute;
  background-color: #333333;
  border-radius: 0.125rem;
  height: 0.875rem;
  width: 0.875rem;
  top: 1.375rem;
  left: -0.375rem;
  transform: rotate(45deg);
}
.style_infoTooltipBox__3C4Uv h4,
.style_infoTooltipBox__3C4Uv p {
  color: #fff;
}
.style_infoTooltipBox__3C4Uv h4 {
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 800;
  margin-bottom: 0.25rem;
}
.style_infoTooltipBox__3C4Uv p {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 400;
}
.style_infoTooltipBox__3C4Uv .style_resources__T5akU {
  margin-top: 1rem;
}
.style_infoTooltipBox__3C4Uv .style_resources__T5akU button {
  margin-top: 0.625rem;
  border: 1px solid #fff;
}
.style_infoTooltipBox__3C4Uv .style_resources__T5akU button:hover {
  background-color: #fff;
  color: #242423;
}

.style_heading__14F37.style_lightTheme__1udS6 .style_headingText__3oxxM > p,
.style_heading__14F37.style_lightTheme__1udS6 .style_headingTextButtonContainer__-7a_M > p {
  color: #bccfdc;
}
.style_heading__14F37.style_lightTheme__1udS6 .style_headingText__3oxxM h2 {
  color: #fff;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_titleTag__18wsn {
  border-radius: 0.25rem;
  padding: 0.25rem 0.4375rem;
  margin-left: 0.625rem;
  color: #fff;
  font-size: 9px;
  line-height: 0.625rem;
  font-weight: 800;
  text-transform: uppercase;
  transform: translateY(-0.0625rem);
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_watchButton__EY-Ah {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.style_watchButton__EY-Ah svg {
  width: 0.875rem;
  height: 0.875rem;
  fill: #0671ee;
}
.style_watchButton__EY-Ah svg > path {
  transition: fill 0.3s ease-in-out;
  fill: #0671ee;
}
.style_watchButton__EY-Ah span {
  font-size: 0.75rem;
  font-weight: 800;
  color: #242423;
  margin-left: 0.3125rem;
  transition: color 0.3s ease-in-out;
}
.style_watchButton__EY-Ah:hover span {
  color: #0671ee;
}
.style_watchButton__EY-Ah.style_lightTheme__MkPYR span {
  color: #fff;
}
.style_watchButton__EY-Ah.style_lightTheme__MkPYR:hover svg > path {
  fill: #0671ee;
}
.style_watchButton__EY-Ah.style_lightTheme__MkPYR:hover span {
  color: #0671ee;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_videoModal__R4S5g {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1310;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.2s ease-in-out;
}
.style_videoModal__R4S5g .style_close__3UZxF {
  position: absolute;
  right: 2.125rem;
  top: 2.125rem;
  background-color: transparent;
  z-index: 1;
}
@media (min-width: 64rem) {
  .style_videoModal__R4S5g .style_close__3UZxF {
    right: 3.75rem;
    top: 3.125rem;
  }
}
.style_videoModal__R4S5g .style_close__3UZxF > div::after, .style_videoModal__R4S5g .style_close__3UZxF > div::before {
  background-color: #fff;
}
.style_videoModal__R4S5g .style_close__3UZxF:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.style_videoModal__R4S5g .style_close__3UZxF:hover > div::after, .style_videoModal__R4S5g .style_close__3UZxF:hover > div::before {
  background-color: #fff;
}
.style_videoModal__R4S5g .style_modalContent__3mbE1 {
  position: relative;
  margin: 3rem 0;
  width: 80vw;
  max-width: 48.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 86rem) {
  .style_videoModal__R4S5g .style_modalContent__3mbE1 {
    max-width: 60rem;
  }
}
.style_videoModal__R4S5g .style_modalClose__1lX8m {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #fff;
  font-size: 2rem;
  line-height: 2rem;
  margin-left: auto;
  cursor: pointer;
  background-color: #333333;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  margin-bottom: 0.5rem;
  transition: background-color 0.2s ease-in-out;
}
.style_videoModal__R4S5g .style_modalClose__1lX8m:hover, .style_videoModal__R4S5g .style_modalClose__1lX8m:focus {
  background-color: #0671ee;
}
.style_videoModal__R4S5g .style_modalClose__1lX8m::before, .style_videoModal__R4S5g .style_modalClose__1lX8m::after {
  content: "";
  background-color: #fff;
  height: 0.125rem;
  border-radius: 0.0625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.25rem;
}
.style_videoModal__R4S5g .style_modalClose__1lX8m::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.style_videoModal__R4S5g .style_modalClose__1lX8m::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.style_videoModal__R4S5g .style_iframeWrapper__2Zn2O {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  width: 100%;
  height: 0;
}
.style_videoModal__R4S5g .style_iframeWrapper__2Zn2O .style_modalIframe__3iIv7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_configureBudget__2k8mH {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #e2e9f1;
  border-radius: 0.5rem;
  margin-bottom: 1.063rem;
  cursor: pointer;
}
.style_configureBudget__2k8mH:hover {
  border-color: #242423;
}
.style_configureBudget__2k8mH .style_configureBudgetIcon__11GSw {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #242423;
  border-radius: 0.625rem;
  margin-right: 0.875rem;
}
.style_configureBudget__2k8mH .style_configureBudgetIcon__11GSw svg {
  height: 0.875rem;
  width: 0.875rem;
}
.style_configureBudget__2k8mH .style_configureBudgetIcon__11GSw svg path {
  fill: #fff;
}
.style_configureBudget__2k8mH .style_configureBudgetIconEdit__kCbGE {
  flex: 1 1;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: end;
}
.style_configureBudget__2k8mH .style_configureBudgetIconEdit__kCbGE svg {
  height: 0.875rem;
  width: 0.875rem;
}
.style_configureBudget__2k8mH .style_configureBudgetIconEdit__kCbGE svg path {
  fill: #242423;
}
.style_configureBudget__2k8mH span {
  color: #242423;
  font-size: 0.875rem;
  font-weight: bold;
}

.style_mainFilterContainer__3Qc6G {
  padding: 1.25rem 1.4375rem 1.5rem 1rem;
  display: flex;
  align-items: center;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}
.style_mainFilterContainer__3Qc6G .style_filterIcon__1159R {
  margin-right: 4px;
  color: #242423;
  font-size: 0.875rem;
  font-weight: 800;
}
.style_mainFilterContainer__3Qc6G .style_filterIcon__1159R svg path {
  fill: #242423;
}

.style_filterDropDown__2e6S1 {
  height: 2.25rem;
}

.style_checkboxItem__2tIN6 {
  width: 100%;
}

.style_clearFilter__2spKy {
  padding: 0 0.75rem;
  height: 2.25rem;
  color: #bccfdc;
  font-size: 0.875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  margin-left: auto;
  cursor: default;
}
.style_clearFilter__2spKy svg {
  width: 0.625rem;
  height: 0.625rem;
}
.style_clearFilter__2spKy svg path {
  fill: #bccfdc;
}

.style_clearFilterActive__L1BS7 {
  border-radius: 0.5rem;
  background-color: rgba(106, 128, 167, 0.07);
  cursor: pointer;
  color: #242423;
}
.style_clearFilterActive__L1BS7 svg path {
  fill: #242423;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_loadingWrapper__3syS_ {
  display: flex;
  margin: 64px auto;
  grid-gap: 16px;
  gap: 16px;
  max-width: 1200px;
  animation: style_fadeIn__kR4Ee 1s;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperLeft__2JbPV {
  box-sizing: border-box;
  height: 320px;
  min-width: 300px;
  border: 0.5px solid #e2e9f1;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(51, 57, 68, 0.05);
}
.style_loadingWrapper__3syS_ .style_loadingWrapperLeft__2JbPV .style_loadingCircle__1_46x {
  display: block;
  margin: 96px auto 60px;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperLeft__2JbPV h1 {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  color: #242423;
  margin: 0;
  margin-bottom: 4px;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperLeft__2JbPV h2 {
  color: #6a80a7;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 0;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperRight__3r8PW {
  align-items: center;
  display: flex;
  box-sizing: border-box;
  height: 320px;
  min-width: 600px;
  max-width: 1000px;
  border: 0.5px solid #e2e9f1;
  border-radius: 12px;
  background-color: #f0f6fd;
  box-shadow: 0 2px 2px 0 rgba(51, 57, 68, 0.05);
  padding: 50px 50px;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperRight__3r8PW > div {
  display: flex;
  grid-gap: 64px;
  gap: 64px;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperRight__3r8PW > div h1 {
  color: #242423;
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 32px;
  animation: style_fadeIn__kR4Ee 2s;
  margin: 0;
  margin-bottom: 16px;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperRight__3r8PW > div h2 {
  color: #fb1427;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0;
  margin-bottom: 8px;
  animation: style_fadeIn__kR4Ee 1s;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperRight__3r8PW > div p, .style_loadingWrapper__3syS_ .style_loadingWrapperRight__3r8PW > div span {
  color: #242423;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 34px;
  animation: style_fadeIn__kR4Ee 3s;
}
.style_loadingWrapper__3syS_ .style_loadingWrapperRight__3r8PW img {
  animation: style_fadeIn__kR4Ee 2s;
  max-height: 240px;
}

@media only all and (max-width: 48rem) {
  .style_loadingWrapper__3syS_ {
    flex-direction: column;
    align-items: center;
  }
  .style_loadingWrapperRight__3r8PW {
    display: none !important;
  }
}
@keyframes style_fadeIn__kR4Ee {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_infoItemWrapper__2l16R {
  position: relative;
  background-color: #fff;
  border-radius: 0.75rem;
  border: 1px solid #e2e9f1;
  padding: 1.25rem 1.5rem 1.5rem;
  margin-top: 1rem;
}
.style_infoItemWrapper__2l16R .style_heading__1Srpc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column-gap: 5px;
  column-gap: 5px;
}
.style_infoItemWrapper__2l16R .style_heading__1Srpc .style_title__3HKoy {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  font-weight: 800;
}
.style_infoItemWrapper__2l16R .style_heading__1Srpc .style_title__3HKoy.style_industry__2fbNC {
  text-transform: capitalize;
  font-weight: 400;
}
.style_infoItemWrapper__2l16R .style_heading__1Srpc svg {
  width: 14px;
  margin-right: 5px;
  height: auto;
}
.style_infoItemWrapper__2l16R .style_subheading__3TNs6 {
  margin-top: 1rem;
}
.style_infoItemWrapper__2l16R .style_subheading__3TNs6 .style_period__1kMCZ {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.style_infoItemWrapper__2l16R .style_subheading__3TNs6 .style_period__1kMCZ.style_comparedTo__3uROr {
  color: #6a80a7;
  margin-right: 0.375rem;
}
.style_infoItemWrapper__2l16R .style_desc__ONhNb {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9rem;
  line-height: 1.23rem;
  color: #253348;
  margin-top: 1rem;
  font-weight: 700;
}
.style_infoItemWrapper__2l16R .style_desc__ONhNb h1,
.style_infoItemWrapper__2l16R .style_desc__ONhNb h2,
.style_infoItemWrapper__2l16R .style_desc__ONhNb h3,
.style_infoItemWrapper__2l16R .style_desc__ONhNb h4,
.style_infoItemWrapper__2l16R .style_desc__ONhNb p,
.style_infoItemWrapper__2l16R .style_desc__ONhNb span,
.style_infoItemWrapper__2l16R .style_desc__ONhNb ul,
.style_infoItemWrapper__2l16R .style_desc__ONhNb ol,
.style_infoItemWrapper__2l16R .style_desc__ONhNb li {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.9rem;
  line-height: 1.35rem;
  color: #253348;
  margin-top: 1rem;
}
.style_infoItemWrapper__2l16R .style_desc__ONhNb strong {
  font-weight: 800;
  color: #000;
}

.style_topActions__1ZWz_ {
  position: absolute;
  top: 1.375rem;
  right: 1.5rem;
  display: flex;
  align-items: center;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN {
  display: flex;
  align-items: center;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  transition: background-color 0.3s ease-in-out;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN span {
  color: #6a80a7;
  font-weight: 800;
  font-size: 0.75rem;
  transition: color 0.3s ease-in-out;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN svg {
  margin-right: 0.375rem;
  fill: #6a80a7;
  transition: fill 0.3s ease-in-out;
  width: 1rem;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN svg g {
  fill: #6a80a7;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN svg path {
  fill: #6a80a7;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN:hover {
  background-color: #f5f6f9;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN:hover span {
  color: #333333;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN:hover svg {
  fill: #333333;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN:hover svg g {
  fill: #333333;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN:hover svg path {
  fill: #333333;
}
.style_topActions__1ZWz_ .style_dismissInsight__1HXFN.style_disabled__LiU29 {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
}

.style_tag__1bfyw {
  height: 1.5rem !important;
}
.style_tag__1bfyw p {
  margin-top: 0 !important;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_subTableContainer__a_fGP {
  border: none;
  border-radius: 0;
}

.style_growthNumber__oYEnr {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.style_isHeadCell__3rX4m {
  font-weight: 600;
}

.style_rowPlatformContent__1J_3P, .style_subtableRowPlatformContent__2qcrw {
  display: flex;
}
.style_rowPlatformContent__1J_3P > div:last-child, .style_subtableRowPlatformContent__2qcrw > div:last-child {
  border-left: 1px solid #e2e9f1;
}

.style_subtableRowPlatformContent__2qcrw > div:first-child {
  justify-content: center;
}

.style_rowClass__1ydLv:first-child {
  border-bottom: 3px solid #bccfdc;
}

.style_rowClassSubTable__9M672:first-child > td {
  background-color: rgba(6, 113, 238, 0.05) !important;
  border-radius: 0 !important;
}

.style_rowPlatformContentLabel__1RC_M {
  min-width: 12.5rem;
  margin: 1.25rem;
  display: flex;
  flex-direction: column;
}
.style_rowPlatformContentLabel__1RC_M > div:last-child {
  flex: 1 1;
  display: flex;
  align-items: end;
  margin-top: 0.5rem;
}

.style_subTableCellLabel__1GHGb, .style_subTablePlatformCellLabel__3g5Mr {
  padding: 1.125rem 0.875rem 1rem 1.25rem;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: right;
  align-items: center;
}

.style_budget__W31Sm {
  justify-content: space-between;
  height: 54px;
}

.style_subTablePlatformCellLabel__3g5Mr {
  justify-content: left;
}

.style_platformLabelContainer__TRSSL {
  display: flex;
  align-items: center;
}
.style_platformLabelContainer__TRSSL .style_platformIcon__Tg-KR {
  transform: translateY(0.1875rem);
  margin-right: 0.625rem;
  flex-shrink: 0;
}
.style_platformLabelContainer__TRSSL .style_title__3odei {
  color: #1b264f;
  font-size: 0.875rem;
  font-weight: 800;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}
.style_platformLabelContainer__TRSSL .style_title__3odei .style_titleText__1IVIz {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 12rem;
}
.style_platformLabelContainer__TRSSL .style_subTitle__2KUP7 {
  font-size: 0.75rem;
  line-height: 1rem;
  position: relative;
}

.style_viewChartContainer__1E3YU {
  position: relative;
}
.style_viewChartContainer__1E3YU .style_btnViewChart__1Y_1K {
  padding: 0.5rem 0.688rem;
  box-sizing: border-box;
  border: 1px solid #e2e9f1;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.style_viewChartContainer__1E3YU .style_btnViewChart__1Y_1K svg {
  height: 0.75rem;
  width: 0.75rem;
}
.style_viewChartContainer__1E3YU .style_btnViewChart__1Y_1K svg path {
  fill: #242423;
}
.style_viewChartContainer__1E3YU .style_btnViewChart__1Y_1K > span {
  color: #242423;
  margin-left: 0.563rem;
  font-size: 0.813rem;
  font-weight: bold;
}
.style_viewChartContainer__1E3YU .style_btnViewChart__1Y_1K:hover {
  border-color: #242423;
}

.style_viewChart__2xTqr {
  width: 20rem;
}
.style_viewChart__2xTqr .style_viewChartMain__JTyuq {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e2e9f1;
  padding: 1rem 1.25rem 1rem 0.25rem;
}
.style_viewChart__2xTqr .style_viewChartMain__JTyuq span {
  color: #1b264f;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-weight: 800;
  margin-bottom: 0.875rem;
}
.style_viewChart__2xTqr .style_viewChartDesc__1M4kZ {
  padding: 0.875rem 1.25rem;
  color: #1b264f;
  font-size: 0.813rem;
}

.style_popoverPaper__2mUdH {
  margin-left: 0.5rem;
  box-shadow: 0 8px 20px 0 rgba(51, 57, 68, 0.2) !important;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_tableWrapper__2fLgb {
  border-radius: 0.625rem;
  border: 1px solid #e2e9f1;
  overflow: hidden;
}
.style_tableWrapper__2fLgb .style_loading__3maFc {
  padding: 8rem 0 4rem;
}
.style_tableWrapper__2fLgb table thead {
  border-top: none !important;
}
.style_tableWrapper__2fLgb table tbody > tr:last-of-type > td {
  border-bottom: none !important;
}
.style_tableWrapper__2fLgb table thead tr th:first-of-type {
  border-top-left-radius: 0.625rem;
}
.style_tableWrapper__2fLgb table thead tr th:last-of-type {
  border-top-right-radius: 0.625rem;
}
.style_tableWrapper__2fLgb table tbody tr:last-of-type td:first-of-type,
.style_tableWrapper__2fLgb table tbody tr[class*=isLastRow] td:first-of-type {
  border-bottom-left-radius: 0.625rem;
}
.style_tableWrapper__2fLgb table tbody tr:last-of-type td:last-of-type,
.style_tableWrapper__2fLgb table tbody tr[class*=isLastRow] td:last-of-type {
  border-bottom-right-radius: 0.625rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_headTooltip___mbYg {
  height: 0.6875rem;
  width: 0.6875rem;
  margin: 0 auto 0 0.25rem;
  display: flex;
  transform: translateY(-0.0625rem);
}
.style_headTooltip___mbYg svg {
  width: 0.6875rem;
  height: 0.6875rem;
  transition: fill 0.3s ease-in-out;
}
.style_headTooltip___mbYg:hover svg {
  fill: #333333;
}

[class*=MuiTableCell-alignRight] [class*=MuiTableSortLabel-root] .style_headCellActions__MdNR6,
[class*=MuiTableCell-alignRight] > span .style_headCellActions__MdNR6 {
  margin-right: auto;
}
[class*=MuiTableCell-alignRight] [class*=MuiTableSortLabel-root] .style_headTooltip___mbYg,
[class*=MuiTableCell-alignRight] > span .style_headTooltip___mbYg {
  order: -1;
  margin: 0 0 0 0.25rem;
}

.style_checkbox__3GtNS {
  display: inline-block;
  width: 0;
}

.style_headCellActions__MdNR6 {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.style_tableOptionsIcon__3EqJd {
  order: unset;
}

.style_tableCell__2rum8 {
  padding: 0 0.875rem;
  width: 3.125rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_metricSettingsRoot__ux52K .style_metricSettingsPaper__cxaUn {
  max-width: 70rem;
  width: 70rem;
  height: 40rem;
  padding: 2.5rem 2.5rem 2.375rem;
  overflow-y: visible;
  overflow-x: visible;
}
@media (max-height: 40rem) {
  .style_metricSettingsRoot__ux52K .style_metricSettingsPaper__cxaUn {
    overflow-y: auto;
  }
}

.style_metricSettings__1UbPy .style_heading__1KtKC {
  text-align: center;
  max-width: 34.25rem;
  margin: 0 auto 1.25rem;
}
.style_metricSettings__1UbPy .style_heading__1KtKC h3 {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #242423;
  margin-bottom: 0.25rem;
}
.style_metricSettings__1UbPy .style_heading__1KtKC p {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #6a80a7;
}
.style_metricSettings__1UbPy .style_selectText__35YBm {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 800;
  text-transform: capitalize;
}
.style_metricSettings__1UbPy .style_buttons__1OWHr {
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_metricSettings__1UbPy .style_buttons__1OWHr button {
  min-width: 9rem;
}
.style_metricSettings__1UbPy .style_buttons__1OWHr button:first-child {
  margin-right: 0.5rem;
}

.style_metricContent__1igdN {
  display: flex;
  height: 29rem;
  justify-content: space-between;
}
.style_metricContent__1igdN .style_metricAddContainer__1YElu {
  flex: 1 1;
  margin-right: 0.5rem;
}
.style_metricContent__1igdN .style_selectedMetrics__1HMOn {
  width: 49%;
  margin-left: 0.5rem;
}
.style_metricContent__1igdN .style_selectedMetrics__1HMOn .style_listMetrics__b6HZl {
  padding: 0.625rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 23.5rem;
  border: 1px solid #e2e9f1;
  border-radius: 0.4rem;
  display: flex;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  flex-direction: column;
}
.style_metricContent__1igdN .style_selectedMetrics__1HMOn .style_listMetrics__b6HZl .style_emptyMessage__33cgP {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.style_metricContent__1igdN .style_metricCheckboxes__NQOkV {
  height: 23.5rem;
  max-height: 23.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_draggableItem__1FxNo {
  flex-basis: 0;
  border-radius: 0.75rem;
  position: relative;
}
.style_draggableItem__1FxNo.style_isDragging__2xMp5 {
  z-index: -1;
}

.style_selectedColumn__3yOss {
  width: 100%;
  display: flex;
  padding: 0.625rem;
  align-items: center;
  border: 1px solid #e2e9f1;
  border-radius: 0.5rem;
  padding-left: 0.375rem;
  cursor: grab;
  background-color: #fff;
}
.style_selectedColumn__3yOss:hover {
  border-color: #333333;
}
.style_selectedColumn__3yOss .style_columnName__3L1l6 {
  width: 100%;
  padding: 0.25rem;
  margin-left: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: #333333;
  text-transform: capitalize;
}
.style_selectedColumn__3yOss .style_closeIcon__21cyB {
  opacity: 0.1;
}
.style_selectedColumn__3yOss .style_dragHandle__E8QxW {
  line-height: 0;
  cursor: grab;
  flex: 0 0 0.5rem;
  transition: opacity 0.3s ease-in-out;
  height: auto;
}
.style_selectedColumn__3yOss .style_dragHandle__E8QxW svg {
  fill: #bccfdc;
  transition: fill 0.3s ease-in-out;
  height: 100% !important;
}
.style_selectedColumn__3yOss.style_isDragOverlay__lSMmB {
  cursor: grabbing;
  border-color: #333333;
}
.style_selectedColumn__3yOss.style_isDragOverlay__lSMmB .style_dragHandle__E8QxW {
  cursor: grabbing;
}
.style_selectedColumn__3yOss.style_isDragging__2xMp5 {
  background-color: rgba(106, 128, 167, 0.1);
}
.style_selectedColumn__3yOss.style_isDragging__2xMp5 .style_dragHandle__E8QxW,
.style_selectedColumn__3yOss.style_isDragging__2xMp5 .style_columnName__3L1l6,
.style_selectedColumn__3yOss.style_isDragging__2xMp5 .style_closeIcon__21cyB {
  opacity: 0 !important;
}

.style_selectedColumn__3yOss:hover .style_dragHandle__E8QxW svg {
  fill: #333333;
}

.style_selectedColumn__3yOss:hover .style_closeIcon__21cyB {
  opacity: 0.4;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_chartWrapper__FAW7H {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}
.style_chartWrapper__FAW7H .style_siteLabel__3vea6 {
  color: #1b264f;
  font-size: 0.75rem;
}
.style_chartWrapper__FAW7H .style_toolTipContainer__3Y7eB {
  border: 1px solid #e2e9f1;
  border-radius: 0.625rem;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(51, 57, 68, 0.05);
  font-family: "Nunito Sans", sans-serif;
  overflow: hidden;
  padding: 8px 14px;
  min-width: 110px;
}
.style_chartWrapper__FAW7H .style_toolTipContainer__3Y7eB .style_numberContainer__Fr4De {
  display: flex;
  align-items: center;
}
.style_chartWrapper__FAW7H .style_toolTipContainer__3Y7eB .style_circle__1dVsF {
  border: 0.25rem solid #6a80a7;
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.375rem;
  transform: translateY(-0.0625rem);
}
.style_chartWrapper__FAW7H .style_toolTipContainer__3Y7eB .style_number__3caWR {
  color: #1b264f;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}
.style_chartWrapper__FAW7H .style_toolTipContainer__3Y7eB .style_info__3BpsF {
  margin-top: 8px;
}
.style_chartWrapper__FAW7H .style_toolTipContainer__3Y7eB .style_accountName__1XmcK {
  margin-bottom: 8px;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
  color: #6a80a7;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_label__1Szk5 {
  display: block;
  color: #1b264f;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 2rem;
  margin-bottom: 0.125rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_alertSettingsWrapper__3L4fn {
  box-sizing: border-box;
  border: 1px solid #e2e9f1;
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(51, 57, 68, 0.05);
}

.style_dropdownSelectorWrapper__hw-IH {
  position: relative;
}
.style_dropdownSelectorWrapper__hw-IH .style_dropdownSelector__3ED2v {
  z-index: unset;
  min-width: 7.625rem !important;
  margin-left: 0 !important;
}
.style_dropdownSelectorWrapper__hw-IH .style_dropdownSelector__3ED2v > ul {
  z-index: 2;
  width: 10rem !important;
}
.style_dropdownSelectorWrapper__hw-IH .style_dropdownSelector__3ED2v > ul > li {
  list-style: unset;
}
.style_dropdownSelectorWrapper__hw-IH .style_dayPickerContainer__qnthw {
  left: 10.25rem;
  top: 0;
  position: absolute;
}
.style_dropdownSelectorWrapper__hw-IH .style_dropdownItem__xSs_y {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}
.style_dropdownSelectorWrapper__hw-IH .style_dropdownItem__xSs_y > div {
  display: flex;
}
.style_dropdownSelectorWrapper__hw-IH .style_dropdownItem__xSs_y > div svg {
  height: auto;
  width: 1rem;
}

.style_backToDashboardBtn__3oM54 {
  min-width: 7rem;
}
.style_backToDashboardBtn__3oM54 > span {
  transform: translateY(0.0625rem);
  display: inline-block;
}

.style_extraLabelContainer__mDA9z {
  display: flex;
  flex-direction: column;
  grid-gap: 0.375rem;
  gap: 0.375rem;
}
.style_extraLabelContainer__mDA9z .style_mainLabel__3_FhG {
  line-height: 0.875rem;
}
.style_extraLabelContainer__mDA9z .style_subLabel__E4eMB {
  line-height: 0.875rem;
  white-space: normal;
  color: #6a80a7;
  font-weight: normal;
  font-size: 0.875rem;
  letter-spacing: 0;
}
.style_extraLabelContainer__mDA9z .style_haveMargin__3dK-k {
  margin-bottom: 0.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_configureBudgetContainer__2HQ2I {
  box-sizing: border-box;
  border: 1px solid #e2e9f1;
  border-radius: 0.75rem;
  background-color: #fff;
}
.style_configureBudgetContainer__2HQ2I .style_configureBudgetHeader__3yqqg {
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #eaf0f7;
}
.style_configureBudgetContainer__2HQ2I .style_configureBudgetHeader__3yqqg > div {
  margin-bottom: 0;
}
.style_configureBudgetContainer__2HQ2I .style_configureBudgetContent__2cN-6 {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 5rem;
  gap: 5rem;
  padding: 1.875rem 2.5rem 3.125rem 2.5rem;
}
.style_configureBudgetContainer__2HQ2I .style_configureBudgetContent__2cN-6 > div {
  flex: 1 1;
}
@media (max-width: 88.5rem) {
  .style_configureBudgetContainer__2HQ2I .style_configureBudgetContent__2cN-6 {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    gap: 3rem;
  }
}
.style_configureBudgetContainer__2HQ2I .style_configureBudgetFooter__36jUh {
  display: flex;
  padding: 1.25rem 2.5rem;
  align-items: center;
  justify-content: end;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-top: 1px solid #eaf0f7;
}
.style_configureBudgetContainer__2HQ2I .style_configureBudgetFooter__36jUh > button {
  min-width: 11.25rem;
}
.style_configureBudgetContainer__2HQ2I .style_mainTitle__uTUbt {
  font-size: 1.125rem;
  font-weight: 800;
  margin-bottom: 1rem;
}

.style_settingsIcon__1Sd1Q {
  display: flex;
  align-items: center;
}
.style_settingsIcon__1Sd1Q svg path {
  fill: #fff;
}

.style_iconBackgroundColor__2_TdY {
  background-color: #242423;
}

.style_formInput__1yoQx {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}
.style_formInput__1yoQx > label > div {
  margin-left: 0;
}

.style_selectedInput__31cKW > div > div span {
  font-weight: 800 !important;
}
.style_selectedInput__31cKW > div > div span > div > div:nth-child(2) {
  display: none;
}

.style_metric__plpAy {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
}
.style_metric__plpAy div {
  display: flex;
  align-items: center;
}

.style_platformCellContainer__35kol {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.style_platformLabelContainer__1uHQR {
  display: flex;
  align-items: center;
}
.style_platformLabelContainer__1uHQR .style_platformIcon__k9D-R {
  transform: translateY(0.1875rem);
  margin-right: 0.625rem;
  flex-shrink: 0;
}
.style_platformLabelContainer__1uHQR .style_title__2UzNt {
  color: #1b264f;
  font-size: 0.875rem;
  font-weight: 800;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}
.style_platformLabelContainer__1uHQR .style_title__2UzNt .style_titleText__3omjx {
  max-width: 12rem;
}
.style_platformLabelContainer__1uHQR .style_subTitle__OU8HZ {
  font-size: 0.75rem;
  line-height: 1rem;
  position: relative;
}

.style_addBudgetContainer__2APSo {
  margin-top: 1rem;
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.style_addBudgetContainer__2APSo .style_addBudgetTree__2OY-I {
  height: 4.125rem;
  width: 2rem;
  border-bottom: 1px solid #e2e9f1;
  border-left: 1px solid #e2e9f1;
}
.style_addBudgetContainer__2APSo .style_addBudgetTable__2xZ-Q {
  margin-top: 0.5rem;
}
.style_addBudgetContainer__2APSo .style_addBudgetTable__2xZ-Q > span {
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.125rem;
}
.style_addBudgetContainer__2APSo .style_addBudgetTable__2xZ-Q > div {
  margin-top: 0.5rem;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_noGA__3Zbk8,
.style_zeroStatePlatforms__2yd-K,
.style_noIntegrations__2MTUU {
  padding: 5rem 0 !important;
}
.style_noGA__3Zbk8 > svg,
.style_zeroStatePlatforms__2yd-K > svg,
.style_noIntegrations__2MTUU > svg {
  height: 2.5rem !important;
}
.style_noGA__3Zbk8 p,
.style_zeroStatePlatforms__2yd-K p,
.style_noIntegrations__2MTUU p {
  font-family: "Nunito Sans", sans-serif;
  max-width: 30rem !important;
}
.style_noGA__3Zbk8 h4,
.style_zeroStatePlatforms__2yd-K h4,
.style_noIntegrations__2MTUU h4 {
  font-family: "Nunito Sans", sans-serif;
}

.style_chatGpt__qHXEv {
  padding: 5rem 0 !important;
}
.style_chatGpt__qHXEv p {
  font-family: "Nunito Sans", sans-serif;
  max-width: 50rem !important;
}
/*
    Typography
*/
/*
    Hawke Colours   
*/
/*
    Colours
*/
/*
    Breakpoints
*/
.style_container__2oaPh {
  width: 100%;
  border-radius: 0.75rem;
  text-align: left;
  padding: 0.75rem 1rem;
  justify-content: left;
  align-items: center;
  margin-bottom: 1rem;
  background-color: rgba(6, 113, 238, 0.15);
  border: 1px solid #0671ee;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.style_container__2oaPh .style_icon__XaAKd {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 50%;
  background-color: #0671ee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.style_container__2oaPh .style_icon__XaAKd svg {
  fill: #fff;
}
.style_container__2oaPh h4 {
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.25rem;
  text-align: left;
  margin-bottom: 0.25rem;
}
.style_container__2oaPh p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8125rem;
  line-height: 1rem;
  text-align: left;
}

.style_percentageCircle__3Bf4N {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  color: #fff;
  font-weight: 800;
  flex-direction: column;
}
