@import "./_variables.scss";

body {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: $primary-font !important;
}

body > div:first-of-type {
    height: 100%;
}

html {
    height: 100%;
}

hr {
    border-top: 1.2px #00bcd4;
    width: 100%;
}

.Select-control {
    border: none;
}

@media print {
    body {
        height: auto;
        overflow-y: initial;
        overflow-x: initial;
    }
}

@page {
    size: A4;
    margin: 16px 30px 30px 30px;
}
