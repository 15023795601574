@import "~assets/styles/_variables";

.Close-Large,
.Close-Drawer,
.Close-Dialog {
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    border-radius: 0.625rem;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        position: absolute;
        opacity: 0;
        line-height: 0;
        height: 0;
        width: 0;
        overflow: hidden;
    }

    > div {
        height: 1.5rem;
        width: 1.5rem;
        position: relative;
        transition: transform 0.3s ease;

        &::after,
        &::before {
            content: "";
            display: block;
            width: 0.125rem;
            height: 2.125rem;
            background-color: $grey-medium;
            border-radius: 0.125rem;
            position: absolute;
            left: 0.6875rem;
            top: -0.25rem;
            transition: background-color 0.3s ease-in-out;
        }

        &::after {
            transform: rotate(45deg);
        }

        &::before {
            transform: rotate(-45deg);
        }
    }

    &:hover {
        background-color: $purple-light;

        > div {
            &::after,
            &::before {
                background-color: $purple;
            }
        }
    }
}

.Close-Large {
    background-color: $white;
    > div {
        transform: scale(0.9);
    }

    &:hover {
        > div {
            transform: scale(0.8);
        }
    }
}

.Close-Drawer {
    > div {
        height: 1rem;
        width: 1rem;

        &::after,
        &::before {
            width: 0.125rem;
            height: 1.375rem;
            left: 0.45rem;
            top: -0.2rem;
        }
    }

    &:hover {
        background-color: rgba(82, 46, 255, 0.07);
    }
}

.Close-Suggestion.Close-Drawer {
    > div {
        &::after,
        &::before {
            background-color: $white;
        }
    }

    &:hover {
        background-color: rgba(37, 51, 72, 0.1);

        > div {
            &::after,
            &::before {
                background-color: $white;
            }
        }
    }
}

.Close-Dialog {
    height: 2.5rem;
    width: 2.5rem;

    > div {
        height: 1rem;
        width: 1rem;
        transform: translateY(1px);

        &::after,
        &::before {
            width: 0.125rem;
            height: 1.375rem;
            left: 0.45rem;
            top: -0.2rem;
        }
    }

    &:hover {
        background-color: rgba(106, 128, 167, 0.07);

        > div {
            &::after,
            &::before {
                background-color: $grey-graphite;
            }
        }
    }
}
