@import "~assets/styles/_variables.scss";

.__react_component_tooltip.type-dark.place-right:after {
    border-right-color: #333944 !important;
}

.__react_component_tooltip.type-dark.place-left:after {
    border-left-color: #333944 !important;
}

.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #333944 !important;
}

.__react_component_tooltip.type-dark.place-bottom:after {
    border-bottom-color: #333944 !important;
}

.data-tip {
    position: relative;
    &:hover:after {
        content: attr(data-tip);
        position: absolute;
        bottom: 110%;
        font-style: $primary-font;
        line-height: 16px;
        font-weight: 400;
        border: 1px solid $grey-lines;
        font-size: 12px;
        padding: 10px 18px;
        background-color: #ffffff;
        min-width: 160px;
        max-width: 280px;
        border-radius: 6px;
    }
}
