@import "~assets/styles/_variables.scss";

.intercom-lightweight-app {
    z-index: 100 !important;
}

@media only all and (max-width: $tablet-breakpoint) {
    .intercom-lightweight-app {
        display: none !important;
    }
}
