@import "~assets/styles/_variables";

.month-picker {
    font-family: $primary-font;

    .rmp-pad {
        width: 16rem !important;
    }

    .rmp-popup.range {
        width: 34.5rem !important;
    }

    .rmp-popup {
        box-shadow: 0 0.25rem 0.5rem 0 rgb(51 57 68 / 5%) !important;
        border-radius: 0.625rem !important;
        border: 1px solid $grey-lines-light !important;
        transform: none !important;
        animation-name: fadeInOpacity !important;
        animation-iteration-count: 1 !important;
        animation-timing-function: ease-in !important;
        animation-duration: 2s !important;
    }

    .rmp-popup > div {
        background-color: $white !important;
    }

    .rmp-btn {
        border-radius: 8px !important;
        transition: background-color 0.5s ease !important;
        line-height: 3rem !important;
    }

    .rmp-btn:hover {
        background-color: $purple !important;
        color: $white !important;
    }

    .rmp-btn.active {
        background-color: $purple !important;
        color: $white !important;
        border-radius: 8px 0px 0px 8px !important;
    }

    .rmp-btn.select {
        background-color: $grey-bg-darker !important;
        border-radius: 0px !important;
    }
    .rmp-btn.select:hover {
        color: inherit !important;
    }

    .rmp-btn.disable {
        color: $grey-medium !important;
        font-weight: 400 !important;
    }

    .rmp-btn.disable:hover {
        background-color: transparent !important;
        color: inherit !important;
    }

    .rmp-tab {
        line-height: 3rem !important;
    }

    label {
        font-family: $primary-font;
        font-size: 16px !important;
        font-weight: 800 !important;
        color: $grey-graphite;
        line-height: 3rem;
    }
    li {
        font-family: $primary-font;
        font-size: 14px !important;
        font-weight: 600 !important;
        color: $grey-graphite;
    }
}
