/*
    Typography
*/

$primary-font: "Nunito Sans", sans-serif;
$bold: 700;
$extra-bold: 800;
$black: 900;

/*
    Hawke Colours   
*/

$hawke-dark-grey: #242423;
$hawke-medium-grey: #333333;
$hawke-light-grey: rgba(#242423, 0.05);
$hawke-blue: #0671ee;
$hawke-red: #0671ee;
$hawke-dark-blue: #0671ee;

/*
    Colours
*/

$white: #fff;

$purple: #333333;
$purple-light: #f5f6f9;
$purple-vibrant: #6634ff;
$purple-vibrant-light: #9959ff;

$indigo-dark: #1c0f9f;
$indigo-darker: #242423;

$sky-blue: #0671ee;
$sky-blue-dark: #0671ee;

$blue: #1679fb;
$blue-dark: #1471e9;

$green: #00b178;
$green-light: #e6f9f5;
$green-dark: #015c3f;
$green-mid: #008258;

$orange: #ff9d00;
$orange-mid: #ff6c00;
$orange-dark: #fb1427;
$orange-light: #fef6e9;

$yellow: #ffbc00;
$medium-yellow: #0671ee;

$red: #fb1427;
$red-light: #ffeef0;
$red-dark: #db0000;

$magenta: #cf1382;
$violet: #d73bf6;

$grey-medium: #6a80a7;
$grey-temp: #7e93b8;
$grey-mild: #a0b5c4;
$grey-light: #bccfdc;
$grey-mist: #c5d7e2;
$grey-lines: #e2e9f1;
$grey-lines-light: #eaf0f7;
$grey-bg: #f8f9fb;
$grey-bg-darker: #f3f7ff;
$grey-bg-body: #fdfdfe;
$grey-rhino: #333333;
$grey-graphite: #1b264f;

/*
    Breakpoints
*/
$tablet-breakpoint: 48rem !default;
$laptop-breakpoint: 64rem !default;
$desktop-breakpoint: 86rem !default;
