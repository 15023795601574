@import "~assets/styles/_variables.scss";

.layoutSettings {
    margin-bottom: 1.25rem;
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;

    .settingsItem {
        margin-right: 0.5rem;
        width: 16.25rem;
    }

    .sectionWrapper {
        display: flex;
        width: 100%;
    }

    .toggleView {
        justify-content: space-between;
        width: 100%;
        .toggleViewItem {
            flex: 1;
        }
    }

    .monthSelector {
        flex: 1;
        > div {
            width: 100% !important;
            z-index: 3;
        }
    }
}
