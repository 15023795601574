@import "~assets/styles/_variables";

$white: #fff;
$overlapDatesColour: $grey-medium;
$defaultDatesColour: $purple;
$selectedDatesColour: $sky-blue;

.dateRangeCompareContainer {
    position: relative;

    .DayPicker-Day {
        font-size: 0.75rem;
        width: 2.125rem;
        height: 2.125rem;
        font-weight: 500;
        cursor: pointer;
        z-index: 1;

        &:active,
        &:focus {
            outline: none;
        }
    }

    .DayPicker-Weekday {
        font-size: 0.75rem;
        font-weight: 600;
        color: $grey-medium;
        padding: 0 0.375rem;
        min-width: 2.125rem;
    }

    .DayPicker-Month {
        border-collapse: initial;
        border-spacing: 0 0.25rem;

        &:active,
        &:focus {
            outline: none;
        }
    }

    .DayPicker-wrapper {
        outline: none;
    }

    .DayPicker-Day {
        color: $grey-graphite;
        position: relative;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--today):not(.DayPicker-Day--outside):not(.DayPicker-Day--highlighted):hover {
        color: #ffffff;
        font-weight: 800;
        background-color: $selectedDatesColour !important;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
        color: $white !important;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside):hover {
        color: #ffffff !important;
        background-color: $selectedDatesColour !important;
        font-weight: 800;
        border-radius: 5rem;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside) {
        border-radius: 5rem;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:before(.DayPicker-Day--from):hover {
        border-radius: 50% 0 0 50%;
        background-color: $selectedDatesColour;
        content: "";
        display: block;
        position: absolute;
        height: 2.125rem;
        width: 2.125rem;
        top: 0;
        left: 0;
        z-index: -2;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before {
        content: "";
        width: 100%;
        height: 100%;
        background: $selectedDatesColour;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .DayPicker-Day--outside {
        background-color: transparent !important;
    }

    .DayPicker-Day--highlighted {
        color: #ffffff !important;
        background-color: $selectedDatesColour;
        font-weight: 800;
        cursor: default;
    }

    .DayPicker-Day--disabled {
        color: $grey-lines;
        cursor: default;
    }

    .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--outside):after {
        content: "";
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 10px;
        background: $selectedDatesColour;
    }

    .DayPicker-Day--daysWithAnomalies:hover:after {
        background: #ffffff;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--compareEnteredTo {
        border-radius: 0;
        background: $overlapDatesColour;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:before {
        border-radius: 50% 0 0 50%;
        background-color: $selectedDatesColour;
        content: "";
        display: block;
        position: absolute;
        height: 2.125rem;
        width: 2.125rem;
        top: 0;
        left: 0;
        z-index: -2;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:before,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
        border-radius: 0 50% 50% 0;
        background-color: $selectedDatesColour;
        content: "";
        display: block;
        position: absolute;
        z-index: -2;
        height: 2.125rem;
        width: 2.125rem;
        top: 0;
        left: 0;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from {
        width: 2.125rem;
        height: 2.125rem;
        border-radius: 100%;
        background: $selectedDatesColour;
        color: #ffffff !important;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:before {
        display: none;
    }

    .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to:after,
    .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--enteredTo:after,
    .DayPicker-Day--selected.DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:after {
        background: #ffffff;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $selectedDatesColour;
        border-radius: initial;
    }

    .daySelectContainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $selectedDatesColour;
        color: #ffffff !important;
        border-radius: 100%;
    }

    .DayPicker-NavButton--prev {
        background-image: url("~assets/images/icons/Icon-Arrow-Right.svg");
        transform: scaleX(-1);
    }

    .DayPicker-NavButton--next {
        background-image: url("~assets/images/icons/Icon-Arrow-Right.svg");
    }

    .DayPicker-NavButton {
        width: 0.875rem;
        outline: none;
        margin-top: -0.0625rem;
    }

    .DayPicker-NavButton--prev {
        left: 1rem;
    }

    .DayPicker-NavButton--next {
        right: 1rem;
    }

    .DayPicker-Caption {
        text-align: center;
        margin-bottom: 0.625rem;
    }

    .DayPicker-Caption > div {
        font-size: 0.875rem;
        font-weight: 800;
        color: $grey-graphite;
    }

    .DayPicker-Week > div:first-of-type:before {
        border-radius: 2rem 0 0 2rem;
    }

    .DayPicker-Week > div:last-of-type:before {
        border-radius: 0 2rem 2rem 0;
    }

    .daySelectContainer {
        position: relative;

        svg {
            fill: #ffffff;
        }

        .DayPicker-Day {
            min-width: 2.125rem;
        }

        .daySelect {
            box-shadow: 0 4px 8px 0 rgba(51, 57, 68, 0.05);
            border-radius: 8px;
            z-index: 100;
            position: absolute;
            background-color: #ffffff;
            right: 0;
            top: 3.1rem;
            padding: 0.5rem 0.5rem 0 0.5rem;
            border: 1px $grey-lines solid;
            transform: scale(0);
            opacity: 0;
            transition: opacity 0.3s ease, transform 0s ease 0.3s;
        }

        .daySelectOpen {
            transform: scale(1);
            opacity: 1;
            transition: opacity 0.3s ease 0.1s, transform 0s ease;
        }

        .DayPicker-Months {
            flex-wrap: initial;
        }
    }

    svg {
        fill: #ffffff;
    }

    .DayPicker-Day {
        min-width: 2.125rem;
    }

    .dateRange {
        border-radius: 0.625rem 0 0 0.625rem;
        z-index: 100;
        background-color: $white;
        padding: 0.5rem 0.5rem 0 0.5rem;
        transform: scale(1); // don't ask, but this is needed for some reason
    }

    .DayPicker-Months {
        flex-wrap: initial;
    }

    // .DayPicker-Day--to,
    // .DayPicker-Day--from {
    //     font-weight: 800;
    //     color: #ffffff !important;
    // }

    .DayPicker-Day--compareTo,
    .DayPicker-Day--compareFrom {
        font-weight: 800;
        color: #ffffff !important;
    }

    :not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:before {
        border-radius: 50% 0 0 50%;
        background-color: $defaultDatesColour;
        content: "";
        display: block;
        position: absolute;
        height: 2.125rem;
        width: 2.165rem;
        top: 0;
        left: 0;
        z-index: -2;
    }

    :not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--compareTo:before {
        border-radius: 50%;
        background-color: $defaultDatesColour;
        content: "";
        display: block;
        position: absolute;
        height: 2.125rem;
        width: 2.225rem;
        top: 0;
        left: 0;
        z-index: -2;
    }

    // :not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareTo:before,
    :not(.DayPicker-Day--outside).DayPicker-Day--compareTo.DayPicker-Day--compareEnteredTo:before {
        border-radius: 0 50% 50% 0;
        background-color: $defaultDatesColour;
        content: "";
        display: block;
        position: absolute;
        z-index: -2;
        height: 2.125rem;
        width: 2.125rem;
        top: 0;
        left: 0;
    }

    // :not(.DayPicker-Day--outside).DayPicker-Day--compareTo.DayPicker-Day--compareEnteredTo:before{

    // }

    :not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:before {
        background-color: $defaultDatesColour;
        content: "";
        display: block;
        position: absolute;
        z-index: -2;
        height: 2.125rem;
        width: 2.17rem;
        top: 0;
        left: 0;
    }

    .DayPicker-Day--compareFrom,
    .DayPicker-Day--compareTo {
        background: $defaultDatesColour;
    }

    .DayPicker-Day--compareEnteredTo {
        color: $white;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo {
        border-radius: 0;
    }

    // :not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareTo,
    // :not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo,
    // :not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom{
    //     width: 2.125rem;
    //     height: 2.125rem;
    //     border-radius: 100%;
    //     background: $defaultDatesColour
    //     color:#FFFFFF !important;
    // }

    :not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--from:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:before {
        display: none;
    }

    .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareTo:after,
    .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:after,
    .DayPicker-Day--daysWithAnomalies:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:after {
        background: #ffffff;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom {
        background-color: $overlapDatesColour;
        border-radius: 0;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo {
        background-color: $overlapDatesColour;
        border-radius: 0;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--to.DayPicker-Day--compareEnteredTo {
        background-color: $overlapDatesColour;
        border-radius: 0;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:hover {
        border-radius: 0;
        background-color: $overlapDatesColour !important;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--to.DayPicker-Day--enteredTo {
        border-radius: 0;
        &:before {
            content: none;
        }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom.DayPicker-Day--enteredTo:hover {
        background-color: $overlapDatesColour !important;
        border-radius: 0;
        &:before {
            content: none;
        }
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--compareEnteredTo:hover,
    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--compareFrom:hover,
    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside).DayPicker-Day--compareTo:hover {
        font-weight: 800;
        background-color: initial;
    }
}
