@import "~assets/styles/_variables";

.container {
    width: 100%;
    border-radius: 0.75rem;
    text-align: left;
    padding: 0.75rem 1rem;
    justify-content: left;
    align-items: center;
    margin-bottom: 1rem;
    background-color: rgba($hawke-blue, 0.15);
    border: 1px solid $hawke-blue;

    display: flex;
    gap: 1rem;

    .icon {
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
        border-radius: 50%;
        background-color: $hawke-blue;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: #fff;
        }
    }

    h4 {
        font-size: 1rem;
        font-weight: 800;
        line-height: 1.25rem;
        text-align: left;
        margin-bottom: 0.25rem;
    }

    p {
        font-family: $primary-font;
        font-size: 0.8125rem;
        line-height: 1rem;
        text-align: left;
    }
}

.percentageCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    color: #fff;
    font-weight: 800;
    flex-direction: column;
}
