@import "~assets/styles/_variables";

/*Grid Styles*/
.headerWithTwoItems {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.twoThirdsOneThirdGrid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 100%;
}
@media only screen and (min-width: 68.75rem) {
    .twoThirdsOneThirdGrid {
        grid-template-columns: calc(68.5% - 0.75rem) calc(31.5% - 0.75rem);
    }
}

.oneHalfOneHalfGrid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 100%;
}
@media only screen and (min-width: 48rem) {
    .oneHalfOneHalfGrid {
        grid-template-columns: calc(50% - 0.75rem) calc(50% - 0.75rem);
    }
}

.oneHalfOneHalfLargeGapGrid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 100%;
}
@media only screen and (min-width: 48rem) {
    .oneHalfOneHalfLargeGapGrid {
        grid-template-columns: calc(50% - 0.75rem) calc(50% - 0.75rem);
    }
}
@media only screen and (min-width: 68.75rem) {
    .oneHalfOneHalfLargeGapGrid {
        grid-gap: 3rem;
        grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);
    }
}
.oneWholeGrid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 100%;
}

.gridSpacingTop {
    margin-top: 2.25rem;
}

.gridSpacingBottom {
    margin-top: 4rem;
}

/* Card Additional Styles */
.materialCardPadding {
    padding: 2rem;
}

.materialCardPaddingGraph {
    padding: 2rem 2rem 2.25rem 2rem;
}

.centeringContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0 !important;
}

.horizontalRule {
    height: 1px;
    width: 100%;
    background-color: $grey-lines;
    display: block;
}

.avatarGreen {
    background-color: $green;
    float: none;
    margin: auto;

    svg,
    path {
        fill: #fff;
    }
}

/* Background Colour Styles */
.lightGreenBackground {
    background-color: $green;
}
.lightOrangeBackground {
    background-color: $orange;
}
.yellowBackground {
    background-color: $yellow;
}
.lightPurpleBackground {
    background-color: $purple;
}
.lightGreyBackgound {
    background-color: $grey-medium;
}

.lighterGreyBackground {
    background-color: $grey-bg;
}

/* growth number */
.zeroGrowth,
.positiveGrowth,
.negativeGrowth,
.mildGrowth {
    font-weight: 800;
    letter-spacing: 0;
    text-align: center;
    border-radius: 0.3125rem;
    padding: 0.0625rem 0.3125rem 0;
    line-height: 1.0625rem;
    font-size: 0.6875rem;
    width: -webkit-max-content;
}

.zeroGrowth {
    color: $grey-medium;
    background-color: #f0f4f7;
}

.positiveGrowth {
    color: $green;
    background-color: $green-light;
    width: -webkit-max-content;
}

.negativeGrowth {
    color: $red;
    background-color: $red-light;
    width: -webkit-max-content;
}

.mildGrowth {
    color: $orange;
    background-color: $orange-light;
    width: -webkit-max-content;
}

.dashboardSubtitle {
    font-size: 0.8125rem;

    .dot {
        height: 0.375rem;
        width: 0.375rem;
        background-color: $grey-light;
        border-radius: 50%;
        display: inline-block;
        margin: 0 0.625rem 0.125rem;
    }
}

.platformChannelLabel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .channelName {
        &::before {
            content: "";
            width: 0.375rem;
            height: 0.375rem;
            border-radius: 50%;
            background-color: $grey-mild;
            display: inline-block;
            margin: 0 0.375rem 0.0625rem 0.125rem;
        }
    }
}
